import React from "react";
import { components } from "react-select";

const LocationIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src="images/Partner-Location.png" alt="Location Icon" />
    </components.DropdownIndicator>
  );
};

export default LocationIndicator;
