import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { userLogout } from "../slice";

const Logout = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { replace } = useHistory();

  const handleSubmit = () => {
    dispatch(userLogout());
    toggle();
    replace("/login");
  };

  return (
    <>
      <div
        className={isOpen ? "modal fade show" : "modal fade"}
        id="delUserDetails"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Logout</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={toggle}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <h4 className="del_user">Are you sure, you want logout? </h4>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="Del_btn"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                Yes
              </button>
              <button
                type="button"
                className="Can_btn"
                data-dismiss="modal"
                onClick={toggle}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default Logout;
