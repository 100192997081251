import React, { Fragment } from "react";
import { convertTime24to12 } from "../../../utils";

const OtherInfo = ({ data }) => {
  const labelFixer = (data) => {
    const strData = String(data);
    const length = strData.length;

    if (length === 4) {
      let feetValue = strData[0];
      let inchesValue = strData[3];
      return feetValue + `' ` + inchesValue + `''`;
    } else if (length === 3) {
      if (strData[2] && strData[2] === "1") {
        let feetValue = strData[0];
        let inchesValue = strData[2] + "0";
        return feetValue + `' ` + inchesValue + `''`;
      } else {
        let feetValue = strData[0];
        let inchesValue = strData[2];
        return feetValue + `' ` + inchesValue + `''`;
      }
    } else {
      return data + `" 0''`;
    }
  };
  return (
    <div className="user-all-info">
      {/* <div className="user_religious">
        <div className="user_hnd">
          <ul>
            <li className="user_r_name">
              <img
                src="images/religion-black.png"
                alt=""
                className="rel_icon"
              />
              <span className="usr_rel_txt">
                {data?.religionDetails?.name || "N/A"}
              </span>
            </li>
            <li className="user_r_name">
              <img
                src="images/community-black.png"
                alt=""
                className="rel_icon"
              />
              <span className="usr_rel_txt">
                {data?.communitiesDetails?.name || "N/A"}
              </span>
            </li>
            <li className="user_r_name">
              <img
                src="images/language-black.png"
                alt=""
                className="rel_icon"
              />
              <span className="usr_rel_txt">
                {data?.mothertonguesDetails?.name || "N/A"}
              </span>
            </li>
          </ul>
        </div>
        <div className="user_hnd">
          <ul>
            <li className="user_r_name">
              <img src="images/height-black.png" alt="" className="rel_icon" />{" "}
              <span className="usr_rel_txt">
                {data?.heightsDetails?.height_type === "feet"
                  ? `${data?.heightsDetails.feet}' ${data?.heightsDetails.inc}"`
                  : data?.heightsDetails?.cm + "cm"}
              </span>
            </li>
            <li className="user_r_name">
              <img src="images/salary-black.png" alt="" className="rel_icon" />
              <span className="usr_rel_txt">
                {data?.annual_package?.toLocaleString(
                  data?.currency?.toUpperCase() === "USD" ? "en-US" : "en-IN",
                  {
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: data?.currency?.toUpperCase() || "INR",
                  }
                ) || "N/A"}
              </span>
            </li>
            <li className="user_r_name">
              <img
                src="images/higher-education.png"
                alt=""
                className="rel_icon"
              />
              <span className="usr_rel_txt">
                {data?.educationsDetails?.graduation_details?.length
                  ? data.educationsDetails.graduation_details.find(
                      (el) => el.is_highest_qualification
                    )?.graduation_degree
                  : "N/A"}
              </span>
            </li>
            <li className="user_r_name">
              <img
                src="images/location-black.png"
                alt=""
                className="rel_icon"
              />
              <span className="usr_rel_txt">{data?.city || "N/A"}</span>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="user-education_area">
        <div className="row">
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Personal Details</h4>
              <div className="education_inner_info">
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>D.O.B</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.date_of_birth?.substr(0, 10) || "NA"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Gender</span>
                  </h4>
                  <h4 className="edu_name">{data?.gender || "NA"}</h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Blood Group</span>
                  </h4>
                  <h4 className="edu_name">{data?.blood_group || "NA"}</h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Marital Status</span>
                  </h4>
                  <h4 className="edu_name">{data?.married}</h4>
                </div>
                {data?.married === "never married" ? (
                  ""
                ) : (
                  <>
                    <div className="education_info">
                      <h4 className="edu_title">
                        <img src="images/mobile.png" alt="icon" />
                        <span>Second Marriage Option</span>
                      </h4>
                      <h4 className="edu_name">
                        {data?.second_marriage_option || "NA"}
                      </h4>
                    </div>
                    <div className="education_info">
                      <h4 className="edu_title">
                        <img src="images/mobile.png" alt="icon" />
                        <span>Living Option</span>
                      </h4>
                      <h4 className="edu_name">
                        {data?.living_option || "NA"}
                      </h4>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Religion/Caste Details</h4>
              <div className="education_inner_info">
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Religion</span>
                  </h4>
                  <h4 className="edu_name">
                    {" "}
                    {data?.religionDetails?.name || "NA"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Caste</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.communitiesDetails?.name || "NA"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Sub Caste</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.subcastsDetails?.name || "NA"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Gothra</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.gothraDetails?.name || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Devak</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.devakDetails?.name || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Dosh</span>
                  </h4>
                  <h4 className="edu_name">{data?.dosh || "N/A"}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="user-education_area">
        <div className="row">
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Professional Details</h4>
              <div className="education_inner_info">
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Highest Qualification</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.highestEducationDetails?.name ||
                      "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Course</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.streamDetails?.name || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Specialization</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.specializationDetails?.name ||
                      "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Occupation</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.occupationDetails?.name || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Employment Type</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.employment_type || "N/A"}
                  </h4>
                </div>

                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Class</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.classDetails?.name || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Annual Income</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.annual_income || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Work Location</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.work_location || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Own House at Work Location</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.do_you_own_house === true
                      ? "YES"
                      : "NO" || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>District</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.district || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>State</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.state || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Plan to settle Abroad</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.plan_to_settle_abroad === true
                      ? "YES"
                      : "NO" || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Country</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.occupationsDetails?.abroad_country || "N/A"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Family Details</h4>
              <div className="education_inner_info">
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Family Type</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.family_type || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Family Status</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.family_status || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Family Values</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.family_values || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Number of Brother</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.no_of_brother || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Number of Sister</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.no_of_sister || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Monthly Average Income</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.family_monthly_average_income_from
                      ? `${data?.familiesDetails?.family_monthly_average_income_from} - ${data?.familiesDetails?.family_monthly_average_income_to}`
                      : "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Family Net Worth</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.family_net_worth_from
                      ? `${data?.familiesDetails?.family_net_worth_from} - ${data?.familiesDetails?.family_net_worth_to}`
                      : "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>No of Acres of Firm</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.number_of_acres_of_firm_from
                      ? `${data?.familiesDetails?.number_of_acres_of_firm_from} - ${data?.familiesDetails?.number_of_acres_of_firm_to}`
                      : "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Father Alive</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.father_alive || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Mother Alive</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.familiesDetails?.mother_alive || "N/A"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="user-education_area">
        <div className="row">
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Horoscope Details</h4>
              <div className="education_inner_info">
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Rashi</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.horoscope_details?.rashi || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Nadi</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.horoscope_details?.nadi || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Manglik</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.horoscope_details?.manglik || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Gan</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.horoscope_details?.gan || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Birth Time</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.horoscope_details?.time || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Birth Place</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.horoscope_details?.birth_place || "N/A"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Lifestyle Details</h4>
              <div className="education_inner_info">
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Diet</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.lifestylesDetails?.diet || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Drinking</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.lifestylesDetails?.drinking || "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Smoking</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.lifestylesDetails?.smoking || "N/A"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="user-education_area">
        <div className="row">
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Hobbies</h4>
              <div className="education_inner_info">
                {data?.hobbiesDetails?.length > 0
                  ? data?.hobbiesDetails?.map((res, i) => {
                      return (
                        <div className="education_info">
                          <h4 className="edu_name">{res?.title}</h4>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Body Details</h4>
              <div className="education_inner_info">
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Blood Group</span>
                  </h4>
                  <h4 className="edu_name">{data?.blood_group || "N/A"}</h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Weight</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.body_weight ? `${data?.body_weight} kg` : "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Height</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.heightsDetails?.height}{" "}
                    {data?.heightsDetails?.height_type}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Complexion</span>
                  </h4>
                  <h4 className="edu_name">{data?.complexion || "N/A"}</h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Spectacles</span>
                  </h4>
                  <h4 className="edu_name">{data?.spectacles || "N/A"}</h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Disability</span>
                  </h4>
                  <h4 className="edu_name">{data?.disability || "N/A"}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="user-education_area">
        <div className="row">
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Membership Details</h4>
              <div className="education_inner_info">
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Overall Subscription expiry date</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.expire_subscription_plan_date?.substr(0, 10) ||
                      "N/A"}
                  </h4>
                </div>
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Total Remaining Request</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.left_number_of_request || "N/A"}
                  </h4>
                </div>
              </div>
              {data?.packageDetails?.length > 0 ? (
                data?.packageDetails.map((res, i) => {
                  return (
                    <div className="education_inner_info package_box mb-3">
                      <div className="education_info">
                        <h4 className="edu_title">
                          <img src="images/mobile.png" alt="icon" />
                          <span>Package</span>
                        </h4>
                        <h4 className="edu_name">{res?.package || "N/A"}</h4>
                      </div>
                      <div className="education_info">
                        <h4 className="edu_title">
                          <img src="images/mobile.png" alt="icon" />
                          <span>Amount</span>
                        </h4>
                        <h4 className="edu_name">
                          {`${res?.amount} ₹` || "NA"}
                        </h4>
                      </div>
                      <div className="education_info">
                        <h4 className="edu_title">
                          <img src="images/mobile.png" alt="icon" />
                          <span>No. Of Requests</span>
                        </h4>
                        <h4 className="edu_name">
                          {res?.number_of_requests || "NA"}
                        </h4>
                      </div>
                      <div className="education_info">
                        <h4 className="edu_title">
                          <img src="images/mobile.png" alt="icon" />
                          <span>Purchased Date</span>
                        </h4>
                        <h4 className="edu_name">
                          {res?.start_date?.substr(0, 10) || "NA"}
                        </h4>
                      </div>
                      <div className="education_info">
                        <h4 className="edu_title">
                          <img src="images/mobile.png" alt="icon" />
                          <span>Expiry Date </span>
                        </h4>
                        <h4 className="edu_name">
                          {res?.end_date?.substr(0, 10) || "NA"}
                        </h4>
                      </div>
                      <div className="education_info">
                        <h4 className="edu_title">
                          <img src="images/mobile.png" alt="icon" />
                          <span>Package Id</span>
                        </h4>
                        <h4 className="edu_name">{res?.packageId || "N/A"}</h4>
                      </div>
                      <div className="education_info">
                        <h4 className="edu_title">
                          <img src="images/mobile.png" alt="icon" />
                          <span>Remaining Kundali Matching Request</span>
                        </h4>
                        <h4 className="edu_name">
                          {res?.pending_number_of_request || "N/A"}
                        </h4>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No Package Found</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="user_education_details">
              <h4 className="education_head">Document Details</h4>
              <div className="education_inner_info">
                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Adhar Card</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.userDocuments?.aadharcard ? (
                      <a
                        href={data?.userDocuments?.aadharcard}
                        target="_blank"
                        alt=""
                      >
                        View{" "}
                        <img
                          src="images/view.png"
                          className="action-view"
                          alt="edit"
                        />
                      </a>
                    ) : (
                      "NA"
                    )}
                  </h4>
                </div>

                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Bio Data</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.userDocuments?.biodata ? (
                      <a
                        href={data?.userDocuments?.biodata}
                        target="_blank"
                        alt=""
                      >
                        View{" "}
                        <img
                          src="images/view.png"
                          className="action-view"
                          alt="edit"
                        />
                      </a>
                    ) : (
                      "NA"
                    )}
                  </h4>
                </div>

                <div className="education_info">
                  <h4 className="edu_title">
                    <img src="images/mobile.png" alt="icon" />
                    <span>Audio Url</span>
                  </h4>
                  <h4 className="edu_name">
                    {data?.userDocuments?.audio_url ? (
                      <a
                        href={data?.userDocuments?.audio_url}
                        target="_blank"
                        alt=""
                      >
                        View{" "}
                        <img
                          src="images/view.png"
                          className="action-view"
                          alt="edit"
                        />
                      </a>
                    ) : (
                      "NA"
                    )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherInfo;
