import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";

const Input = styled(MuiInput)`
  width: 100%;
  margin-left: 20px;
`;

export default function InputSlider({
  formik,
  minimum = 0,
  maximum = 100,
  currency = "INR",
}) {
  const handleSliderChange = (event, newValue) => {
    formik.setFieldValue("value", newValue);
  };

  const handleInputChange = (event) => {
    formik.setFieldValue(
      "value",
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (formik.values.value < minimum) {
      formik.setFieldValue("value", minimum);
    } else if (formik.values.value > maximum) {
      formik.setFieldValue("value", maximum);
    }
  };

  return (
    <div>
      <Grid item xs>
        <Slider
          value={
            typeof formik.values.value === "number"
              ? formik.values.value
              : minimum
          }
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          min={minimum}
          max={maximum}
        />
      </Grid>
      <br />
      <Grid item>
        <div className="d-flex align-items-center">
          <b>{currency}</b>
          <Input
            value={formik.values.value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              min: minimum,
              max: maximum,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
            disabled={formik.values.value > maximum}
          />
        </div>
      </Grid>
    </div>
  );
}
