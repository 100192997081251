import { getCookie } from "../utils";

// const baseURI = process.env.REACT_APP_VEER_MATRIMONY_ADMIN_BASE_URL;
// const baseURI = "http://13.200.97.246:5011";
const baseURI = "https://api.pairsindia.in";

// Example POST method implementation:
export async function postEncodedData(url = "", data = {}) {
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}
export async function patchEncodedData(url = "", data = {}) {
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "PATCH", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}
export async function postEncodedDataDelete(url = "", data = {}) {
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}
export async function postEncodedDataPatch(url = "", data = {}) {
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "PATCH", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}

export async function postFormData(url = "", data = {}) {
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: data,
    });
    return response.json();
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}

export async function postRawData(url = "", data = {}) {
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: data,
    });
    return response.json();
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}

export async function getData(url = "", params = {}) {
  try {
    // Default options are marked with *
    let uri = new URL(baseURI + url);
    uri.search = new URLSearchParams(params).toString();
    const response = await fetch(uri, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}

export async function getPostData(url = "", params = {}) {
  try {
    // Default options are marked with *
    let uri = new URL(baseURI + url);
    uri.search = new URLSearchParams(params).toString();
    const response = await fetch(uri, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}
export async function deleteFormData(url = "", data = {}) {
  console.log("Data0", data);
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: data,
    });
    return response.json();
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}

export async function deleteEncodedData(url = "", data = {}) {
  console.log(data);
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: data,
    });
    return response.json();
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}

export async function putFormData(url = "", data = {}) {
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: data,
    });
    return response.json();
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}

export async function putEncodedData(url = "", data = {}) {
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: data, // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}

export async function patchFormData(url = "", data = {}) {
  try {
    // Default options are marked with *
    const response = await fetch(baseURI + url, {
      method: "PATCH",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Authorization: getCookie("clszdi"),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: data,
    });
    return response.json();
  } catch (error) {
    console.error("Error: ", error);
    return null;
  }
}
