import React from 'react'

const Complexion = ({ setComplexionValue }) => {

    return (
        <div className='d-flex justify-content-around mt-4'>
            <h4 className='text-center'>Complexion</h4>
            <div className="form-groupp">
                <select
                    className="form-control add_user_info"
                    onChange={(e) => setComplexionValue(e.target.value)}
                >
                    <option value="">
                        Select
                    </option>
                    <option value="Fair">Fair</option>
                    <option value="Medium Fair">Medium Fair</option>
                    <option value="Naturally Brown">Naturally Brown</option>
                    <option value="Dark Brown">Dark Brown</option>
                </select>

            </div>


        </div>
    )
}

export default Complexion