import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { changeuserPageNumber, getUsersList, usersList } from "../slice";
import Header from "./Header";
import Tab from "./Tab";
import SimpleBackdrop from "../../components/backdrop";
import HandleDelete from "./HandleDelete";
import { useToggle } from "../../app/hooks";
import ImgFluid from "../../components/image/ImgFluid";
import HandleDeactivate from "./HandleDeactivate";
import { getUsersListWithoutPagination } from "../slice";
import Cookies from "universal-cookie";
import HandleMembership from "./AssignMembership";

const ref = React.createRef();
const ApprovedUser = () => {
  const cookie = new Cookies();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [gender, setGender] = useState("");
  const [percentage, setPercentage] = useState("");
  const status = useSelector((state) => state?.status);
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state?.usersList);
  const data2 = useSelector((state) => state?.usersListWithoutPagination);
  const [isOpen, toggle] = useToggle(false);
  const [isOpenDelete, toggleDelete] = useToggle(false);
  const [isOpenAssignMembership, toggleMembership] = useToggle(false);

  const [page, setPage] = useState(1);
  const [userID, setUserID] = useState("");
  const userPageNumber = useSelector((state) => state?.userPageNumber);

  const [heightFrom, setHeightFrom] = useState("");
  const [heightTo, setHeightTo] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allUsers, setAllUsers] = useState([]);

  const age_from = cookie.get("age_from");
  const age_to = cookie.get("age_to");
  const have_father = cookie.get("have_father");
  const have_mother = cookie.get("have_mother");
  const have_brother = cookie.get("have_brother");
  const have_sister = cookie.get("have_sister");
  const complexion = cookie.get("complexion");
  const ready_to_abroad = cookie.get("ready_to_abroad");
  const own_house_occupation = cookie.get("own_house_occupation");
  const user_merital_status = cookie.get("user_merital_status");
  const employment_type = cookie.get("employment_type");
  const classgov = cookie.get("class");
  const occupation = cookie.get("occupation");
  const user_manglik_status = cookie.get("user_manglik_status");
  const occupation_country = cookie.get("occupation_country");
  const occupation_state = cookie.get("occupation_state");
  const occupation_city = cookie.get("occupation_city");
  const user_country = cookie.get("user_country");
  const user_state = cookie.get("user_state");
  const user_city = cookie.get("user_city");
  const heighest_education = cookie.get("heighest_education");
  const stream = cookie.get("stream");
  const specialization = cookie.get("specialization");
  const user_height_from = cookie.get("user_height_from");
  const user_height_to = cookie.get("user_height_to");
  const search = cookie.get("search");
  const [userCheck, setUserCheck] = useState([]);

  useEffect(() => {
    if (userPageNumber) {
      setPage(userPageNumber);
    }
  }, [userPageNumber]);
  useEffect(() => {
    if (data) {
      setAllUsers(data?.data);
    }
  }, [data]);
  const handleDeleteSelect = (e) => {
    const { name, checked } = e.target;

    if (name === "allUsers") {
      let tempUsers = allUsers?.map((user) => {
        return { ...user, isChecked: checked };
      });
      setAllUsers(tempUsers);
    } else {
      let tempUsers = allUsers?.map((user) =>
        user?._id == name ? { ...user, isChecked: checked } : user
      );
      setAllUsers(tempUsers);
    }
  };
  useEffect(() => {
    dispatch(
      getUsersList({
        tab_type: "approved",
        page,
        perpage: 50,
        age_from: age_from ? age_from : "",
        age_to: age_to ? age_to : "",
        have_father: have_father ? have_father : "",
        have_mother: have_mother ? have_mother : "",
        have_brother: have_brother ? have_brother : "",
        have_sister: have_sister ? have_sister : "",
        complexion: complexion ? complexion : "",
        ready_to_abroad:
          ready_to_abroad === true
            ? "Yes"
            : ready_to_abroad === false
            ? "No"
            : "",
        own_house_occupation:
          own_house_occupation === true
            ? "Yes"
            : own_house_occupation === false
            ? "No"
            : "",
        user_merital_status: user_merital_status ? user_merital_status : "",
        employment_type:
          employment_type?.length > 0 ? employment_type?.toString() : "",
        class: classgov?.length > 0 ? classgov?.toString() : "",
        occupation: occupation?.length > 0 ? occupation?.toString() : "",
        user_manglik_status:
          user_manglik_status?.length > 0
            ? user_manglik_status?.toString()
            : "",
        occupation_country:
          occupation_country?.length > 0 ? occupation_country?.toString() : "",
        occupation_state:
          occupation_state?.length > 0 ? occupation_state?.toString() : "",
        occupation_city:
          occupation_city?.length > 0 ? occupation_city?.toString() : "",
        user_country: user_country?.length > 0 ? user_country?.toString() : "",
        user_state: user_state?.length > 0 ? user_state?.toString() : "",
        user_city: user_city?.length > 0 ? user_city?.toString() : "",
        heighest_education:
          heighest_education?.length > 0 ? heighest_education.toString() : "",
        stream: stream?.length > 0 ? stream?.toString() : "",
        specialization:
          specialization?.length > 0 ? specialization?.toString() : "",
        user_height_from: user_height_from ? user_height_from : "",
        user_height_to: user_height_to ? user_height_to : "",
      })
    );
    dispatch(getUsersListWithoutPagination({ tab_type: "approved" }));
  }, [dispatch, page, limit, userCheck]);

  const exportData = data?.data?.map((res) => {
    return {
      "Matrimony Id": res?.veer_id,
      "First Name": res?.first_name,
      "Middle Name": res?.middle_name,
      "Last Name": res?.last_name,
      email: res?.email,
      Mobile: res?.mobile_number,
      Gender: res?.gender,
      "D.O.B": res?.date_of_birth?.substr(0, 10),
      "Recently Logged In": res?.last_activity_day_time
        ? new Date(res.last_activity_day_time).toLocaleString()
        : "-",
    };
  });

  useEffect(() => {
    return () => dispatch(usersList(null));
  }, [dispatch]);
  const handleGenderSearch = (e) => {
    setGender(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({ tab_type: "new", page: 1, gender: e.target.value })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({ tab_type: "reject", page: 1, gender: e.target.value })
        );
        break;

      default:
        break;
    }
  };
  const handlePercentageSearch = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            profile_percentage: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            profile_percentage: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            profile_percentage: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };
  const handleManglikStatus = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            user_manglik_status: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            user_manglik_status: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            user_manglik_status: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };
  const handleMaritialStatus = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            user_merital_status: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            user_merital_status: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            user_merital_status: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };

  const handleFamilyType = (e) => {
    // setGender(e.target.value)
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            family_type: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            family_type: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            family_type: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };

  const handleFamilyStatus = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            family_status: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            family_status: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            family_status: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };

  return (
    <div className="m-main-content">
      <Header />
      <Tab
        exportData={exportData}
        data={data?.data}
        setHeightTo={setHeightTo}
        heightTo={heightTo}
        setHeightFrom={setHeightFrom}
        heightFrom={heightFrom}
        setSearchText={setSearchText}
      />
      <div className="d-flex justify-content-end mx-3">
        <h4 style={{ color: "#EC008C", fontWeight: "bold" }}>{search}</h4>
      </div>
      <div className="row">
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num mx-3"> Gender</label>
          <select
            className="form-control ps-4 mx-2"
            onChange={(e) => {
              handleGenderSearch(e);
            }}
          >
            <option value="">All</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num">Profile Percentage</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handlePercentageSearch(e);
            }}
          >
            <option value="">All</option>
            <option value="25">Below 25 %</option>
            <option value="50">Below 50 %</option>
            <option value="75">Below 75 %</option>
            <option value="100">100 %</option>
          </select>
        </div>
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num">Manglik Status</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleManglikStatus(e);
            }}
          >
            <option value="">All</option>
            <option value="Yes">Yes</option>
            <option value="Yes but Mild">Yes but Mild</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num">Maritial Status</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleMaritialStatus(e);
            }}
          >
            <option value="">All</option>
            <option value="never married">Never married</option>
            <option value="divorced">Divorced</option>
            <option value="widow">Widow</option>
            <option value="awaiting divorce">awaiting divorce</option>
          </select>
        </div>

        <div className="col-md-2">
          <label className="num"> Family Type</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleFamilyType(e);
            }}
          >
            <option value="">All</option>
            <option value="Joint">Joint</option>
            <option value="Nuclear">Nuclear</option>
          </select>
        </div>
        <div className="col-md-2">
          <label className="num">Family Status</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleFamilyStatus(e);
            }}
          >
            <option value="">All</option>
            <option value="Middle class">Middle Class</option>
            <option value="Upper middle class">Upper Middle Class</option>
            <option value="Rich">Rich</option>
          </select>
        </div>

        <div className="col-md-4 mt-4">
          <div className="d-flex">
            <button
              title="Approve"
              className="btn save_btnn mx-2"
              onClick={() => {
                toggleMembership();
              }}
            >
              Assign Membership
            </button>
          </div>
        </div>
      </div>
      <div className="brand_table">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="brand_table_inner">
                <div className="table-responsive " ref={ref}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col mx-5">
                          <label className="container-select">
                            <input
                              type="checkbox"
                              className=""
                              name="allUsers"
                              onChange={handleDeleteSelect}
                              checked={
                                allUsers.filter(
                                  (user) => user?.isChecked !== true
                                ).length < 1
                              }
                            />
                            <span className="checkmark-check"></span>
                          </label>
                        </th>
                        <th scope="col">S.No</th>
                        <th scope="col">Matrimony Id</th>
                        <th scope="col">Photo</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Middle Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Email ID</th>
                        <th scope="col">Mobile Number</th>
                        <th scope="col">No. of Matches</th>
                        <th scope="col">Recently Logged In</th>
                        <th scope="col">Plan Status</th>
                        <th scope="col">Registration Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && allUsers?.length ? (
                        allUsers.map((el, i) => (
                          <tr key={el._id}>
                            <td className="bt-non">
                              <label className="container-select">
                                <input
                                  type="checkbox"
                                  className=""
                                  name={el?._id}
                                  checked={el?.isChecked || false}
                                  onChange={handleDeleteSelect}
                                />
                                <span className="checkmark-check mt-1"></span>
                              </label>
                            </td>
                            <td className="bt-non">
                              {data.current === 1
                                ? i + 1
                                : (data.current - 1) * 50 + (i + 1)}
                            </td>
                            <td className="bt-non">{el?.veer_id || "NA"}</td>
                            <td className="bt-non">
                              <div className="p_pic">
                                <ImgFluid
                                  src={el?.profile_images?.map((res) => {
                                    return res?.is_profile === 1
                                      ? res?.image_url
                                      : "NA";
                                  })}
                                />
                              </div>
                            </td>
                            <td className="bt-non">{el.first_name || "N/A"}</td>
                            <td className="bt-non">
                              {el.middle_name || "N/A"}
                            </td>
                            <td className="bt-non">{el.last_name || "N/A"}</td>
                            <td className="bt-non">{el.gender || "N/A"}</td>
                            <td className="bt-non">{el.email || "N/A"}</td>
                            <td className="bt-non">
                              {`${el.country_code || "N/A"} ${
                                el.mobile_number || "N/A"
                              }`}
                            </td>
                            {/* <td className="bt-non">
                              {`${el.city || ""} ${el.state || ""} ${
                                el.country || ""
                              }`}
                            </td> */}
                            <td className="bt-non">{el.no_of_matches || 0}</td>
                            <td className="bt-non">
                              {el.last_activity_day_time
                                ? new Date(
                                    el.last_activity_day_time
                                  ).toLocaleString()
                                : "-"}
                            </td>
                            <td className="bt-non">
                              {el.subscription_plan_active == 1
                                ? "Active"
                                : "Inactive"}
                            </td>
                            <td className="bt-non">
                              {el.createdAt?.substr(0, 10) || "NA"}
                            </td>
                            <td className="bt-non">
                              <span className="table-action">
                                <Link
                                  to={`/users/approved/${el._id}`}
                                  title="View"
                                >
                                  <img
                                    src="images/view.png"
                                    className="action-view"
                                    alt="view"
                                  />
                                </Link>
                                <button
                                  style={{ background: "none", border: "none" }}
                                  title="Deactivate"
                                  onClick={() => {
                                    setUserID(el._id);
                                    toggle();
                                  }}
                                >
                                  <img
                                    src="images/on.png"
                                    className="action-view"
                                    alt="status"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setUserID(el._id);
                                    toggleDelete();
                                  }}
                                  style={{ background: "none", border: "none" }}
                                  title="Delete"
                                >
                                  <img
                                    src="images/delete.png"
                                    className="action-view"
                                    alt="Deactivate"
                                  />
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={14} style={{ padding: "10px" }}>
                            No Results Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {data?.pages > 1 ? (
                    <Pagination
                      count={data.pages}
                      page={data.current}
                      onChange={(e, page) => {
                        (async () => {
                          await localStorage.setItem("userPageNumber", page);
                        })();
                        dispatch(changeuserPageNumber(page));
                        setPage(page);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleBackdrop open={status === "loading"} />
      <HandleDelete
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        userID={userID}
        page={page}
        userType="approved"
      />

      <HandleMembership
        isOpen={isOpenAssignMembership}
        toggle={toggleMembership}
        page={page}
        userType="approved"
        id={allUsers
          ?.filter((user) => user?.isChecked === true)
          .map((user) => {
            return user?._id;
          })}
        setUserCheck={setUserCheck}
      />
      <HandleDeactivate
        isOpen={isOpen}
        toggle={toggle}
        userID={userID}
        page={page}
        userType="approved"
      />
    </div>
  );
};

export default ApprovedUser;
