import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { changeuserPageNumber } from "../slice";

const TabReport = () => {
  const dispatch = useDispatch();
  return (
    <div className="manage_news_section mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="manage_tab">
              <ul className="manage_news_inner">
                <li>
                  <NavLink
                    to="/report-revenue"
                    className="m_news"
                    onClick={() => {
                      (async () => {
                        await localStorage.setItem("userPageNumber", 1);
                      })();
                      dispatch(changeuserPageNumber(1));
                    }}
                  >
                    Revenue Generated
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/report/subscribed-users"
                    className="m_news"
                    onClick={() => {
                      (async () => {
                        await localStorage.setItem("userPageNumber", 1);
                      })();
                      dispatch(changeuserPageNumber(1));
                    }}
                  >
                    Subscribed Users
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/feedback"
                    className="m_news"
                    onClick={() => {
                      (async () => {
                        await localStorage.setItem("userPageNumber", 1);
                      })();
                      dispatch(changeuserPageNumber(1));
                    }}
                  >
                    Feedbacks
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabReport;
