import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { addNotification } from "../../api/offer";
import { useFormik } from 'formik'
import * as Yup from "yup";

const SendNotifications = ({ isOpen, toggle, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loadingText, setLadingText] = useState()


    useEffect(() => {
        document.body.classList.toggle("modal-open", isOpen);
    }, [isOpen]);

    const formik = useFormik({
        initialValues: {
            title: "",
            body: "",
            users: "",
            notifyAllUsers: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            title: Yup.string().required("*Required"),
            body: Yup.string().required("*Required"),
        }),

        onSubmit: (values, { resetForm }) => {
            setLadingText("Please wait, notifications sending....")
            const { title, body } = values;
            const payload = {
                title: title,
                body: body,
                users: data?.map((res) => { return res?._id }),
                send_to_all_user: "No",

            };
            addNotification(payload).then((response) => {
                // setSaving(false);
                enqueueSnackbar(response?.message);
                if (response?.error_code === 0) {
                    setLadingText("")
                    resetForm({});
                    toggle()
                    // dispatch(getNotification({}));
                    // handleShow()
                }
            });

        },
    });


    return (
        <>
            <div
                className={isOpen ? "modal fade show" : "modal fade"}
                id="delUserDetails"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" >Send Notifications</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={toggle}
                            >
                                &times;
                            </button>
                        </div>

                        <form className="form-style mt-3" onSubmit={formik.handleSubmit}>
                            <h4 className="text-center" style={{ color: "#EC008C", fontSize: "15px", fontWeight: "bold" }}>{loadingText}</h4>

                            <div className="row  justify-content-center">
                                <div className="col-sm-12 col-md-6 col-lg-12 mb-4">
                                    <div className="form-groupp">
                                        <label className="text_label text-center mx-3">Notification Title</label>
                                        <div className="input-containerr">
                                            <input type="text" className="form-control add_user_info" {...formik.getFieldProps("title")}
                                                placeholder="Title" />
                                            {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                        </div>
                                        {formik.touched.title && formik.errors.title ? (
                                            <span className="error">{formik.errors.title}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                    <label className="text_label text-center mx-3">Description</label>
                                    <div className="form-groupp">
                                        <textarea className=" textarea-control" rows={7}
                                            {...formik.getFieldProps("body")}
                                            placeholder="Description" ></textarea>
                                        {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                    </div>
                                    {formik.touched.body && formik.errors.body ? (
                                        <span className="error">{formik.errors.body}</span>
                                    ) : null}
                                </div>

                                {/* <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                                    <div className="input-container Present-checkbox highest-qualification">
                                        <input
                                            type="checkbox"
                                            id="notifyAllUsers"
                                            name="notifyAllUsers"
                                            // checked={
                                            //     formik.values.notifyAllUsers === "Yes"

                                            // }
                                            onChange={() => formik.setFieldValue(`notifyAllUsers`, formik?.values?.notifyAllUsers === "Yes" ? "No" : "Yes")}
                                        />
                                        <label htmlFor={"notifyAllUsers"}>
                                            Notify All Users
                                        </label>
                                    </div>
                                </div> */}
                            </div>

                            <div className="button_area text-center">
                                <button type="submit" className="save_btn">Submit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            {isOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

SendNotifications.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    userID: PropTypes.string.isRequired,
    page: PropTypes.number,
    userType: PropTypes.string.isRequired,
};

export default SendNotifications;
