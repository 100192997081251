import React, { useState, useEffect, useRef } from 'react'
import { Drawer } from "antd";
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { addUpdateSuscription } from '../../api/subscription';
import { getSubscription, getSubscriptionDetails } from '../slice';
import CircularProgress from "@material-ui/core/CircularProgress";
import JoditEditor from 'jodit-react';


const AddSubscription = ({ show, handleShow, id }) => {
    const editor = useRef(null);
    const dispatch = useDispatch()
    const { goBack } = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [viewportWidth, setViewportWidth] = useState("")
    const data = useSelector((state) => state.subscriptionDetail);
    const [content, setContent] = useState(data?.service_offered);
    const [saving, setSaving] = useState(false);


    useEffect(() => {

        if (typeof (window.innerWidth) != 'undefined') {
            setViewportWidth(window.innerWidth);
        }
    }, [])
    useEffect(() => {
        if (id) {
            dispatch(getSubscriptionDetails({ id }));
        }
    }, [dispatch, id]);

    const formik = useFormik({
        initialValues: {

            type: id ? data?.package : "",
            amount: id ? data?.amount : "",
            validity: id ? data?.validity : "",
            service_offered: id ? data?.service_offered : "",
            request: id ? data?.number_of_requests : "",
            description: id ? data?.service_offered : "",
            parameter: data?.number_of_requests !== null ? 2 : data?.validity !== null ? 1 : 3,
        },
        enableReinitialize: true,
        // validationSchema: Yup.object({
        //     type: Yup.string().required("*Required"),
        //     amount: Yup.number().min(0.1, " *Must be greater than or equal to 0.1").required("*Required."),
        //     validity: Yup.string().required("*Required"),
        //     // service_offered: Yup.string().required("*Required"),
        // }),
        onSubmit: (values, { resetForm }) => {
            const { type, amount, validity, request, description } = values;

            let payload = {
                package: type,
                amount: amount,
                number_of_requests: request,
                validity: validity,
                // description: description,
                service_offered: content,

            }
            // if (type === "Kundli Matching Packages") {
            //     payload = {
            //         package: type,
            //         amount: amount,
            //         packageType: 2,
            //         number_of_requests: request,
            //     };
            // }
            // if (type === "Account Validity Packages") {
            //     payload = {
            //         package: type,
            //         amount: amount,
            //         validity: validity,
            //         packageType: 1,
            //     };
            // }
            // if (type === "Combo Package") {
            //     payload = {
            //         package: type,
            //         amount: amount,
            //         validity: validity,
            //         packageType: 3,
            //         number_of_requests: request,
            //     };
            // }

            if (id) {
                payload.subscriptionId = id;
            }
            addUpdateSuscription(payload).then((response) => {
                setSaving(false);
                enqueueSnackbar(response?.message);
                if (response?.error_code === 0) {
                    resetForm({});
                    dispatch(getSubscription({}));
                    handleShow()
                }
            });


        },
    });



    return (
        <>
            <Drawer placement="right" onClose={handleShow} visible={show} width={viewportWidth > 700 ? "40%" : "100%"}>

                <div className="px-5 pb-5">
                    <div className="text-center">
                        <h2 className="offcanvas-title mb-3">{id ? "Edit Subscription Package" : "Add Subscription Package"} </h2>
                    </div>
                    <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label">Package</label>
                                <div className="input-containerr">
                                    <input type="text" className="form-control add_user_info" placeholder="Package Type"   {...formik.getFieldProps("type")} />
                                    {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                </div>
                                {formik.touched.type && formik.errors.type ? (
                                    <span className="error">{formik.errors.type}</span>
                                ) : null}
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label">Amount</label>
                                <div className="form-groupp">
                                    <div className="input-containerr">
                                        <input type="number" className="form-control add_user_info" placeholder="Amount"   {...formik.getFieldProps("amount")} />
                                        {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                    </div>
                                    {formik.touched.amount && formik.errors.amount ? (
                                        <span className="error">{formik.errors.amount}</span>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 mb-5 mt-4">
                                <div className="form-groupp">
                                    <label className="text_label">Choose Parameters</label>
                                    <div className="nutritionRadio">
                                        <p className="mb-0">
                                            <input type="radio" id="test1" name="radio-group"
                                                onChange={() => formik.setFieldValue("parameter", 1)}
                                            />
                                            <label for="test1">Account Validity</label>
                                        </p>
                                        <p className="mb-0">
                                            <input type="radio" id="test2" name="radio-group"
                                                onChange={() => formik.setFieldValue("parameter", 2)} />
                                            <label for="test2">Kundali Matching Requests</label>
                                        </p>
                                        <p className="mb-0">
                                            <input type="radio" id="test3" name="radio-group"
                                                onChange={() => formik.setFieldValue("parameter", 3)} />
                                            <label for="test3">Combo</label>
                                        </p>

                                    </div>
                                </div>
                            </div>


                            {formik.values.parameter === 2 || formik.values.parameter === 3 ?
                                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                    <label className="text_label">Number Of Requests</label>
                                    <div className="form-groupp">
                                        <div className="input-containerr">
                                            <input type="number" className="form-control add_user_info" placeholder="Request"   {...formik.getFieldProps("request")} />
                                            {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                        </div>
                                        {formik.touched.amount && formik.errors.amount ? (
                                            <span className="error">{formik.errors.amount}</span>
                                        ) : null}
                                    </div>
                                </div> : ""}


                            {formik.values.parameter === 1 || formik.values.parameter === 3 ?
                                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                    <label className="text_label">Validity</label>
                                    <div className="form-groupp">
                                        <select
                                            className="form-control add_user_info"
                                            {...formik.getFieldProps("validity")}

                                        >
                                            <option value="">
                                                Select Validity
                                            </option>
                                            <option value="3">3 Months</option>
                                            <option value="6">6 Months</option>
                                            <option value="12">12 Months</option>
                                            <option value="24">2 Years</option>

                                        </select>
                                        {formik.touched.validity && formik.errors.validity ? (
                                            <span className="error">{formik.errors.validity}</span>
                                        ) : null}

                                    </div>
                                </div> : ""}
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label">Description</label>
                                <div className="form-groupp">
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        tabIndex={1}
                                        onBlur={newContent => setContent(newContent)}
                                        {...formik.getFieldProps("description")}
                                        onChange={newContent => setContent(newContent)}
                                    />
                                </div>
                                {formik.touched.description && formik.errors.description ? (
                                    <span className="error">{formik.errors.description}</span>
                                ) : null}
                            </div>


                        </div>
                        <div className="button_area text-center">
                            {saving ? <button
                                type="submit"
                                className="save_btn"
                                disabled={saving}
                            >
                                {saving ?
                                    <CircularProgress color="inherit" /> : ""
                                }
                            </button> :
                                <button type="submit" className="save_btn" >Submit</button>}

                        </div>
                    </form>

                </div>
            </Drawer>

        </>
    )
}

export default AddSubscription