import React from "react";

const ReportDetails = ({ data }) => {
  return (
    <div className="add_new_details_mian">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="new_user_detail_heading">
            <h2 className="new_user_txt">Report Details </h2>
          </div>
          <div className="blood_report_area">
            <div className="row">
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted Blood Reports?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isBloodReport ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    defaultValue={data?.verifiedBloodReport}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted parent contact details?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isParentContact ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data?.verifiedParentContact}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted Latest ITR?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isITR ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data?.verifiedITR}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted family photo?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isFamilyPhoto ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data?.verifiedFamilyPhoto}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted parent Aadhar Card details?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isParentAadharCard ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data?.verifiedParentAadharCard}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted Electricity bill?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isElectricityBill ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data?.verifiedElectricityBill}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted highest educational qualification
                    certificate?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isQualificationCertificate ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data?.verifiedQualificationCertificate}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted bank statement?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isBankStatement ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data?.verifiedBankStatement}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted Aadhar Card details?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isAadharCardDetails ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data?.verifiedAadharCardDetails}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="reports_question_main report_top">
                  <h4 className="report_qes">
                    Does user submitted Pan Card Details?*
                  </h4>
                  <div className="report_yes_no">
                    <span className="yes_btn">
                      {data?.isPanCard ? (
                        <img src="images/checked.png" alt="" />
                      ) : (
                        <img src="images/crossed.png" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group add_user_info">
                  <label htmlFor="">Verification Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data?.verifiedPanCard}
                    placeholder="Verification Person Name"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetails;
