import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { communityList, fetchCommunities, getUserDetails } from "../../slice";
import { editCommunity } from "../../../api/edit";
import SimpleBackdrop from "../../../components/backdrop";

const Community = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state.status);
  const list = useSelector((state) => state.communityList);

  const [other, setOther] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  useEffect(() => {
    if (data?.religion) {
      dispatch(fetchCommunities({ religion_id: data.religion }));
    }
  }, [dispatch, data?.religion]);

  const formik = useFormik({
    initialValues: {
      community: data?.community || "",
      subCaste: data?.sub_caste || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      community: Yup.string().required("*Required"),
      subCaste: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      const payload = {
        userid: userID,
        caste: values.community,
        sub_caste: values.subCaste,
      };

      editCommunity(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          goBack();
        }
      });
    },
  });

  useEffect(() => {
    return () => dispatch(communityList(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Community"
              : "Add User Community"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <div className="">
                    <img
                      src="images/religion.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  {list?.muslim ? (
                    <Fragment>
                      <h5>Shia</h5>
                      <div className="gander blood-group religion community">
                        {list?.data?.shia?.length
                          ? list.data.shia.map((el) => (
                              <label key={el._id}>
                                <input
                                  type="radio"
                                  name="community"
                                  checked={
                                    formik.values.community === el._id ||
                                    (other && el.name === "Others")
                                  }
                                  onChange={() => {
                                    formik.setFieldValue("community", el._id);
                                    if (el.name === "Others") {
                                      setOther(true);
                                    } else {
                                      setOther(false);
                                    }
                                  }}
                                />
                                <div className="div-bg">
                                  <p>
                                    <span>{el.name}</span>
                                  </p>
                                </div>
                              </label>
                            ))
                          : null}
                      </div>

                      <h5 className="mt-3">Sunni</h5>
                      <div className="gander blood-group religion community">
                        {list?.data?.sunni?.length
                          ? list.data.sunni.map((el) => (
                              <label key={el._id}>
                                <input
                                  type="radio"
                                  name="community"
                                  checked={
                                    formik.values.community === el._id ||
                                    (other && el.name === "Others")
                                  }
                                  onChange={() => {
                                    formik.setFieldValue("community", el._id);
                                    if (el.name === "Others") {
                                      setOther(true);
                                    } else {
                                      setOther(false);
                                    }
                                  }}
                                />
                                <div className="div-bg">
                                  <p>
                                    <span>{el.name}</span>
                                  </p>
                                </div>
                              </label>
                            ))
                          : null}
                      </div>
                    </Fragment>
                  ) : (
                    <div className="gander blood-group religion community">
                      {list?.data?.length
                        ? list.data.map((el) => (
                            <label key={el._id}>
                              <input
                                type="radio"
                                name="community"
                                checked={
                                  formik.values.community === el._id ||
                                  (other && el.name === "Others")
                                }
                                onChange={() => {
                                  formik.setFieldValue("community", el._id);
                                  if (el.name === "Others") {
                                    setOther(true);
                                  } else {
                                    setOther(false);
                                  }
                                }}
                              />
                              <div className="div-bg">
                                <p>
                                  <span>{el.name}</span>
                                </p>
                              </div>
                            </label>
                          ))
                        : null}
                    </div>
                  )}

                  {other && list?.other?.length ? (
                    <div className="input-container mt-3 add_user_info">
                      <select
                        className="form-control ps-4"
                        {...formik.getFieldProps("community")}
                        disabled
                      >
                        <option value="">Select Your Community</option>
                        {list.other.map((el) => (
                          <option value={el._id} key={el._id}>
                            {el.name}
                          </option>
                        ))}
                      </select>
                      <img
                        src="images/First-Name.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  ) : null}
                  {formik.errors.community ? (
                    <span className="error">{formik.errors.community}</span>
                  ) : null}
                </div>
                {/* <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                <h5>Select Sub-Caste</h5>
                <div className="input-container add_user_info">
                  <select
                    className="form-control ps-4"
                    {...formik.getFieldProps("subCaste")}
                    // disabled={!!!formik.values.community}
                    disabled
                  >
                    <option value="">Select Sub Caste</option>
                    {casteList?.length
                      ? casteList.map((el) => (
                          <option value={el._id} key={el._id}>
                            {el.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <img src="images/First-Name.png" className="input-img" alt="" />
                  {formik.errors.subCaste ? (
                    <span className="error">{formik.errors.subCaste}</span>
                  ) : null}
                </div>
              </div> */}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="input-container add_user_info">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Sub-caste"
                      {...formik.getFieldProps("subCaste")}
                      disabled={!!!formik.values.community}
                    />
                    <img
                      src="images/First-Name.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.subCaste && formik.errors.subCaste ? (
                      <span className="error">{formik.errors.subCaste}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default Community;
