import { configureStore } from "@reduxjs/toolkit";
// We'll use redux-logger just as an example of adding another middleware
// And use redux-batch as an example of adding enhancers
import { reduxBatch } from "@manaflair/redux-batch";

import rootReducer from "../features/slice";

const preloadedState = {
  limit: 10,
  isLoggedIn: false,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
  preloadedState,
  enhancers: [reduxBatch],
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("../features/slice", () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require("../features/slice").default;
    store.replaceReducer(newRootReducer);
  });
}
