import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateStatusBanner } from "../../../api/banner";
import { getBanner, idle, loading } from "../../slice";

const UpdateBanner = ({ isOpen, toggle, id, status }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { goBack } = useHistory();
    const limit = useSelector((state) => state?.limit);

    useEffect(() => {
        document.body.classList.toggle("modal-open", isOpen);
    }, [isOpen]);

    const handleUpdate = () => {
        if (id) {
            dispatch(loading());
            const payload = {
                id: id,
                status: status === true ? "0" : "1",
            };

            updateStatusBanner(payload).then((response) => {
                dispatch(idle());

                dispatch(getBanner({}));
                enqueueSnackbar(response.message);
                toggle()
            });
        }
    }

    return (
        <>
            <div
                className={isOpen ? "modal fade show" : "modal fade"}
                id="delUserDetails"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Status Banner</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={toggle}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="del_user">
                                Are you sure, you want to update status of this banner?
                            </h4>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="Del_btn"
                                data-dismiss="modal"
                                onClick={handleUpdate}
                            >
                                Update
                            </button>
                            <button
                                type="button"
                                className="Can_btn"
                                data-dismiss="modal"
                                onClick={toggle}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

// HandleDelete.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   toggle: PropTypes.func.isRequired,
//   userID: PropTypes.string.isRequired,
//   page: PropTypes.number,
//   userType: PropTypes.string.isRequired,
// };

export default UpdateBanner;
