import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import SimpleBackdrop from "../../../components/backdrop";
import { fetchHobbies, getUserDetails } from "../../slice";
import { editHobbies } from "../../../api/edit";
import { Link } from "react-router-dom";
import UserProfile from "../details/Profile";
import { stubFalse } from "lodash";

const Hobbies = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const data = useSelector((state) => state.userDetails);
  const list = useSelector((state) => state.hobbiesList);
  const status = useSelector((state) => state.status);
  const [saving, setSaving] = useState(false);
  const [other, setOther] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  useEffect(() => {

    if (!list) {
      dispatch(fetchHobbies({}));
    }
  }, [dispatch, list]);
  const formik = useFormik({
    initialValues: {
      hobbies: data?.hobbiesDetails?.length ? data?.hobbiesDetails : [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      hobbies: Yup.array().of(
        Yup.object().shape({
          _id: Yup.string().required("*Required"),
        })
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      const payload = {
        userid: userID,
        hobbies: values.hobbies?.map((el) => el._id),
      };

      editHobbies(payload).then((response) => {
        setSaving(true);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          setSaving(false)
          dispatch(getUserDetails({ user_id: userID }));

        }
      });
    },
  });

  useEffect(() => {
    let list = formik.values.hobbies;
    if (typeof list === "object" && list?.length) {
      const isOther = list.find((el) => el.title === "Others");
      setOther(!!isOther);
    }
  }, [formik.values.hobbies]);

  const handleChange = (hobby) => {
    let prevList = formik.values.hobbies;
    const isExist = prevList.find((el) => el._id === hobby._id);
    if (isExist) {
      const newList = prevList.filter((el) => el._id !== hobby._id);
      formik.setFieldValue("hobbies", [...newList]);
      formik.setFieldError("hobbies", "");
    } else {
      formik.setFieldValue("hobbies", [...prevList, hobby]);
      formik.setFieldError("hobbies", "");
    }
  };


  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Hobbies Details"
              : "Add User Hobbies Details"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img
                src="images/edit.png"
                className="action-view"
                alt="edit"
              />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>
                  Lifestyle
                </Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                  My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>
                  About Me
                </Link>
                <Link to={`/users/my-document/${userID}`}>
                  My Document
                </Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="gander blood-group religion community">
                    {list?.data?.length
                      ? list.data.map((el) => (
                        <label key={el._id}>
                          <input
                            type="checkbox"
                            checked={formik.values.hobbies?.find(
                              (hobby) => hobby._id === el._id
                            )}
                            onChange={() => handleChange(el)}
                          />
                          <div className="div-bg selectHobbies" style={{ padding: "10px" }}>
                            <div>
                              <img
                                src={el.logo}
                                className="unselected"
                                alt=""
                              />
                              <img
                                src={el.logo}
                                className="selectHobbies"
                                alt=""
                              />
                            </div>
                            <p>
                              <span className="unselected"
                              >{el.title}</span>
                            </p>
                          </div>
                        </label>
                      ))
                      : null}
                  </div>

                  {formik.errors.hobbies ? (
                    <span className="error">{formik.errors.hobbies}</span>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default Hobbies;
