import React from "react";

const regex =
  /\.(png|jpg|jpeg|pdf|PNG|JPG|JPEG|avif|apng|gif|jfif|pjpeg|pjp|svg|webp)$/;

const UserProfile = ({ data }) => {

  return (
    <div className="new_user_main">
      <div style={{ display: "flex" }}>
        <div className="user_pic">
          <h6>Profile Image</h6>
          <img
            src={
              data?.profile_images?.filter(res => res.is_profile === 1)[0]?.image_url || ""
            }
            alt="user"
            className="user_pic1"
          />
        </div>
        {/* <div className="user_pic">
          <h6>Family Photo</h6>
          <img
            src={
              data?.familiesDetails?.family_image
                ? data.familiesDetails.family_image
                : "images/favicon.png"
            }
            alt="family"
            className="user_pic1"
          />
        </div> */}
      </div>

      <div className="user_main_details">
        <div className="user_info">
          <img src="images/FirstName.png" alt="" className="user_icon1" />
          <p className="name_title">Matrimonial Id</p>
          <h4 className="usr_name">{data?.veer_id}</h4>
        </div>
        <div className="user_info">
          <img src="images/FirstName.png" alt="" className="user_icon1" />
          <p className="name_title">First Name</p>
          <h4 className="usr_name">{data?.first_name || "N/A"}</h4>
        </div>
        <div className="user_info">
          <img src="images/FirstName.png" alt="" className="user_icon1" />
          <p className="name_title">Middle Name</p>
          <h4 className="usr_name">{data?.middle_name || "N/A"}</h4>
        </div>
        <div className="user_info">
          <img src="images/FirstName.png" alt="" className="user_icon1" />
          <p className="name_title">Last Name</p>
          <h4 className="usr_name">{data?.last_name || "N/A"}</h4>
        </div>
        <div className="user_info">
          <img src="images/Mobile-Number.png" alt="" className="user_icon1" />
          <p className="name_title">Mobile Number</p>
          <h4 className="usr_name">
            {`${data?.country_code || ""}${data?.mobile || ""}` || "N/A"}
          </h4>
        </div>
        <div className="user_info">
          <img src="images/Mobile-Number.png" alt="" className="user_icon1" />
          <p className="name_title">Whatsapp Number</p>
          <h4 className="usr_name">
            {`${data?.whatsapp_country_code || ""}${data?.whatsapp_mobile_number || ""}` || "N/A"}
          </h4>
        </div>
        <div className="user_info">
          <img src="images/EmailAddress.png" alt="" className="user_icon1" />
          <p className="name_title">Email Address</p>
          <h4 className="usr_name">{data?.email || "N/A"}</h4>
        </div>
        {/* <div className="user_info">
          <img src="images/Address.png" alt="" className="user_icon1" />
          <p className="name_title">Address</p>
          <h4 className="usr_name">
            {`${data?.address || ""} ${data?.address_2 || ""} ${
              data?.city || ""
            } `}
            <br />
            {`${data?.state || ""} ${data?.country || ""} - ${
              data?.zipcode || ""
            }`}
          </h4>
        </div> */}
      </div>
    </div>
  );
};

export default UserProfile;
