import React from "react";
import { components } from "react-select";

const MarriageIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src="images/partner-Marital-Status.png" alt="Ring" />
    </components.DropdownIndicator>
  );
};

export default MarriageIndicator;
