import React from 'react'
import ReactSlider from 'react-slider'


const PartnerPackage = ({ value, setValue, MIN, MAX }) => {

    return (
        <div className="d-flex justify-content-center mt-5 mb-4 mx-3">
            <h4 className='mt-2 mx-4 fbold'>Partner Package INR {value[0]}  - {value[1]}</h4>
            <ReactSlider
                value={value}
                min={MIN}
                max={MAX}
                onChange={setValue}
            />
        </div>
    )
}

export default PartnerPackage