import { getData, postEncodedData, postEncodedDataDelete, postEncodedDataPatch } from ".";

export async function addUpdateSuscription(params) {
  return postEncodedData("/admin/add_update_subscription", params).then((data) => {
    return data;
  });
}

export async function fetchSubscription(params) {
  return getData(`/admin/get_subscription_plan?search&perpage&page=${params.page}`, params).then((data) => {
    return data;
  });
}
export async function fetchAllUsers(params) {
  return getData(`/admin/user/all-user-list?status=${params.no}`, params).then((data) => {
    return data;
  });
}
export async function fetchHigherEducation(params) {
  return getData(`/get_higher_education`, params).then((data) => {
    return data;
  });
}
export async function fetchClassList(params) {
  return getData(`/get_class_list`, params).then((data) => {
    return data;
  });
}
export async function fetchSubscriptionDetails(params) {
  return getData(`/admin/get_subscription_plan_details/${params?.id}`).then((data) => {
    return data;
  });
}
export async function deleteSusbscription(params) {
  return postEncodedDataDelete("/admin/remove_subscription_plan", params).then((data) => {
    return data;
  });
}
export async function updateStatusSusbscription(params) {
  return postEncodedDataPatch("/admin/update_subscription_plan_status", params).then((data) => {
    return data;
  });
}
export async function deleteFaq(params) {
  return postEncodedData("/delete_faq", params).then((data) => {
    return data;
  });
}
export async function fetchTransaction(params) {
  return getData(`/admin/transaction_report?perpage&page=${params.page}`, params).then((data) => {
    return data;
  });
}
export async function fetchSubscribedUsers(params) {
  return getData("/admin/subscribed_user_report", params).then((data) => {
    return data;
  });
}
export async function fetchRevenue(params) {
  return getData(`/admin/earnings?month=&year&plan_type=${params.plan_type}&perpage&page=${params.page}`, params).then((data) => {
    return data;
  });
}
export async function fetchEarningDetails(params) {
  console.log("para", params.date)
  return getData(`/admin/earning_details?date=${params.date}&plan_type=`).then((data) => {
    return data;
  });
}
