import { postEncodedData } from ".";

export async function editGender(params) {
  return postEncodedData("/admin/user/add_update_gender", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editMaritalStatus(params) {
  return postEncodedData("/admin/user/add_update_married_status", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editNationality(params) {
  return postEncodedData("/admin/user/add_update_nationality", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editAnnualPackage(params) {
  return postEncodedData("/admin/user/add_update_annual_package", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editImagesVideos(params) {
  return postEncodedData("/admin/user/add_update_profile", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editBirthday(params) {
  console.log("editBirthday(params)", params);
  return postEncodedData("/admin/user/add_update_birthday", params).then(
    (data) => {
      return data;
    }
  );
}
export async function editHoroscope(params) {
  console.log("editHoroscope(params)", params);
  return postEncodedData("/admin/user/add_update_horoscope_details", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editHeight(params) {
  return postEncodedData("/add_update_body_details", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editBloodGroup(params) {
  return postEncodedData("/admin/user/add_update_blood", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editReligion(params) {
  return postEncodedData("/add_update_religion", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editCommunity(params) {
  return postEncodedData("/admin/user/add_update_community", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editMotherTongue(params) {
  return postEncodedData("/admin/user/add_update_mother_tongue", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editEducationDetails(params) {
  return postEncodedData(
    "/admin/user/add_update_education_details",
    params
  ).then((data) => {
    return data;
  });
}

export async function editGraduationDetails(params) {
  return postEncodedData(
    "/admin/user/add_update_graduation_details",
    params
  ).then((data) => {
    return data;
  });
}

export async function editOccupationDetails(params) {
  return postEncodedData(
    "/admin/user/add_update_occupation_details",
    params
  ).then((data) => {
    return data;
  });
}

export async function editPastExperince(params) {
  return postEncodedData("/admin/user/add_update_past_experience", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editFamilyDetails(params) {
  return postEncodedData("/admin/user/add_update_family_details", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editLifeStyle(params) {
  return postEncodedData("/add_update_life_style", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editPets(params) {
  return postEncodedData("/admin/user/add_update_gender", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editHobbies(params) {
  return postEncodedData("/add_update_hobbies", params).then(
    (data) => {
      return data;
    }
  );
}

export async function editPartnerPreferences(params) {
  return postEncodedData(
    "/admin/user/add_update_partner_preferences",
    params
  ).then((data) => {
    return data;
  });
}

// Question Answers

export async function addAnswer1(params) {
  return postEncodedData("/add_update_answer_one", params).then((data) => {
    return data;
  });
}

export async function addAnswer2(params) {
  return postEncodedData("/add_update_answer_two", params).then((data) => {
    return data;
  });
}

export async function addAnswer3(params) {
  return postEncodedData("/add_update_answer_three", params).then((data) => {
    return data;
  });
}

export async function addAnswer4(params) {
  return postEncodedData("/add_update_answer_four", params).then((data) => {
    return data;
  });
}

export async function addAboutMe(params) {
  return postEncodedData("/add_update_about_yourself", params).then((data) => {
    return data;
  });
}
export async function addMydocuments(params) {
  return postEncodedData("/add_update_documents", params).then((data) => {
    return data;
  });
}

export async function addAnswer6(params) {
  return postEncodedData("/add_update_answer_six", params).then((data) => {
    return data;
  });
}

export async function addAnswer7(params) {
  return postEncodedData("/add_update_answer_seven", params).then((data) => {
    return data;
  });
}

export async function addAnswer8(params) {
  return postEncodedData("/add_update_answer_eight", params).then((data) => {
    return data;
  });
}
