import { getData, postEncodedData, postFormData } from ".";

export async function loginUser(params) {
  return postEncodedData("/admin/login", params).then((data) => {
    return data;
  });
}

export async function forgotPassword(payload) {
  return postEncodedData("/admin/forgot_password", payload).then((data) => {
    return data;
  });
}

export async function verifyOTP(payload) {
  return postEncodedData(
    "/admin/email_verification_for_forgot_password",
    payload
  ).then((data) => {
    return data;
  });
}

export async function resendOTP(payload) {
  return postEncodedData("/admin/resend_otp", payload).then((data) => {
    return data;
  });
}

export async function resetPassword(payload) {
  return postEncodedData("/admin/reset_password", payload).then((data) => {
    return data;
  });
}

export async function changePassword(payload) {
  return postEncodedData("/admin/change_password", payload).then((data) => {
    return data;
  });
}

export async function logoutUser(payload) {
  return postEncodedData("/admin/logout", payload).then((data) => {
    return data;
  });
}

export async function fileUploader(payload) {
  return postFormData("/file_uploader", payload).then((data) => {
    return data;
  });
}

export async function removeAWSFile(payload) {
  return postEncodedData("/remove_file_aws", payload).then((data) => {
    return data;
  });
}

export async function getReligionList(params) {
  return getData("/get_religion_list", params).then((data) => {
    return data;
  });
}

export async function getCommunityList(params) {
  return getData(`get_community_list?religion_id=${params}`, params).then((data) => {
    return data;
  });
}

export async function getLanguage(params) {
  return getData("/admin/user/get_mother_tongue_list", params).then((data) => {
    return data;
  });
}

export async function getHobbies(params) {
  return getData("/get_hobbies_list", params).then((data) => {
    return data;
  });
}
export async function getRashi(params) {
  return getData("/get_horoscope_options_list", params).then((data) => {
    return data;
  });
}

export async function getCountries(params) {
  return getData("/admin/user/get_country_list", params).then((data) => {
    return data;
  });
}

export async function getStates(params) {
  return getData("/admin/user/get_state_list", params).then((data) => {
    return data;
  });
}

export async function getCities(params) {
  return getData("/admin/user/get_city_list", params).then((data) => {
    return data;
  });
}

export async function getPackageList(params) {
  return getData("/get_package_list", params).then((data) => {
    return data;
  });
}