import React, { useState } from 'react'
import ReactSlider from 'react-slider'


const Age = ({value,MIN,MAX,setValue}) => {

    return (
        <div className="d-flex justify-content-center mt-5 mb-4 mx-3">
            <h4 className='mt-2 mx-4 fbold'>Partner Age {value[0]} - {value[1]}</h4>
            <ReactSlider
                value={value}
                min={MIN}
                max={MAX}
                onChange={setValue}
            />
        </div>
    )
}

export default Age