import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getMatchLimit, idle, loading, matchLimit } from "../slice";
import { updateMatchLimit } from "../../api/users";

const UserLimits = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const data = useSelector((state) => state.matchLimit);

  useEffect(() => {
    dispatch(getMatchLimit({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      maximumLimit: data?.limit || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      maximumLimit: Yup.number().required(" *Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loading());
      let payload = {
        limit: values.maximumLimit,
      };

      updateMatchLimit(payload).then((response) => {
        enqueueSnackbar(response?.message);
        // if (response?.error_code === 0) {
        //   resetForm({});
        // }
        dispatch(idle());
      });
    },
  });

  useEffect(() => {
    return () => dispatch(matchLimit(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="user_package_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <form
                className="user_package_content"
                onSubmit={formik.handleSubmit}
              >
                {/* <div className="user_head_img">
                  <img src="images/limit-of-listing.png" alt="package-img" />
                </div> */}
                <div className="manage_salary">
                  <h4 className="manage_txt">
                    Manage your limit of listing per user
                  </h4>
                </div>
                <div className="min-package-area subs_area">
                  <div className="package_main">
                    <p className="pact_txt">
                      Limit/per user{" "}
                      {formik.touched.maximumLimit &&
                      formik.errors.maximumLimit ? (
                        <span className="error">
                          {formik.errors.maximumLimit}
                        </span>
                      ) : null}
                    </p>
                    <input
                      type="number"
                      className="package_enter subs_amt"
                      placeholder="Maximum Limit"
                      {...formik.getFieldProps("maximumLimit")}
                    />
                  </div>
                </div>
                <div className="button_area">
                  <button
                    type="submit"
                    className="save_btn"
                    disabled={status === "loading"}
                  >
                    {status === "loading" ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLimits;
