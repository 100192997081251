import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { changeuserPageNumber } from "../slice";
import { CSVLink } from "react-csv";
import SendNotifications from "./sendNotifications";
import { useToggle } from "../../app/hooks";
import UserSearch from "./search";
import { useLocation } from "react-router-dom";

const Tab = ({
  exportData,
  data,
  setHeightTo,
  heightTo,
  setHeightFrom,
  heightFrom,
  setSearchText,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOpen, toggle] = useToggle(false);
  const [isOpenSearch, toggleSearch] = useToggle(false);
  const [filterMessage, setFilterMessage] = useState("");
  // const [heightFrom, setHeightFrom] = useState("")
  // const [heightTo, setHeightTo] = useState("")

  return (
    <div className="manage_news_section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="manage_tab">
              <ul className="manage_news_inner">
                <li>
                  <NavLink
                    to="/users/new"
                    className="m_news"
                    onClick={() => {
                      (async () => {
                        await localStorage.setItem("userPageNumber", 1);
                      })();
                      dispatch(changeuserPageNumber(1));
                    }}
                  >
                    New
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/users/approved"
                    className="m_news"
                    onClick={() => {
                      (async () => {
                        await localStorage.setItem("userPageNumber", 1);
                      })();
                      dispatch(changeuserPageNumber(1));
                    }}
                  >
                    Approved
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/users/rejected"
                    className="m_news"
                    onClick={() => {
                      (async () => {
                        await localStorage.setItem("userPageNumber", 1);
                      })();
                      dispatch(changeuserPageNumber(1));
                    }}
                  >
                    Rejected
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6">
            <div className="d-flex justify-content-end mx-2 mt-3">
              {location.pathname === "/users/approved" ? (
                <button
                  type="submit"
                  className="btn save_btnn"
                  onClick={() => {
                    toggleSearch();
                    setSearchText("search applied");
                  }}
                >
                  Advance Search
                </button>
              ) : (
                ""
              )}
              {exportData?.length > 0 ? (
                <CSVLink data={exportData || ""} filename={"New User"}>
                  <button type="submit" className="btn save_btnn mx-2">
                    Export CSV
                  </button>
                </CSVLink>
              ) : (
                ""
              )}

              {location.pathname === "/users/approved" ? (
                <button
                  type="submit"
                  className="btn save_btnn mx-2"
                  onClick={() => {
                    toggle();
                  }}
                >
                  Send Notifications
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <p>{filterMessage}</p>
      </div>
      <SendNotifications
        isOpen={isOpen}
        toggle={toggle}
        data={data}
        userType="approved"
        setFilterMessage={setFilterMessage}
      />
      <UserSearch
        isOpen={isOpenSearch}
        toggle={toggleSearch}
        setHeightTo={setHeightTo}
        heightTo={heightTo}
        setHeightFrom={setHeightFrom}
        heightFrom={heightFrom}
        setSearchText={setSearchText}
      />
    </div>
  );
};

export default Tab;
