import { getData, postEncodedData, postEncodedDataDelete, postEncodedDataPatch } from ".";

export async function addUpdateBanner(params) {
  return postEncodedData("/admin/add_update_banner", params).then((data) => {
    return data;
  });
}

export async function fetchBanner(params) {
  return getData("/admin/get_banner", params).then((data) => {
    return data;
  });
}
export async function fetchBannerDetails(params) {
  return getData(`/admin/get_banner_details/${params?.id}`).then((data) => {
    return data;
  });
}
export async function deleteBanner(params) {
  return postEncodedDataDelete("/admin/remove_banner", params).then((data) => {
    return data;
  });
}
export async function updateStatusBanner(params) {
  return postEncodedDataPatch("/admin/update_banner_status", params).then((data) => {
    return data;
  });
}