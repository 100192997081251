import React from 'react'

const ManglikStatus = ({ checkedItems, setCheckedItems }) => {
    const status = ["Yes", "No", "Yes but Mild"]


    const handleCheckOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setCheckedItems([...checkedItems, value]); // add the value to the array
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== value)); // remove the value from the array
        }
    };

    console.log("checked manglik", checkedItems)


    return (
        <div className='gov text-center mb-4 mt-3'>
            <h4>Manglik Status</h4>
            {status?.map((res, i) => {
                return <div className="" key={i}>
                    <input
                        type="checkbox"
                        id="notifyAllUsers"
                        name="notifyAllUsers"
                        value={res}
                        // checked={checkedItems.includes(res?.id)}
                        onChange={handleCheckOptions}
                    />
                    <label htmlFor="groupA" className='mx-3'>
                        {res}
                    </label>
                </div>
            })}
        </div>
    )
}

export default ManglikStatus