import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { cms, fetchContactUs } from "../slice";
import { updateAboutUs, updateContactUs } from "../../api/cms";
import SimpleBackdrop from "../../components/backdrop";

const ContactUs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const data = useSelector((state) => state.contact);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchContactUs({}));
  }, [dispatch]);
  
  const formik = useFormik({
    initialValues: {
      email: data?.email || "",
      mobile: data?.mobile_no || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().required("*Required"),
      mobile: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      let payload = {
        email: values.email,
        mobile_no: values.mobile,
      };

      updateContactUs(payload).then((response) => {
        enqueueSnackbar(response?.message);
        // if (response?.error_code === 0) {
        //   resetForm({});
        // }
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    return () => dispatch(cms(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="user_package_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <form
                className="user_package_content"
                onSubmit={formik.handleSubmit}
              >
                <div className="user_head_img">
                  <img src="images/About Us vector.png" alt="package-img" />
                </div>
                <div className="manage_salary">
                  <h4 className="manage_txt">Manage contact us here.</h4>
                </div>
                <div className="min-package-area subs_area">
                  <div className="package_main">
                    <p className="pact_txt">
                      Email{" "}
                      {formik.touched.email && formik.errors.email ? (
                        <span className="error">{formik.errors.email}</span>
                      ) : null}
                    </p>
                    <input
                      type="text"
                      className="form-control shadow-box p-2"
                      placeholder="Email...."
                      rows="10"
                      {...formik.getFieldProps("email")}
                    />
                  </div>
                  <div className="package_main">
                    <p className="pact_txt">
                      Mobile Number{" "}
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <span className="error">{formik.errors.mobile}</span>
                      ) : null}
                    </p>
                    <input
                      type="text"
                      className="form-control shadow-box p-2"
                      placeholder="Mobile Number...."
                      rows="10"
                      {...formik.getFieldProps("mobile")}
                    />
                  </div>
                </div>
                <div className="button_area">
                  <button
                    type="submit"
                    className="save_btn"
                    disabled={status === "loading" || loading}
                  >
                    {loading ? <CircularProgress color="inherit" /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default ContactUs;
