import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { changeuserPageNumber, getUsersList, usersList } from "../slice";
import Header from "./Header";
import Tab from "./Tab";
import SimpleBackdrop from "../../components/backdrop";
import { useToggle } from "../../app/hooks";
import HandleDelete from "./HandleDelete";
import HandleVerify from "./HandleVerify";
import ImgFluid from "../../components/image/ImgFluid";
import { getUsersListWithoutPagination } from "../slice";

const ref = React.createRef();

const RejectedUser = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const status = useSelector((state) => state?.status);
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state?.usersList);
  const data2 = useSelector((state) => state?.usersListWithoutPagination);
  const [isOpenDelete, toggleDelete] = useToggle(false);
  const [isOpenVerify, toggleVerify] = useToggle(false);
  const [gender, setGender] = useState("");
  const [percentage, setPercentage] = useState("");
  const [page, setPage] = useState(1);
  const [userID, setUserID] = useState("");
  const [value, setValue] = useState(0);
  const userPageNumber = useSelector((state) => state?.userPageNumber);

  useEffect(() => {
    if (userPageNumber) {
      setPage(userPageNumber);
    }
  }, [userPageNumber]);
  useEffect(() => {
    dispatch(getUsersList({ tab_type: "reject", page, perpage: limit }));
    dispatch(getUsersListWithoutPagination({ tab_type: "reject" }));
  }, [dispatch, page, limit]);

  const exportData = data?.data?.map((res) => {
    return {
      "First Name": res?.first_name,
      "Middle Name": res?.middle_name,
      "Last Name": res?.last_name,
      email: res?.email,
      Mobile: res?.mobile_number,
      Gender: res?.gender,
      "D.O.B": res?.date_of_birth?.substr(0, 10),
    };
  });

  useEffect(() => {
    return () => dispatch(usersList(null));
  }, [dispatch]);
  const handleGenderSearch = (e) => {
    setGender(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({ tab_type: "new", page: 1, gender: e.target.value })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({ tab_type: "reject", page: 1, gender: e.target.value })
        );
        break;

      default:
        break;
    }
  };
  const handlePercentageSearch = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            profile_percentage: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            profile_percentage: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            profile_percentage: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };
  const handleManglikStatus = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            user_manglik_status: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            user_manglik_status: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            user_manglik_status: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };
  const handleMaritialStatus = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            user_merital_status: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            user_merital_status: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            user_merital_status: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };
  const handleFamilyType = (e) => {
    // setGender(e.target.value)
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            family_type: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            family_type: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            family_type: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };

  const handleFamilyStatus = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            family_status: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            family_status: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            family_status: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };

  return (
    <div className="m-main-content">
      <Header />
      <Tab exportData={exportData} />
      <div className="row">
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num mx-3"> Gender</label>
          <select
            className="form-control ps-4 mx-2"
            onChange={(e) => {
              handleGenderSearch(e);
            }}
          >
            <option value="">All</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num">Profile Percentage</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handlePercentageSearch(e);
            }}
          >
            <option value="">All</option>
            <option value="25">Below 25 %</option>
            <option value="50">Below 50 %</option>
            <option value="75">Below 75 %</option>
            <option value="100">100 %</option>
          </select>
        </div>
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num">Manglik Status</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleManglikStatus(e);
            }}
          >
            <option value="">All</option>
            <option value="Yes">Yes</option>
            <option value="Yes but Mild">Yes but Mild</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num">Maritial Status</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleMaritialStatus(e);
            }}
          >
            <option value="">All</option>
            <option value="never married">Never married</option>
            <option value="divorced">Divorced</option>
            <option value="widow">Widow</option>
            <option value="awaiting divorce">awaiting divorce</option>
          </select>
        </div>
        <div className="col-md-2">
          <label className="num"> Family Type</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleFamilyType(e);
            }}
          >
            <option value="">All</option>
            <option value="Joint">Joint</option>
            <option value="Nuclear">Nuclear</option>
          </select>
        </div>
        <div className="col-md-2">
          <label className="num">Family Status</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleFamilyStatus(e);
            }}
          >
            <option value="">All</option>
            <option value="Middle class">Middle Class</option>
            <option value="Upper middle class">Upper Middle Class</option>
            <option value="Rich">Rich</option>
          </select>
        </div>
      </div>
      <div className="brand_table">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="brand_table_inner">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Matrimony Id</th>
                        <th scope="col">Photo</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Middle Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Email ID</th>
                        <th scope="col">Mobile Number</th>
                        <th scope="col">Address</th>
                        <th scope="col">Registration Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.length ? (
                        data.data.map((el, i) => (
                          <tr key={el._id}>
                            <td className="bt-non">
                              {data.current === 1
                                ? i + 1
                                : (data.current - 1) * 10 + (i + 1)}
                            </td>
                            <td className="bt-non">{el.veer_id || "NA"}</td>
                            <td className="bt-non">
                              <div className="p_pic">
                                <ImgFluid
                                  src={el?.profile_images?.map((res) => {
                                    return res?.is_profile === 1
                                      ? res?.image_url
                                      : "NA";
                                  })}
                                />
                              </div>
                            </td>
                            <td className="bt-non">{el.first_name || "N/A"}</td>
                            <td className="bt-non">
                              {el.middle_name || "N/A"}
                            </td>
                            <td className="bt-non">{el.last_name || "N/A"}</td>
                            <td className="bt-non">{el.gender || "N/A"}</td>
                            <td className="bt-non">{el.email || "N/A"}</td>
                            <td className="bt-non">
                              {`${el.country_code} ${el.mobile_number}` ||
                                "N/A"}
                            </td>
                            <td className="bt-non">
                              {`${el.city || ""} ${el.state || ""} ${
                                el.country || ""
                              }`}
                            </td>
                            <td className="bt-non">
                              {el?.createdAt?.substr(0, 10) || "NA"}
                            </td>
                            <td className="bt-non">
                              <span className="table-action">
                                <Link
                                  to={`/users/rejected/${el._id}`}
                                  title="View"
                                >
                                  <img
                                    src="images/view.png"
                                    className="action-view"
                                    alt="view"
                                  />
                                </Link>
                                <button
                                  style={{ background: "none", border: "none" }}
                                  title="Approve"
                                  onClick={() => {
                                    setUserID(el._id);
                                    setValue(1);
                                    toggleVerify();
                                  }}
                                >
                                  <img
                                    src="images/approve.png"
                                    className="action-view"
                                    alt="approve"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setUserID(el._id);
                                    toggleDelete();
                                  }}
                                  style={{ background: "none", border: "none" }}
                                  title="Delete"
                                >
                                  <img
                                    src="images/delete.png"
                                    className="action-view"
                                    alt="delete"
                                  />
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={13} style={{ padding: "10px" }}>
                            No Results Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {data?.pages > 1 ? (
                    <Pagination
                      count={data.pages}
                      page={data.current}
                      onChange={(e, page) => {
                        (async () => {
                          await localStorage.setItem("userPageNumber", page);
                        })();
                        dispatch(changeuserPageNumber(page));
                        setPage(page);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleBackdrop open={status === "loading"} />
      <HandleDelete
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        userID={userID}
        page={page}
        userType="reject"
      />
      <HandleVerify
        isOpen={isOpenVerify}
        toggle={toggleVerify}
        userID={userID}
        page={page}
        userType="reject"
        status={value}
      />
    </div>
  );
};

export default RejectedUser;
