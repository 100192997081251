import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SimpleBackdrop from "../../../../components/backdrop";
import { getUserDetails } from "../../../slice";
import Q1Description from "./Q1Description";
import Q2PassionateAbout from "./Q2PassionateAbout";
import Q3Vacation from "./Q3Vacation";
import Q4LifePartner from "./Q4LifePartner";
import Q6InspirationalPerson from "./Q6InspirationalPerson";
import Q7ImportantThings from "./Q7ImportantThings";
import Q8SuccessInterpretation from "./Q8SuccessInterpretation";

const Questionnaire = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let { path } = useRouteMatch();
  const { userID } = useParams();
  const { goBack } = useHistory();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  return (
    <div className="m-main-content">
      <div className="manage_news_section">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Questions"
              : "Add User Questions"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="manage_tab">
                <ul className="manage_news_inner">
                  <li>
                    <NavLink
                      to={`/users/edit-question/${userID}/description-phrase`}
                      className="m_news"
                    >
                      Question 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/users/edit-question/${userID}/passionate-about`}
                      className="m_news"
                    >
                      Question 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/users/edit-question/${userID}/vacation-idea`}
                      className="m_news"
                    >
                      Question 3
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/users/edit-question/${userID}/life-partner`}
                      className="m_news"
                    >
                      Question 4
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/users/edit-question/${userID}/notice-about-me`}
                      className="m_news"
                    >
                      Question 5
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/users/edit-question/${userID}/inspirational-person`}
                      className="m_news"
                    >
                      Question 6
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/users/edit-question/${userID}/important-things`}
                      className="m_news"
                    >
                      Question 7
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/users/edit-question/${userID}/success-interpretation`}
                      className="m_news"
                    >
                      Question 8
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <Switch>
              <Route exact path={`${path}/description-phrase`}>
                <Q1Description data={data?.userAnswersDetails} />
              </Route>
              <Route exact path={`${path}/passionate-about`}>
                <Q2PassionateAbout data={data?.userAnswersDetails} />
              </Route>
              <Route exact path={`${path}/vacation-idea`}>
                <Q3Vacation data={data?.userAnswersDetails} />
              </Route>
              <Route exact path={`${path}/life-partner`}>
                <Q4LifePartner data={data?.userAnswersDetails} />
              </Route>

              <Route exact path={`${path}/inspirational-person`}>
                <Q6InspirationalPerson data={data?.userAnswersDetails} />
              </Route>
              <Route exact path={`${path}/important-things`}>
                <Q7ImportantThings data={data?.userAnswersDetails} />
              </Route>
              <Route exact path={`${path}/success-interpretation`}>
                <Q8SuccessInterpretation data={data?.userAnswersDetails} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default Questionnaire;
