import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getUsersCount, getUsersList, usersCount } from "../slice";

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const count = useSelector((state) => state.usersCount);
  const limit = useSelector((state) => state?.limit);

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getUsersCount({}));
  }, []);


  const handleSearch = (e) => {
    e.preventDefault();
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({ tab_type: "new", page: 1, perpage: limit, search })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            perpage: limit,
            search,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({ tab_type: "reject", page: 1, perpage: limit, search })
        );
        break;

      default:
        break;
    }
  };

  return (
    <div className="brand_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <div className="brand_head_data">
              <div className="brands_total">
                <img src="images/Manage-Users-white.png" alt="user" />
                <p className="t_brand_txt">Total Users</p>
                <h4 className="brand_num">{count?.total_user || "00"}</h4>
              </div>
              <div className="brands_total">
                <img src="images/Manage-Users-white.png" alt="user" />
                <p className="t_brand_txt">New Users</p>
                <h4 className="brand_num">{count?.new_users || "00"}</h4>
              </div>
              <div className="brands_total">
                <img src="images/Manage-Users-white.png" alt="user" />
                <p className="t_brand_txt">Approved Users</p>
                <h4 className="brand_num">{count?.approved_user || "00"}</h4>
              </div>
              <div className="brands_total">
                <img src="images/Manage-Users-white.png" alt="user" />
                <p className="t_brand_txt">Rejected Users </p>
                <h4 className="brand_num">{count?.reject_user || "00"}</h4>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="search_area">
              <form className="top-search-bar" onSubmit={handleSearch}>
                <input
                  type="text"
                  name="search"
                  value={search}
                  placeholder="Search by name/email/mobile."
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search ? (
                  <img
                    src="images/cancel.png"
                    className="search_icon pointer"
                    alt="clear"
                    onClick={() => setSearch("")}
                  />
                ) : (
                  <img
                    src="images/search.png"
                    className="search_icon"
                    alt="search"
                  />
                )}
              </form>
              <Link to="/users/add" className="brand_add">
                <img src="images/add.png" alt="add" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
