import React from 'react'

const FamilyIncome = ({ checkedItems, setCheckedItems }) => {

    const incomeList = [
        {
            "label": "1 Lacs-2 Lacs",
            "value": "100000-200000"
        },
        {
            "label": "2 Lacs-5 Lacs",
            "value": "200000-500000"
        },
        {
            "label": "5 Lacs-10 Lacs",
            "value": "500000-1000000"
        },
        {
            "label": "10 Lacs-25 Lacs",
            "value": "1000000-2500000"
        },
        {
            "label": "25 Lacs-50 Lacs",
            "value": "2500000-5000000"
        },
        {
            "label": "50 Lacs-75 Lacs",
            "value": "5000000-7500000"
        },
        {
            "label": "75 Lacs -1 Cr",
            "value": "7500000-10000000"
        },
        {
            "label": "Greater than 1Cr",
            "value": "10000000 - Above"
        }
    ]


    const handleCheckOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setCheckedItems([...checkedItems, value]); // add the value to the array
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== value)); // remove the value from the array
        }
    };


    return (
        <div className='gov text-center mb-4 mt-3'>
            <h4>Family Income</h4>
            {incomeList?.map((res, i) => {
                return <div className="" key={i}>
                    <input
                        type="checkbox"
                        id="notifyAllUsers"
                        name="notifyAllUsers"
                        value={res?.value}
                        // checked={checkedItems.includes(res?.id)}
                        onChange={handleCheckOptions}
                    />
                    <label htmlFor="groupA" className='mx-3'>
                        {res?.label}
                    </label>
                </div>
            })}
        </div>
    )
}

export default FamilyIncome