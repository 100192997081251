import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { cms, fetchTnC } from "../slice";
import { updateTnC } from "../../api/cms";
import SimpleBackdrop from "../../components/backdrop";
import JoditEditor from 'jodit-react';


const TermsNconditions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const data = useSelector((state) => state.cms);
  const [loading, setLoading] = useState(false);
  const editor = useRef(null);
  const [content, setContent] = useState(data?.description);

  useEffect(() => {
    dispatch(fetchTnC({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      tnc: data?.description || "",
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      let payload = {
        description: content,
      };

      updateTnC(payload).then((response) => {
        enqueueSnackbar(response?.message);
        // if (response?.error_code === 0) {
        //   resetForm({});
        // }
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    return () => dispatch(cms(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="user_package_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <form
                className="user_package_content"
                onSubmit={formik.handleSubmit}
              >
                <div className="user_head_img">
                  <img src="images/Terms & Conditions.png" alt="package-img" />
                </div>
                <div className="manage_salary">
                  <h4 className="manage_txt">
                    Manage terms and conditions here.
                  </h4>
                </div>
                <div className="min-package-area subs_area">
                  <div className="package_main">
                    <p className="pact_txt">
                      Terms and Conditions{" "}
                      {formik.touched.tnc && formik.errors.tnc ? (
                        <span className="error">{formik.errors.tnc}</span>
                      ) : null}
                    </p>
                    {/* <textarea
                      type="text"
                      className="form-control shadow-box p-2"
                      placeholder="Describe here..."
                      rows="10"
                      {...formik.getFieldProps("tnc")}
                    /> */}
                    <JoditEditor
                      ref={editor}
                      value={content}
                      tabIndex={1}
                      onBlur={newContent => setContent(newContent)}
                      {...formik.getFieldProps("tnc")}
                      onChange={newContent => setContent(newContent)}
                    />
                  </div>
                </div>
                <div className="button_area">
                  <button
                    type="submit"
                    className="save_btn"
                    disabled={status === "loading" || loading}
                  >
                    {loading ? <CircularProgress color="inherit" /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default TermsNconditions;
