import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { verifyProfilePic } from "../../api/users";
import { useEffect } from "react";
import { getUserDetails, userDetails } from "../slice";
import axios from "axios";
import { getCookie } from "../../utils";


const PhotosModal = ({ isOpen, toggle, image }) => {

    useEffect(() => {
        document.body.classList.toggle("modal-open", isOpen);
    }, [isOpen]);


    return (
        <>
            <div
                className={isOpen ? "modal fade show" : "modal fade"}
                id="delUserDetails"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={toggle}
                            >
                                &times;
                            </button>
                        </div>
                        <img
                            src={image}
                            className="action-view mb-5"
                            alt="approve"
                        />
                    </div>
                </div>

            </div>
            {isOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

PhotosModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    userID: PropTypes.string.isRequired,
    page: PropTypes.number,
    userType: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
};

export default PhotosModal;
