export function setCookie(name, value, seconds) {
  let expires = "";
  if (seconds) {
    let date = new Date();
    date.setTime(date.getTime() + seconds * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function getParameterByName() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
}

export function min18Years() {
  var maxDate = new Date();
  maxDate.setFullYear(
    maxDate.getFullYear() - 18,
    maxDate.getMonth(),
    maxDate.getDate()
  );
  return maxDate;
}

export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return Math.round(age);
}

export function getRange(minimum = 0, maximum = 1, gap) {
  let min = Number(minimum);
  let max = Number(maximum);
  const difference = Math.round((max - min) / 10);
  var range = [];

  while (min < max) {
    range.push(min);
    if (gap) {
      min += gap;
    } else {
      min += difference;
    }
  }

  return range;
}

export const convertTime24to12 = (time24h) => {
  let [hours, minutes] = time24h.split(":");
  var AmOrPm = Number(hours) >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  var finalTime = hours + ":" + minutes + " " + AmOrPm;
  return finalTime;
};
