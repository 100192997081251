import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../slice";
import { editLifeStyle } from "../../../api/edit";
import SimpleBackdrop from "../../../components/backdrop";
import { Link } from "react-router-dom";
import UserProfile from "../details/Profile";

const LifeStyle = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);
  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      diet: data?.lifestylesDetails?.diet || "vegetarian",
      drinking: data?.lifestylesDetails?.drinking || "No drinking",
      smoking: data?.lifestylesDetails?.smoking || "No smoking",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      diet: Yup.string().required("*Required"),
      drinking: Yup.string().required("*Required"),
      smoking: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);

      editLifeStyle({ ...values, userid: userID }).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          // goBack();
          dispatch(getUserDetails({ user_id: userID }));
        }
      });
    },
  });

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Lifestyle Details"
              : "Add User Lifestyle Details"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img
                src="images/edit.png"
                className="action-view"
                alt="edit"
              />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                {/* <Link to={`/users/edit-gender/${userID}`}>Gender</Link> */}
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>
                  Lifestyle
                </Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                  My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>
                  About Me
                </Link>
                <Link to={`/users/my-document/${userID}`}>
                  My Document
                </Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 mt-5">
              <form className="row form-style" onSubmit={formik.handleSubmit}>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="diet">
                    <div className="">
                      <h6 className="mb-0">Diet</h6>
                    </div>
                    <div className="d-flex gander blood-group religion">
                      <label>
                        <input
                          type="radio"
                          name="diet"
                          checked={formik.values.diet === "vegetarian"}
                          onChange={() =>
                            formik.setFieldValue("diet", "vegetarian")
                          }
                        />
                        <div className="div-bg">
                          <div>
                            <img
                              src="images/Vegetarian.png"
                              className="unselected"
                              alt=""
                            />
                            <img
                              src="images/Vegetarian-white.png"
                              className="selected"
                              alt=""
                            />
                          </div>
                          <p>
                            <span>Vegetarian</span>
                          </p>
                        </div>
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="diet"
                          checked={formik.values.diet === "non_vegetarian"}
                          onChange={() =>
                            formik.setFieldValue("diet", "non_vegetarian")
                          }
                        />
                        <div className="div-bg">
                          <div>
                            <img
                              src="images/Non-Vegetarian.png"
                              className="unselected"
                              alt=""
                            />
                            <img
                              src="images/Non-Vegetarian-white.png"
                              className="selected"
                              alt=""
                            />
                          </div>
                          <p>
                            <span>Non-Vegetarian</span>
                          </p>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="diet"
                          checked={formik.values.diet === "eggetarian"}
                          onChange={() =>
                            formik.setFieldValue("diet", "eggetarian")
                          }
                        />
                        <div className="div-bg">
                          <div>
                            <img
                              src="images/Eggunselect.png"
                              className="unselected"
                              alt=""
                            />
                            <img
                              src="images/EggSelected.png"
                              className="selected"
                              alt=""
                            />
                          </div>
                          <p>
                            <span>Eggetarian</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="diet drinking">
                    <div className="">
                      <h6 className="mb-0">Drinking</h6>
                    </div>
                    <div className="gander blood-group religion">
                      <label>
                        <input
                          type="radio"
                          name="drinking"
                          checked={formik.values.drinking === "no_drinking"}
                          onChange={() =>
                            formik.setFieldValue("drinking", "no_drinking")
                          }
                        />
                        <div className="div-bg">
                          <p>
                            <span>No Drinking</span>
                          </p>
                        </div>
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="drinking"
                          checked={formik.values.drinking === "drinking"}
                          onChange={() =>
                            formik.setFieldValue("drinking", "drinking")
                          }
                        />
                        <div className="div-bg">
                          <p>
                            <span>Drinking</span>
                          </p>
                        </div>
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="drinking"
                          checked={formik.values.drinking === "occasionally"}
                          onChange={() =>
                            formik.setFieldValue("drinking", "occasionally")
                          }
                        />
                        <div className="div-bg">
                          <p>
                            <span>Occasionally</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="diet drinking">
                    <div className="">
                      <h6 className="mb-0">Smoking</h6>
                    </div>
                    <div className="gander blood-group religion">
                      <label>
                        <input
                          type="radio"
                          name="smoking"
                          checked={formik.values.smoking === "no_smoking"}
                          onChange={() =>
                            formik.setFieldValue("smoking", "no_smoking")
                          }
                        />
                        <div className="div-bg">
                          <p>
                            <span>No Smoking</span>
                          </p>
                        </div>
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="smoking"
                          checked={formik.values.smoking === "smoking"}
                          onChange={() =>
                            formik.setFieldValue("smoking", "smoking")
                          }
                        />
                        <div className="div-bg">
                          <p>
                            <span>Smoking</span>
                          </p>
                        </div>
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="smoking"
                          checked={formik.values.smoking === "occasionally"}
                          onChange={() =>
                            formik.setFieldValue("smoking", "occasionally")
                          }
                        />
                        <div className="div-bg">
                          <p>
                            <span>Occasionally</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default LifeStyle;
