/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";

import { changePassword } from "../../../api/accounts";
import { idle, loading, userLogout } from "../../slice";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { replace } = useHistory();

  const status = useSelector((state) => state.status);

  const [showOldPwd, setShowOldPwd] = useState(false);
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [showCnfPwd, setShowCnfPwd] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("*Required"),
      newPassword: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .required("*Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loading());
      const payload = {
        current_password: values.oldPassword,
        new_password: values.confirmPassword,
      };
      changePassword(payload).then((response) => {
        enqueueSnackbar(response?.message);
        if (!response.error_code) {
          resetForm({});
          dispatch(idle());
          dispatch(userLogout());
          replace("/login");
        }
      });
    },
  });

  return (
    <div className="m-main-content">
      <div className="setting_main">
        <div className="container-fluid">
          <div className="login_main settings">
            <div className="row">
              <div className="col-md-5 offset-md-3">
                <div className="login_right_area">
                  <div className="lon_in_content">
                    <div className="log_in_head">
                      <img
                        src="images/reset.png"
                        alt="Forgot"
                        className="img-fluid"
                      />
                      <h1 className="login_heading-txt forgot_head_txt">
                        Change Password
                      </h1>
                    </div>
                    <div className="registration_txt">
                      <p>Please change your password here</p>
                    </div>
                    <form className="login_form" onSubmit={formik.handleSubmit}>
                      {formik.touched.oldPassword &&
                      formik.errors.oldPassword ? (
                        <span className="error">
                          {formik.errors.oldPassword}
                        </span>
                      ) : null}
                      <div className="form_input_main form-group">
                        <input
                          type={showOldPwd ? "text" : "password"}
                          className="log_inp_area form-control"
                          placeholder="Current Password"
                          {...formik.getFieldProps("oldPassword")}
                        />
                        <img
                          src="images/password.png"
                          alt="pwd"
                          className="inp_icon2"
                        />
                        <img
                          src="images/view.png"
                          alt="pwd"
                          className="view_icon"
                          onClick={() => setShowOldPwd(!showOldPwd)}
                        />
                      </div>
                      {/* <div className="forgot_area settings-forgot">
                        <Link to="/forgot-password" className="forgot_pwd_txt">
                          Forgot Password?
                        </Link>
                      </div> */}
                      <small className="new-password-condition">
                        *New Password Must Contain 8 Characters, One Uppercase,
                        One Lowercase, One Number and One Special Case Character
                      </small>
                      {formik.touched.newPassword &&
                      formik.errors.newPassword ? (
                        <div className="error">{formik.errors.newPassword}</div>
                      ) : null}
                      <div className="form_input_main form-group">
                        <input
                          type={showNewPwd ? "text" : "password"}
                          className="log_inp_area form-control"
                          placeholder="New Password"
                          {...formik.getFieldProps("newPassword")}
                        />
                        <img
                          src="images/password.png"
                          alt="pwd"
                          className="inp_icon2"
                        />
                        <img
                          src="images/view.png"
                          alt="pwd"
                          className="view_icon"
                          onClick={() => setShowNewPwd(!showNewPwd)}
                        />
                      </div>
                      {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <div className="error">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                      <div className="form_input_main form-group">
                        <input
                          type={showCnfPwd ? "text" : "password"}
                          className="log_inp_area form-control"
                          placeholder="Confirm New Password"
                          {...formik.getFieldProps("confirmPassword")}
                        />
                        <img
                          src="images/password.png"
                          alt="pwd"
                          className="inp_icon2"
                        />
                        <img
                          src="images/view.png"
                          alt="pwd"
                          className="view_icon"
                          onClick={() => setShowCnfPwd(!showCnfPwd)}
                        />
                      </div>
                      <div className="log_in_btn">
                        <button
                          type="submit"
                          className="login_btn"
                          disabled={status === "loading"}
                        >
                          {status === "loading" ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            "Done"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
