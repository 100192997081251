import React, { useState } from "react";
import { useDispatch } from "react-redux";
import HandleVerifyProfilePic from "../handleVerifyProfilePic";


const regex =
  /\.(png|jpg|jpeg|pdf|PNG|JPG|JPEG|avif|apng|gif|jfif|pjpeg|pjp|svg|webp)$/;

const MediaItems = ({ data, allData }) => {
  const [verifyProfilePic, setVerifyProfilePic] = useState(false)
  const [status, setStatus] = useState(0)
  const [imageId, setImageId] = useState("")

  const handleProfilePic = () => {
    setVerifyProfilePic(!verifyProfilePic)
  }

  return (
    <div className="new_user_main">
      <div className="row justify-content-center">
        {data?.length
          ? data.map((el, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-2 mx-3" key={index}>
                <img
                  src={el?.image_url}
                  alt="img"
                  className="w-100 userImage"
                />
                {el?.is_verified === 1 ? <h5 className="text-center mt-3" style={{ color: "green", fontWeight: "bold" }}>Approved</h5> : ""}
                {el?.is_verified === 0 ? <h5 className="text-center mt-3" style={{ color: "orange", fontWeight: "bold" }}>Approval Pending</h5> : ""}
                {el?.is_verified === 2 ? <h5 className="text-center mt-3" style={{ color: "#c03", fontWeight: "bold" }}> <img
                  src="images/reject.png"
                  className="action-view"
                  alt="reject"
                />Rejected</h5> : ""}
                
                {/* {el?.is_verified === 0 ?
                  <div className="table-action text-center mt-2">
                    <button
                      style={{ background: "none", border: "none" }}
                      title="Approve"
                      onClick={() => {
                        setStatus(1)
                        setImageId(el?._id)
                        handleProfilePic()
                      }}
                    >
                      <img
                        src="images/approve.png"
                        className="action-view"
                        alt="approve"
                      />
                    </button>
                    <button
                      style={{ background: "none", border: "none" }}
                      title="Reject"
                      onClick={() => {
                        // setUserID(el._id);
                        // setValue(2);
                        setStatus(2)
                        handleProfilePic()
                        setImageId(el?._id)
                      }}
                    >
                      <img
                        src="images/reject.png"
                        className="action-view"
                        alt="reject"
                      />
                    </button>
                  </div> : ""} */}
              </div>
            );
          })
          : null}
      </div>

      <HandleVerifyProfilePic
        isOpen={verifyProfilePic}
        toggle={handleProfilePic}
        userID={allData?._id}
        imageId={imageId}
        // page={page}
        // userType="new"
        status={status}
      />
    </div>
  );
};

export default MediaItems;
