import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { addAnswer3 } from "../../../../api/edit";
import { getUserDetails } from "../../../slice";

const Q3Vacation = ({ data }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { userID } = useParams();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      answer: data?.answer_three || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      answer: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      let payload = {
        userid: userID,
        answer_three: values.answer,
      };

      addAnswer3(payload).then((response) => {
        setLoading(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          dispatch(getUserDetails({ user_id: userID }));
        }
      });
    },
  });

  return (
    <form
      className="row profile-form form-style"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group col-sm-12 col-md-12 text-center mb-5">
        <div className="">
          <img src="images/question3.png" className="img-fluid" alt="" />
        </div>
      </div>
      <div className="form-group col-sm-12 col-md-12 mb-4">
        <h5>My Idea of a Vacation?</h5>
        <textarea
          className="form-control shadow-box p-2 question-area"
          rows="7"
          placeholder="Your answer...."
          {...formik.getFieldProps("answer")}
        ></textarea>
      </div>
      <div className="form-group col-sm-12 col-md-12 text-center">
        <div className="button_area">
          <button type="submit" className="save_btn" disabled={loading}>
            {loading ? <CircularProgress color="inherit" /> : "Save Changes"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Q3Vacation;
