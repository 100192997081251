import React from "react";
import { components } from "react-select";

const BirthIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src="images/partner-Year-of-Birth.png" alt="Bithday Cake" />
    </components.DropdownIndicator>
  );
};

export default BirthIndicator;
