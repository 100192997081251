import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import {
    getOffer,
    getUsersList,
    usersCount,
    usersList,
} from "../slice";
import SimpleBackdrop from "../../components/backdrop";
import { useToggle } from "../../app/hooks";
import { getNotification } from "../slice";
import AddNotification from "./addNotification";
import DetailNotification from "./notificationDetail";
import DeleteNotification from "./deleteNotification";



const NotificationList = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state?.status);
    const limit = useSelector((state) => state?.limit);
    const data = useSelector((state) => state?.notification);
    const count = useSelector((state) => state.usersCount);
    const [activePage, setActivePage] = useState(1);
    const [isOpen, toggle] = useToggle(false);
    const [isOpenDetail, toggleDetail] = useToggle(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [id, setId] = useState("");
    const [isOpenDelete, toggleDelete] = useToggle(false);



    useEffect(() => {
        dispatch(getNotification({ page: 1 }))
    }, [dispatch, page, limit]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        // setPageNo(pageNumber)
        dispatch(getNotification({ page: pageNumber }))
    }
    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(
            getUsersList({ tab_type: "deactivated", page: 1, perpage: limit, search })
        );
    };

    useEffect(() => {
        return () => dispatch(usersList(null));
    }, [dispatch]);

    useEffect(() => {
        return () => dispatch(usersCount(null));
    }, [dispatch]);

    return (
        <div className="m-main-content">
            <div className="brand_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="brand_head_data">
                                <div className="brands_total deactive_user">
                                    {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                                    <p className="t_brand_txt">Total Notification</p>
                                    <h4 className="brand_num">{data?.total_notification || "00"}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end col-md-8">
                            {/* <span className="brand_add mt-5" onClick={toggle}>
                                <img src="images/add.png" alt="add" />
                            </span> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="brand_table">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="brand_table_inner">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Notification Title</th>
                                                <th scope="col">Created Date</th>
                                                <th scope="col">Updated Date</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data?.notification_list?.length ? (
                                                data?.notification_list?.map((el, i) => (
                                                    <tr key={i}>
                                                        <td className="bt-non">
                                                            {i + 1}
                                                        </td>
                                                        <td className="bt-non">
                                                            {el?.title}
                                                        </td>
                                                        <td className="bt-non">
                                                            {el?.createdAt?.substr(0, 10)}
                                                        </td>
                                                        <td className="bt-non">
                                                            {el?.updatedAt?.substr(0, 10)}
                                                        </td>
                                                        <td className="bt-non">
                                                            <span className="table-action">

                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggleDetail()
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Delete"
                                                                >
                                                                    <img
                                                                        src="images/view.png"
                                                                        className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggleDelete();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Delete"
                                                                >
                                                                    <img
                                                                        src="images/delete.png"
                                                                        className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} style={{ padding: "10px" }}>
                                                        No Results Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {data?.total_notification
                                        >= 9 ?
                                        <div className='mt-4'>
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={data?.total_notification
                                                }
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                            />
                                        </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleBackdrop open={status === "loading"} />
            {isOpen ? <AddNotification show={isOpen} handleShow={toggle} /> : ""}
            {isOpenDetail ? <DetailNotification show={isOpenDetail} handleShow={toggleDetail} id={id} /> : ""}
            <DeleteNotification
                isOpen={isOpenDelete}
                toggle={toggleDelete}
                id={id}
                page={page}
                userType="deactivated"
            />
        </div>
    );
};

export default NotificationList;
