import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

import { getUserDetails } from "../../slice";
import { editImagesVideos } from "../../../api/edit";
import { fileUploader, removeAWSFile } from "../../../api/accounts";
import SimpleBackdrop from "../../../components/backdrop";

const regex =
  /\.(png|jpg|jpeg|pdf|PNG|JPG|JPEG|avif|apng|gif|jfif|pjpeg|pjp|svg|webp)$/;

const ImagesVideos = () => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      files: data?.profile_image_video_path || [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      files: Yup.array().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      let payload = {
        userid: userID,
        profile_image_video_path: values.files,
      };

      editImagesVideos(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          goBack();
        }
      });
    },
  });

  const addFile = async (event) => {
    const file = event.target.files[0];
    const fileType = file?.type?.split("/")[0];
    const fileSize = file?.size;
    if (fileType === "image") {
      if (Math.floor(fileSize / 1000000) > 50) {
        enqueueSnackbar("File size should not exceed 50 MB");
      } else {
        upload(file);
      }
    } else if (fileType === "video") {
      if (Math.floor(fileSize / 1000000) > 200) {
        enqueueSnackbar("File size should not exceed 200 MB");
      } else {
        upload(file);
      }
    } else {
      enqueueSnackbar("This file type is not accepted.");
    }

    function upload(file) {
      setUploading(true);
      let formData = new FormData();
      formData.append("profile_image", file);
      fileUploader(formData).then(async (response) => {
        setUploading(false);
        if (response?.error_code === 0 && response?.data?.path) {
          formik.setFieldError("files", "");
          await formik.setFieldValue("files", [
            ...formik.values.files,
            response.data.path,
          ]);
        }
      });
    }
  };

  const removeFile = (index) => {
    const file = formik.values.files.find((el, i) => index === i);
    const newFiles = formik.values.files.filter((f, i) => i !== index);
    formik.setFieldValue("files", [...newFiles]);
    if (typeof file === "string" || file?.path) {
      removeAWSFile({ path: typeof file === "string" ? file : file?.path });
    }
  };

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Photo/Video"
              : "Add User Photo/Video"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <div className="">
                    <img
                      src="images/upload-photo.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <small>Max 5 Photo can be uploaded</small>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="text-center">
                    <Tooltip
                      title={`Photo Guidelines: 
                      Do not upload photos that show only a side of a face or where face is only partly visible.
                      Do not upload blurred / enhanced photos.
                      Please ensure that you do not upload celebrity / scenery / obscenity / irrelevant photos.
                      Do not upload watermarked or digitally edited photos.
                      Do not upload group photos.
                      Do not mention your contact information in the photograph.
                      Non-compliance to any of the above points shall result in immediate deactivation of your membership.`}
                      placement="top"
                    >
                      <span className="Guidelines">
                        Guidelines <i className="fa fa-info-circle"></i>
                      </span>
                    </Tooltip>
                  </div>
                  <div className="Guidelines-point profile-upload">
                    <div className="">
                      <span className="Guidelines-box">Clear Face</span>
                    </div>
                    <div className="">
                      <span className="Guidelines-box">No Sun-Glasses</span>
                    </div>
                    <div className="">
                      <span className="Guidelines-box">No Group Photo</span>
                    </div>
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="profile-upload">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <div className="wrapper">
                          <div className="file-upload">
                            <input type="file" onChange={addFile} />
                            {formik.values.files?.[0] ? (
                              formik.values.files[0]
                                ?.toLowerCase()
                                ?.match(regex) ? (
                                <img src={formik.values.files[0]} alt="" />
                              ) : (
                                <video
                                  src={formik.values.files[0]}
                                  width="100%"
                                  height="100%"
                                  controls
                                  type="video/*"
                                ></video>
                              )
                            ) : (
                              <img src="images/image-show.png" alt="" />
                            )}
                            {formik.values.files?.[0] && (
                              <button
                                type="button"
                                className="remove-photo"
                                style={{ border: "none", background: "none" }}
                                onClick={() => removeFile(0)}
                              >
                                <img src="images/remove-photo.png" alt="" />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <div className="wrapper">
                          <div className="file-upload">
                            <input type="file" onChange={addFile} />
                            {formik.values.files?.[1] ? (
                              formik.values.files[1]
                                ?.toLowerCase()
                                ?.match(regex) ? (
                                <img src={formik.values.files[1]} alt="" />
                              ) : (
                                <video
                                  src={formik.values.files[1]}
                                  width="100%"
                                  height="100%"
                                  controls
                                  type="video/*"
                                ></video>
                              )
                            ) : (
                              <img src="images/image-show.png" alt="" />
                            )}
                            {formik.values.files?.[1] && (
                              <button
                                type="button"
                                className="remove-photo"
                                style={{ border: "none", background: "none" }}
                                onClick={() => removeFile(1)}
                              >
                                <img src="images/remove-photo.png" alt="" />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <div className="wrapper">
                          <div className="file-upload">
                            <input type="file" onChange={addFile} />
                            {formik.values.files?.[2] ? (
                              formik.values.files[2]
                                ?.toLowerCase()
                                ?.match(regex) ? (
                                <img src={formik.values.files[2]} alt="" />
                              ) : (
                                <video
                                  src={formik.values.files[2]}
                                  width="100%"
                                  height="100%"
                                  controls
                                  type="video/*"
                                ></video>
                              )
                            ) : (
                              <img src="images/image-show.png" alt="" />
                            )}
                            {formik.values.files?.[2] && (
                              <button
                                type="button"
                                className="remove-photo"
                                style={{ border: "none", background: "none" }}
                                onClick={() => removeFile(2)}
                              >
                                <img src="images/remove-photo.png" alt="" />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <div className="wrapper">
                          <div className="file-upload">
                            <input type="file" onChange={addFile} />
                            {formik.values.files?.[3] ? (
                              formik.values.files[3]
                                ?.toLowerCase()
                                ?.match(regex) ? (
                                <img src={formik.values.files[3]} alt="" />
                              ) : (
                                <video
                                  src={formik.values.files[3]}
                                  width="100%"
                                  height="100%"
                                  controls
                                  type="video/*"
                                ></video>
                              )
                            ) : (
                              <img src="images/image-show.png" alt="" />
                            )}
                            {formik.values.files?.[3] && (
                              <button
                                type="button"
                                className="remove-photo"
                                style={{ border: "none", background: "none" }}
                                onClick={() => removeFile(3)}
                              >
                                <img src="images/remove-photo.png" alt="" />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <div className="wrapper">
                          <div className="file-upload">
                            <input type="file" onChange={addFile} />
                            {formik.values.files?.[4] ? (
                              formik.values.files[4]
                                ?.toLowerCase()
                                ?.match(regex) ? (
                                <img src={formik.values.files[4]} alt="" />
                              ) : (
                                <video
                                  src={formik.values.files[4]}
                                  width="100%"
                                  height="100%"
                                  controls
                                  type="video/*"
                                ></video>
                              )
                            ) : (
                              <img src="images/image-show.png" alt="" />
                            )}
                            {formik.values.files?.[4] && (
                              <button
                                type="button"
                                className="remove-photo"
                                style={{ border: "none", background: "none" }}
                                onClick={() => removeFile(4)}
                              >
                                <img src="images/remove-photo.png" alt="" />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <div className="wrapper">
                          <div className="file-upload">
                            <input type="file" onChange={addFile} />
                            {formik.values.files?.[5] ? (
                              formik.values.files[5]
                                ?.toLowerCase()
                                ?.match(regex) ? (
                                <img src={formik.values.files[5]} alt="" />
                              ) : (
                                <video
                                  src={formik.values.files[5]}
                                  width="100%"
                                  height="100%"
                                  controls
                                  type="video/*"
                                ></video>
                              )
                            ) : (
                              <img src="images/image-show.png" alt="" />
                            )}
                            {formik.values.files?.[5] && (
                              <button
                                type="button"
                                className="remove-photo"
                                style={{ border: "none", background: "none" }}
                                onClick={() => removeFile(5)}
                              >
                                <img src="images/remove-photo.png" alt="" />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <small>Video can be uploaded for 30 sec</small>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {(uploading || status === "loading") && (
        <SimpleBackdrop open={uploading || status === "loading"} />
      )}
    </div>
  );
};

export default ImagesVideos;
