import React, { useState, useEffect } from 'react'
import { Drawer } from "antd";
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { getQueries } from '../slice';
import { addUpdateQuery } from '../../api/story';
import { useSnackbar } from "notistack";


const SupportFormResponse = ({ show, handleShow, id, userQuery, responseData }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [viewportWidth, setViewportWidth] = useState("")

    useEffect(() => {

        if (typeof (window.innerWidth) != 'undefined') {
            setViewportWidth(window.innerWidth);
        }
    }, [])
    const formik = useFormik({
        initialValues: {

            id: id,
            response: responseData ? responseData : "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            response: Yup.string().required("*Required"),

        }),
        onSubmit: (values, { resetForm }) => {
            const { id, response } = values;
            const payload = {
                request_id: id,
                response: response,
            };

            addUpdateQuery(payload).then((response) => {
                // setSaving(false);
                enqueueSnackbar(response?.message);
                if (response?.error_code === 0) {
                    resetForm({});
                    dispatch(getQueries({}));
                    handleShow()
                }
            });


        },
    });



    return (
        <>
            <Drawer placement="right" onClose={handleShow} visible={show} width={viewportWidth > 700 ? "40%" : "100%"}>

                <div className="px-5 pb-5">
                    <div className="text-center">
                        <h2 className="offcanvas-title mb-3">Manage Response </h2>
                    </div>
                    <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label mb-3">User Query :</label>
                                <div className="form-groupp">
                                    {/* <div className="input-containerr">
                                        <input type="text" className="form-control add_user_info"  placeholder="Query" />
                                    </div> */}
                                    <h5>{userQuery}</h5>

                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label">Response :</label>
                                <div className="form-groupp">
                                    <textarea className="textarea-control" rows={10} {...formik.getFieldProps("response")} placeholder="Enter response" ></textarea>
                                    {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                </div>
                                {formik.touched.response && formik.errors.response ? (
                                    <span className="error">{formik.errors.response}</span>
                                ) : null}
                            </div>

                        </div>
                        {!responseData ?
                            <div className="button_area text-center">
                                <button type="submit" className="save_btn">Submit</button>
                            </div> : ""}
                    </form>

                </div>
            </Drawer>

        </>
    )
}

export default SupportFormResponse