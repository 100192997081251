import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { getUserDetails } from "../../slice";
import UserProfile from "../details/Profile";
import { addAboutMe } from "../../../api/edit";

const AboutMe = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { pathname } = useLocation();
    const { goBack } = useHistory();
    const { userID } = useParams();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const data = useSelector((state) => state.userDetails);


    useEffect(() => {
        if (userID) {
            dispatch(getUserDetails({ user_id: userID }));
        }
    }, [dispatch, userID]);
    const formik = useFormik({
        initialValues: {
            aboutme: data?.about_yourself
                || "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            aboutme: Yup.string().required("*Required"),
        }),
        onSubmit: (values, { resetForm }) => {
            setLoading(true);
            let payload = {
                userid: userID,
                about_yourself: values.aboutme,
            };

            addAboutMe(payload).then((response) => {
                setSaving(false);
                enqueueSnackbar(response?.message);
                if (response?.error_code === 0) {
                    dispatch(getUserDetails({ user_id: userID }));
                }
            });
        },
    });

    return (
        <div className="m-main-content">
            <div className="manage_new_user_details p-5">
                <div
                    className="new_user_detail_heading"
                    style={{ position: "relative" }}
                >
                    <button className="back-arrow" onClick={goBack}>
                        <img src="images/back.png" alt="back" />
                    </button>
                    <h2 className="new_user_txt">
                        {pathname.includes("/edit")
                            ? "Edit About Me"
                            : "Add About Me"}
                    </h2>
                    <UserProfile data={data} />
                    <div className="edit-span-button">
                        <span className="position-relative edit-icon-list">
                            <img
                                src="images/edit.png"
                                className="action-view"
                                alt="edit"
                            />
                            <div className="edit-pages">
                                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                                {/* <Link to={`/users/edit-gender/${userID}`}>Gender</Link> */}
                                <Link to={`/users/edit-marital-status/${userID}`}>
                                    Personal Details
                                </Link>
                                <Link to={`/users/edit-religion/${userID}`}>
                                    Religion Details
                                </Link>
                                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                                <Link to={`/users/edit-occupation-details/${userID}`}>
                                    Professional Details
                                </Link>
                                <Link to={`/users/edit-family-details/${userID}`}>
                                    Family Details
                                </Link>
                                <Link to={`/users/edit-lifestyle/${userID}`}>
                                    Lifestyle
                                </Link>
                                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                                <Link to={`/users/edit-horoscope-details/${userID}`}>
                                    Horoscope Details
                                </Link>
                                <Link to={`/users/edit-partner-preferences/${userID}`}>
                                    My Partner Preferences
                                </Link>
                                <Link to={`/users/about-me/${userID}`}>
                                    About Me
                                </Link>
                                <Link to={`/users/my-document/${userID}`}>
                                    My Document
                                </Link>
                            </div>
                        </span>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                            <form
                                className="row "
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                                    {formik.touched.aboutme &&
                                        formik.errors.aboutme ? (
                                        <span className="error">
                                            {formik.errors.aboutme}
                                        </span>
                                    ) : null}
                                    <textarea
                                        className="form-control shadow-box p-2 "
                                        rows="14"
                                        placeholder="About Me...."
                                        {...formik.getFieldProps("aboutme")}
                                    ></textarea>
                                </div>
                                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                                    <div className="button_area">
                                        <button
                                            type="submit"
                                            className="save_btn"
                                            disabled={saving}
                                        >
                                            {saving ? (
                                                <CircularProgress color="inherit" />
                                            ) : pathname.includes("/edit") ? (
                                                "Save Changes"
                                            ) : (
                                                "Save"
                                            )}
                                        </button>
                                    </div>
                                </div>

                            </form>


                        </div>
                    </div>
                </div>
            </div>
            {/* {status === "loading" && <SimpleBackdrop open={status === "loading"} />} */}
        </div>
    );
};

export default AboutMe;
