import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import {
    getBanner,
} from "../../slice";

import { useToggle } from "../../../app/hooks";

import SimpleBackdrop from "../../../components/backdrop";
import AddBanner from "./AddBanner";
import ImgFluid from "../../../components/image/ImgFluid";
import UpdateBanner from "./UpdateBanner";
import DeleteBanner from "./DeleteBanner";


const BannerList = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state?.status);
    const limit = useSelector((state) => state?.limit);
    const data = useSelector((state) => state?.banner);
    const count = useSelector((state) => state.usersCount);

    const [isOpen, toggle] = useToggle(false);
    const [isOpenDelete, toggleDelete] = useToggle(false);
    const [isOpenEdit, toggleEdit] = useToggle(false)
    const [isOpenStatus, toggleStatus] = useToggle(false)
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [id, setId] = useState("");
    const [status2, setStatus2] = useState("")



    useEffect(() => {
        dispatch(getBanner({}))
    }, [dispatch, page, limit]);


    return (
        <div className="m-main-content">
            <div className="brand_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="brand_head_data">
                                <div className="brands_total deactive_user">
                                    {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                                    <p className="t_brand_txt">Total Banners</p>
                                    <h4 className="brand_num">{data?.total_banners}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end col-md-8">

                            <span className="brand_add mt-5" onClick={toggle}>
                                <img src="images/add.png" alt="add" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brand_table">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="brand_table_inner">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Banner Image</th>
                                                <th scope="col">Validity Date</th>
                                                <th scope="col">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data?.banner_list?.length ? (
                                                data?.banner_list?.map((el, i) => (
                                                    <tr key={i}>

                                                        <td className="bt-non">
                                                            <div className="p_pic">
                                                                <ImgFluid
                                                                    src={
                                                                        el?.image ||""
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="bt-non">
                                                            {el?.validity?.substr(0, 10)}
                                                        </td>

                                                        <td className="bt-non">
                                                            <span className="table-action">
                                                                <button
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Deactivate"
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        setStatus2(el.isActive)
                                                                        toggleStatus();
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={el?.isActive === true ? "images/on.png" : "images/off.png"}
                                                                        className="action-view"
                                                                        alt="status"
                                                                    />
                                                                </button>
                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggleEdit();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Edit"
                                                                >
                                                                    <img
                                                                        src="images/edit.png" className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggleDelete();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Delete"
                                                                >
                                                                    <img
                                                                        src="images/delete.png"
                                                                        className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} style={{ padding: "10px" }}>
                                                        No Results Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {data?.pages > 1 ? (
                                        <Pagination
                                            count={data.pages}
                                            page={data.current}
                                            onChange={(e, page) => setPage(page)}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleBackdrop open={status === "loading"} />
            {isOpen ? <AddBanner show={isOpen} handleShow={toggle} /> : ""}
            {isOpenEdit ? <AddBanner show={isOpenEdit} handleShow={toggleEdit} id={id} /> : ""}
            <UpdateBanner
                isOpen={isOpenStatus}
                toggle={toggleStatus}
                id={id}
                status={status2}
                page={page}
                userType="deactivated"
            />
            <DeleteBanner
                isOpen={isOpenDelete}
                toggle={toggleDelete}
                id={id}
                page={page}
                userType="deactivated"
            />
            {/* 
            {isOpenEdit ? <AddOffers show={isOpenEdit} handleShow={toggleEdit} id={id} /> : ""}
            */}
        </div>
    );
};

export default BannerList;
