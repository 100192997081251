import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    usersCount,
    usersList,
} from "../slice";

import SimpleBackdrop from "../../components/backdrop";
import { useToggle } from "../../app/hooks";
import DeleteTransaction from "./DeleteTransaction";
import { getTransactionList } from "../slice";
import Pagination from "react-js-pagination";



const TransactionList = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state?.status);
    const limit = useSelector((state) => state?.limit);
    const data = useSelector((state) => state?.transactionList);

    const [isOpen, toggle] = useToggle(false);
    const [isOpenDelete, toggleDelete] = useToggle(false)

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [userID, setUserID] = useState("");
    const [activePage, setActivePage] = useState(1)


    useEffect(() => {
        dispatch(getTransactionList({ page: 1 }));
    }, [dispatch]);


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        // setPageNo(pageNumber)
        dispatch(getTransactionList({ page: pageNumber }))
    }
    const handleSearch = (e) => {
        // dispatch(getTransactionList({ page: 1 }));
        e.preventDefault();
    }
    return (
        <div className="m-main-content">
            <div className="brand_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="brand_head_data">
                                <div className="brands_total deactive_user">
                                    {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                                    <p className="t_brand_txt">Total Transactions</p>
                                    <h4 className="brand_num">{data?.data?.total_transaction_count}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end col-md-8">
                            <form className="search_area" onSubmit={(e) => handleSearch(e)}>
                                <div className="top-search-bar p-top">
                                    <input
                                        type="text"
                                        name="search"
                                        value={search}
                                        placeholder="Search...."
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    {search ? (
                                        <img
                                            src="images/cancel.png"
                                            className="search_icon pointer"
                                            alt="clear"
                                            onClick={() => setSearch("")}
                                        />
                                    ) : (
                                        <img
                                            src="images/search.png"
                                            className="search_icon"
                                            alt="search"
                                        />
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brand_table">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="brand_table_inner">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">User Name</th>
                                                <th scope="col">Subscription Type</th>
                                                <th scope="col">Transaction Amount</th>
                                                <th scope="col">Currency</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Payment Mode</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data?.data?.transaction_list?.length
                                                ? (
                                                    data.data.transaction_list
                                                        .map((el, i) => (
                                                            <tr key={el._id}>
                                                                <td className="bt-non">
                                                                    {i + 1}
                                                                </td>
                                                                <td className="bt-non">
                                                                    {`${el?.userDetails?.first_name} ${el?.userDetails?.last_name}`}
                                                                </td>
                                                                <td className="bt-non">
                                                                    {el?.subscriptionDetails?.type}
                                                                </td>
                                                                <td className="bt-non">
                                                                    {el?.amount || "N/A"}
                                                                </td>
                                                                <td className="bt-non">
                                                                    {el?.currency}
                                                                </td>
                                                                <td className="bt-non">
                                                                    <span className={el?.status === "success" ? "successStatus" : "rejectedStatus"}>
                                                                        {el?.status}
                                                                    </span>
                                                                </td>
                                                                <td className="bt-non">
                                                                    {el?.createdAt?.substr(0, 10)}
                                                                </td>
                                                                <td className="bt-non">
                                                                    <span className="upi">                        {el?.paymentMode}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} style={{ padding: "10px" }}>
                                                            No Results Found
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                    {data?.data?.total_transaction_count
                                        >= 9 ?
                                        <div className='mt-4'>
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={data?.data?.total_transaction_count
                                                }
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                            />
                                        </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleBackdrop open={status === "loading"} />
            {/* {isOpen ? <SupportFormResponse show={isOpen} handleShow={toggle} /> : ""} */}
            <DeleteTransaction
                isOpen={isOpenDelete}
                toggle={toggleDelete}
                userID={userID}
                page={page}
                userType="deactivated"
            />

        </div>
    );
};

export default TransactionList;
