import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguage, getUserDetails, languageList } from "../../slice";
import { editMotherTongue } from "../../../api/edit";
import SimpleBackdrop from "../../../components/backdrop";

const MotherTongue = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const list = useSelector((state) => state.languageList);
  const status = useSelector((state) => state.status);

  const [other, setOther] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  useEffect(() => {
    dispatch(fetchLanguage({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      language: data?.mother_tongue || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      language: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      let payload = {
        userid: userID,
        mother_tongue: values.language,
      };

      editMotherTongue(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          goBack();
        }
      });
    },
  });

  useEffect(() => {
    return () => dispatch(languageList(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Mother Tongue"
              : "Add User Mother Tongue"}
          </h2>
        </div>
        <div className="container-fluid">
          <form className="row form-style" onSubmit={formik.handleSubmit}>
            <div className="form-group col-sm-12 col-md-12 text-center mb-4">
              <div className="">
                <img
                  src="images/mother-tongue.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
              <div className="gander blood-group religion community">
                {list?.data?.length
                  ? list.data.map((el) => (
                      <label key={el._id}>
                        <input
                          type="radio"
                          name="mother_tongue"
                          checked={
                            formik.values.language === el._id ||
                            (other && el.name === "Others")
                          }
                          onChange={() => {
                            formik.setFieldValue("language", el._id);
                            if (el.name === "Others") {
                              setOther(true);
                            } else {
                              setOther(false);
                            }
                          }}
                        />
                        <div className="div-bg">
                          <p>
                            <span>{el.name}</span>
                          </p>
                        </div>
                      </label>
                    ))
                  : null}
              </div>
              {other && list?.other?.length && (
                <div className="input-container mt-3">
                  <select
                    className="form-control ps-4"
                    {...formik.getFieldProps("community")}
                  >
                    <option value="">Select Your Mother Tongue</option>
                    {list.other.map((el) => (
                      <option value={el._id} key={el._id}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                  <img
                    src="images/First-Name.png"
                    className="input-img"
                    alt=""
                  />
                </div>
              )}
              {formik.errors.language ? (
                <span className="error">{formik.errors.language}</span>
              ) : null}
            </div>
            <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
              <div className="button_area">
                <button type="submit" className="save_btn" disabled={saving}>
                  {saving ? (
                    <CircularProgress color="inherit" />
                  ) : pathname.includes("/edit") ? (
                    "Save Changes"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default MotherTongue;
