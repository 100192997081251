import React, { useState } from 'react'

const AdvanceCriteria = ({ setCheckOwnhouse, setCheckAbroad }) => {


    const handleCheck = (e) => {
        setCheckOwnhouse(e.target.checked)
    }
    const handleCheckAbroad = (e) => {
        setCheckAbroad(e.target.checked)
    }


    return (
        <div className='gov text-center mb-4 mt-3'>
            <h4>Advanced Criteria</h4>
            <div className="">
                <input
                    type="checkbox"
                    id="notifyAllUsers"
                    name="notifyAllUsers"
                    onChange={handleCheck}
                />
                <label htmlFor="groupA" className='mx-3'>
                    Own house at their occupation place
                </label>
            </div>
            <div className="">
                <input
                    type="checkbox"
                    id="notifyAllUsers"
                    name="notifyAllUsers"
                    onChange={handleCheckAbroad}
                />
                <label htmlFor="groupA" className='mx-3'>
                    Ready to settle abroad
                </label>
            </div>

        </div>
    )
}

export default AdvanceCriteria