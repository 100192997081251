import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
// import _ from "lodash";

import { getUserDetails } from "../../slice";
// import { useAddressPredictions, useGeoCoder } from "../../../app/hooks";
import { convertTime12to24, getAge, min18Years } from "../../../utils";
import { editBirthday } from "../../../api/edit";
import SimpleBackdrop from "../../../components/backdrop";

const maxAge = min18Years();

const BirthDetails = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);
  // const [placeId, setPlaceId] = useState("");
  const [location, setLocation] = useState("");
  // const [showPredictions, setShowPredictions] = useState(false);
  // const predictions = useAddressPredictions(location || " ");
  // const geometry = useGeoCoder(placeId);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      birthday: data?.birthdaysDetails?.date_of_birth
        ? new Date(data?.birthdaysDetails?.date_of_birth)
            .toISOString()
            .split("T")[0]
        : "",
      age: data?.birthdaysDetails?.age || "",
      address: data?.birthdaysDetails?.date_of_place || "",
      time: data?.birthdaysDetails?.time_of_birth
        ? convertTime12to24(data.birthdaysDetails.time_of_birth)
        : "",
      latitude: data?.birthdaysDetails?.latitude || "",
      longitude: data?.birthdaysDetails?.longitude || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      birthday: Yup.string().required("*Required"),
      age: Yup.number().min(18).max(99).required("*Required"),
      address: Yup.string().required("*Required"),
      time: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const { birthday, age, address, time /* latitude, longitude */ } = values;
      // if ((latitude && longitude) || !_.isEmpty(geometry)) {
      setSaving(true);
      const payload = {
        userid: userID,
        date_of_birth: new Date(birthday).toISOString(),
        age: age,
        date_of_place: address,
        time_of_birth: time,
      };


      editBirthday(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          goBack();
        }
      });
      // }
    },
  });

  useEffect(() => {
    const date = formik.values.birthday;

    if (date) {
      const age = getAge(date);
      formik.setFieldValue("age", age);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.birthday]);

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Birth Details"
              : "Add User Birth Details"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <div className="">
                    <img
                      src="images/birth-details.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="input-container add_user_info">
                    <input
                      type="date"
                      className="form-control ps-4"
                      placeholder="yyyy-mm-dd"
                      {...formik.getFieldProps("birthday")}
                      title="Date of Birth"
                      max={new Date(maxAge).toISOString().split("T")[0]}
                    />
                    <img
                      src="images/date-of-birth.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.birthday && formik.errors.birthday ? (
                      <span className="error">{formik.errors.birthday}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="input-container add_user_info">
                    <input
                      type="number"
                      className="form-control ps-4"
                      placeholder="Age"
                      {...formik.getFieldProps("age")}
                      readOnly
                    />
                    <img
                      src="images/First-Name.png"
                      className="input-img"
                      alt=""
                    />
                    {formik.touched.age && formik.errors.age ? (
                      <span className="error">{formik.errors.age}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="input-container add_user_info">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Place of Birth"
                      onInput={() => formik.setFieldError("address", "")}
                      value={
                        formik.values.address ? formik.values.address : location
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        formik.setFieldValue("address", value);
                        setLocation(value);
                        // setShowPredictions(true);
                      }}
                    />
                    <img
                      src="images/address.png"
                      className="input-img"
                      alt=""
                    />
                    {/* {showPredictions && (
                      <ul className="predictions">
                        {predictions.map((prediction, index) => (
                          <li
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setPlaceId(prediction.placeId);
                              formik.setFieldValue(
                                "address",
                                prediction.description
                              );
                              setLocation(prediction.description);
                              setShowPredictions(false);
                            }}
                          >
                            {prediction.description}
                          </li>
                        ))}
                      </ul>
                    )} */}
                    {formik.touched.address && formik.errors.address ? (
                      <span className="error">{formik.errors.address}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="input-container add_user_info">
                    <input
                      type="time"
                      className="form-control ps-4"
                      placeholder="Time of Birth"
                      {...formik.getFieldProps("time")}
                      title="Time of Birth"
                    />
                    <img src="images/time.png" className="input-img" alt="" />
                    {formik.touched.time && formik.errors.time ? (
                      <span className="error">{formik.errors.time}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default BirthDetails;
