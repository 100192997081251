import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { editPastExperince } from "../../../api/edit";
import { getUserDetails } from "../../slice";
import SimpleBackdrop from "../../../components/backdrop";

const currentYear = new Date().getFullYear();

const PastExperience = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      experience: data?.past_experience?.length
        ? data.past_experience
        : [
            {
              job_title: "",
              company_name: "",
              department: "",
              from_date: "",
              to_date: "",
            },
          ],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      experience: Yup.array().of(
        Yup.object().shape({
          job_title: Yup.string(),
          company_name: Yup.string(),
          department: Yup.string(),
          from_date: Yup.number()
            .min(currentYear, "* Can not be greater than current year")
            .max(2099),
          to_date: Yup.number()
            .min(currentYear, "* Can not be greater than current year")
            .max(2099),
        })
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);

      editPastExperince({
        past_experience: values.experience,
        userid: userID,
      }).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          goBack();
        }
      });
    },
  });

  const handleAddMore = () => {
    const prevList = formik.values.experience;
    const length = prevList.length - 1;
    if (
      prevList[length].job_title &&
      prevList[length].company_name &&
      prevList[length].department &&
      prevList[length].from_date &&
      prevList[length].to_date
    ) {
      formik.setFieldValue("experience", [
        ...prevList,
        {
          job_title: "",
          company_name: "",
          department: "",
          from_date: "",
          to_date: "",
        },
      ]);
    }
  };

  const handleRemove = (index) => {
    const prevList = formik.values.experience;
    let newList = [];
    newList = prevList.filter((el, i) => i !== index);
    if (newList.length === 0) {
      newList = [
        {
          job_title: "",
          company_name: "",
          department: "",
          from_date: "",
          to_date: "",
        },
      ];
    }
    formik.setFieldValue("experience", newList);
  };

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Past Experience"
              : "Add User Past Experience"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <div className="">
                    <img
                      src="images/Past-Experience.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 graduation">
                  <div className="element" id="div_1">
                    {formik.values.experience?.length
                      ? formik.values.experience.map((el, i) => (
                          <div className="row" key={i}>
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                              <div className="input-container add_user_info">
                                <input
                                  type="text"
                                  className="form-control ps-4"
                                  placeholder="Job Title"
                                  {...formik.getFieldProps(
                                    `experience[${i}].job_title`
                                  )}
                                />
                                <img
                                  src="images/suitcase.png"
                                  className="input-img"
                                  alt=""
                                />
                                {formik.touched.experience &&
                                formik.errors.experience ? (
                                  <span className="error">
                                    {formik.errors.experience[i]?.job_title}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                              <div className="input-container add_user_info">
                                <input
                                  type="text"
                                  className="form-control ps-4"
                                  placeholder="Company Name"
                                  {...formik.getFieldProps(
                                    `experience[${i}].company_name`
                                  )}
                                />
                                <img
                                  src="images/Department.png"
                                  className="input-img"
                                  alt=""
                                />
                                {formik.touched.experience &&
                                formik.errors.experience ? (
                                  <span className="error">
                                    {formik.errors.experience[i]?.company_name}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                              <div className="input-container add_user_info">
                                <input
                                  type="text"
                                  className="form-control ps-4"
                                  placeholder="Department"
                                  {...formik.getFieldProps(
                                    `experience[${i}].department`
                                  )}
                                />
                                <img
                                  src="images/Department.png"
                                  className="input-img pass"
                                  alt=""
                                />
                                {formik.touched.experience &&
                                formik.errors.experience ? (
                                  <span className="error">
                                    {formik.errors.experience[i]?.department}
                                  </span>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                              <div className="input-container add_user_info">
                                <img
                                  src="images/year.png"
                                  className=""
                                  alt=""
                                />
                                <span> Year</span>
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                              <div className="input-container add_user_info">
                                <input
                                  type="number"
                                  min={1900}
                                  max={2099}
                                  className="form-control ps-4 datepicker"
                                  placeholder="From"
                                  {...formik.getFieldProps(
                                    `experience[${i}].from_date`
                                  )}
                                />
                                <img
                                  src="images/choose-date.png"
                                  className="field-icon"
                                  alt=""
                                />
                                {formik.touched.experience &&
                                formik.errors.experience ? (
                                  <span className="error">
                                    {formik.errors.experience[i]?.from_date}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                              <div className="input-container add_user_info">
                                <input
                                  type="number"
                                  min={1900}
                                  max={2099}
                                  className="form-control ps-4 datepicker"
                                  placeholder="To"
                                  {...formik.getFieldProps(
                                    `experience[${i}].to_date`
                                  )}
                                />
                                <img
                                  src="images/choose-date.png"
                                  className="field-icon"
                                  alt=""
                                />
                                {formik.touched.experience &&
                                formik.errors.experience ? (
                                  <span className="error">
                                    {formik.errors.experience[i]?.to_date}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            {formik.values.experience?.length > 1 && (
                              <div className="form-group col-sm-12 col-md-12 col-lg-12">
                                <button
                                  type="button"
                                  id="remove_1"
                                  className="remove"
                                  onClick={() => handleRemove(i)}
                                  style={{ background: "none", border: "none" }}
                                >
                                  <img src="images/delete.png" alt="" />
                                </button>
                              </div>
                            )}
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <button
                    type="button"
                    className="AddtoList"
                    onClick={handleAddMore}
                    style={{ background: "none", border: "none" }}
                  >
                    + Add More
                  </button>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default PastExperience;
