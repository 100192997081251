import React, { useState, useEffect } from 'react'
import { Drawer } from "antd";
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// import { getSubscription } from '../slice';
import CircularProgress from "@material-ui/core/CircularProgress";
import { getStory, getStoryDetails } from '../../slice';
import { getAllUsers } from "../../slice";
import { addUpdateStory } from '../../../api/story';



const AddSuccessStory = ({ show, handleShow, id }) => {
    const dispatch = useDispatch()
    const { goBack } = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [viewportWidth, setViewportWidth] = useState("")
    const data = useSelector((state) => state.storyDetail);
    const [saving, setSaving] = useState(false);
    const users = useSelector((state) => state?.allusers)

    useEffect(() => {

        if (typeof (window.innerWidth) != 'undefined') {
            setViewportWidth(window.innerWidth);
        }
    }, [])
    useEffect(() => {
        if (id) {
            dispatch(getStoryDetails({ id }));
        }
    }, [dispatch, id]);
    useEffect(() => {
        dispatch(getAllUsers({}))
    }, [dispatch,])
    const formik = useFormik({
        initialValues: {

            user: id ? data?.userid?._id            : "",
            description: id ? data?.description : "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            user: Yup.string().required("*Required"),
            description: Yup.string().required("*Required"),

        }),
        onSubmit: (values, { resetForm }) => {
            const { user,description } = values;
            const payload = {
                userid: user,
                description: description,
            };
            if (id) {
                payload.storyId = id;
            }
            addUpdateStory(payload).then((response) => {
                setSaving(false);
                enqueueSnackbar(response?.message);
                if (response?.error_code === 0) {
                    resetForm({});
                    dispatch(getStory({}));
                    handleShow()
                }
            });


        },
    });



    return (
        <>
            <Drawer placement="right" onClose={handleShow} visible={show} width={viewportWidth > 700 ? "40%" : "100%"}>

                <div className="px-5 pb-5">
                    <div className="text-center">
                        <h2 className="offcanvas-title mb-3">{id ? "Edit Success Story" : "Add Success Story"} </h2>
                    </div>
                    <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label">User</label>
                                <select
                                    className="form-control add_user_info"
                                    {...formik.getFieldProps("user")}
                                >
                                    <option value="">
                                        Select User
                                    </option>
                                    {users?.userList?.map((el, i) => {
                                        return <option value={el._id}>{el?.first_name} {el?.last_name}</option>
                                    })}


                                </select>
                                {formik.touched.user && formik.errors.user ? (
                                    <span className="error">{formik.errors.user}</span>
                                ) : null}
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label">Description</label>
                                <div className="form-groupp">
                                    <textarea  className=" textarea-control" rows ="10" placeholder="Description"   {...formik.getFieldProps("description")} ></textarea>
                                    {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                </div>
                                {formik.touched.description && formik.errors.description ? (
                                    <span className="error">{formik.errors.description}</span>
                                ) : null}
                            </div>

                        </div>
                        <div className="button_area text-center">
                            {saving ? <button
                                type="submit"
                                className="save_btn"
                                disabled={saving}
                            >
                                {saving ?
                                    <CircularProgress color="inherit" /> : ""
                                }
                            </button> :
                                <button type="submit" className="save_btn" >Submit</button>}

                        </div>
                    </form>

                </div>
            </Drawer>

        </>
    )
}

export default AddSuccessStory