import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { getUserDetails } from "../../slice";
import UserProfile from "../details/Profile";
import { addAboutMe, addMydocuments } from "../../../api/edit";
import { fileUploader } from "../../../api/accounts";
import { useToggle } from "../../../app/hooks";
import HandleDeleteDocument from "./deleteDocument";

const MyDocuments = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { userID } = useParams();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploading2, setUploading2] = useState(false);
  const [uploading3, setUploading3] = useState(false);
  const [isOpenDelete, toggleDelete] = useToggle(false);
  const [path, setPath] = useState("");

  const data = useSelector((state) => state.userDetails);

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  console.log("id", data?.userDocuments?.audio_url);

  const formik = useFormik({
    initialValues: {
      adharcard: data?.userDocuments?.aadharcard || "",
      biodata: data?.userDocuments?.biodata || "",
      audio: data?.userDocuments?.audio || "",
    },
    enableReinitialize: true,
    // validationSchema: Yup.object({
    //     aboutme: Yup.string().required("*Required"),
    // }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      let payload = {
        userid: userID,
        aadharcard: values.adharcard,
        biodata: values.biodata,
        audio_url: values.audio,
      };
      addMydocuments(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          dispatch(getUserDetails({ user_id: userID }));
        }
      });
    },
  });

  const handleImageUpload = (event) => {
    formik.setFieldError("adharcard", "");
    const file = event.target.files[0];
    setUploading(true);
    let formData = new FormData();
    formData.append("file", file);
    fileUploader(formData).then(async (response) => {
      console.log("adhar", response);
      setUploading(false);
      if (response?.error_code === 0 && response?.data?.fileurl) {
        await formik.setFieldValue("adharcard", response.data.fileurl);
      }
    });
  };
  const handleImageUpload2 = (event) => {
    formik.setFieldError("biodata", "");
    const file = event.target.files[0];
    setUploading2(true);
    let formData = new FormData();
    formData.append("file", file);
    fileUploader(formData).then(async (response) => {
      setUploading2(false);
      if (response?.error_code === 0 && response?.data?.fileurl) {
        await formik.setFieldValue("biodata", response.data.fileurl);
      }
    });
  };
  const handleImageUpload3 = (event) => {
    formik.setFieldError("audio", "");
    const file = event.target.files[0];
    setUploading3(true);
    let formData = new FormData();
    formData.append("file", file);
    fileUploader(formData).then(async (response) => {
      setUploading3(false);
      if (response?.error_code === 0 && response?.data?.fileurl) {
        await formik.setFieldValue("audio", response.data.fileurl);
      }
    });
  };
  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit My Documents"
              : "Add My Documents"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img src="images/edit.png" className="action-view" alt="edit" />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                {/* <Link to={`/users/edit-gender/${userID}`}>Gender</Link> */}
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>Lifestyle</Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                  My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>About Me</Link>
                <Link to={`/users/my-document/${userID}`}>My Document</Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row " onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mt-5">
                  <label className="num">Adhar Card</label>
                  {formik.touched.adharcard && formik.errors.adharcard ? (
                    <span className="error">{formik.errors.adharcard}</span>
                  ) : null}
                  <input
                    type="file"
                    className="form-control shadow-box p-2 "
                    name=""
                    onChange={handleImageUpload}
                  />
                </div>
                {data?.userDocuments?.aadharcard ? (
                  <>
                    <div className="mx-5">
                      <a
                        href={data?.userDocuments?.aadharcard}
                        target="_blank"
                        alt=""
                      >
                        View{" "}
                        <img
                          src="images/view.png"
                          className="action-view"
                          alt="edit"
                        />
                      </a>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {uploading ? <CircularProgress /> : ""}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mt-5">
                  <label className="num">Bio Data</label>
                  {formik.touched.biodata && formik.errors.biodata ? (
                    <span className="error">{formik.errors.biodata}</span>
                  ) : null}
                  <input
                    type="file"
                    className="form-control shadow-box p-2 "
                    name=""
                    onChange={handleImageUpload2}
                  />
                </div>
                {data?.userDocuments?.biodata ? (
                  <>
                    <div className="mx-5">
                      <a
                        href={data?.userDocuments?.biodata}
                        target="_blank"
                        alt=""
                      >
                        View{" "}
                        <img
                          src="images/view.png"
                          className="action-view"
                          alt="edit"
                        />
                      </a>
                    </div>

                    <img
                      src="images/delete.png"
                      className="action-view"
                      alt="delete"
                      onClick={() => {
                        toggleDelete();
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
                {uploading2 ? <CircularProgress /> : ""}

                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mt-5">
                  <label className="num">Audio Clip</label>
                  {formik.touched.audio && formik.errors.audio ? (
                    <span className="error">{formik.errors.audio}</span>
                  ) : null}
                  <input
                    type="file"
                    className="form-control shadow-box p-2 "
                    name=""
                    onChange={handleImageUpload3}
                  />
                </div>

                {data?.userDocuments?.audio_url ? (
                  <>
                    <div className="mx-5">
                      <a
                        href={data?.userDocuments?.audio_url}
                        target="_blank"
                        alt=""
                      >
                        View{" "}
                        <img
                          src="images/view.png"
                          className="action-view"
                          alt="edit"
                        />
                      </a>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {uploading3 ? <CircularProgress /> : ""}

                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* {status === "loading" && <SimpleBackdrop open={status === "loading"} />} */}
      <HandleDeleteDocument
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        userType="approved"
        filepath={data?.userDocuments?.biodata}
        userID={userID}
      />
    </div>
  );
};

export default MyDocuments;
