import React, { useEffect } from "react";

const NoRelatives = ({ isOpen, toggle }) => {
  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

  return (
    <>
      <div
        className={isOpen ? "modal fade show" : "modal fade"}
        id="relativesModal"
        tabIndex="-1"
        aria-labelledby="relativesModalLabel"
        aria-hidden={!isOpen}
        aria-modal={isOpen}
        role="dialog"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content b-r-20">
            <div className="modal-body">
              <div className="text-center">
                <img
                  src="assets/img/no-relatives.png"
                  className="img-fluid"
                  alt=""
                />
                <h2 className="mdl-ttl my-4">No Relatives?</h2>
                <p>
                  You are not allowed to move further without giving relatives
                  address. In order to move next step, please provide your
                  Indian relative's address
                </p>
                <button className="btn btn-submit mt-4" onClick={toggle}>
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default NoRelatives;
