import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { editPets } from "../../../api/edit";
import { getUserDetails } from "../../slice";
import SimpleBackdrop from "../../../components/backdrop";

const Pets = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      pets: data || "Not interested in pets",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      pets: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);

      editPets({ ...values, userid: userID }).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          goBack();
        }
      });
    },
  });

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Pets Details"
              : "Add User Pets Details"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <div className="">
                    <img src="images/Pets.png" className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="text-center">
                    <h6 className="mb-4">Interested in Pets?</h6>
                  </div>
                  <div className="diet">
                    <div className="gander blood-group religion">
                      <label>
                        <input
                          type="radio"
                          name="pets"
                          checked={formik.values.pets === "Cat"}
                          onChange={() => formik.setFieldValue("pets", "Cat")}
                        />
                        <div className="div-bg">
                          <div>
                            <img
                              src="images/Cat.png"
                              className="unselected"
                              alt=""
                            />
                            <img
                              src="images/Cat-white.png"
                              className="selected"
                              alt=""
                            />
                          </div>
                          <p>
                            <span>Cat</span>
                          </p>
                        </div>
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="pets"
                          checked={formik.values.pets === "Dog"}
                          onChange={() => formik.setFieldValue("pets", "Dog")}
                        />
                        <div className="div-bg">
                          <div>
                            <img
                              src="images/Dog.png"
                              className="unselected"
                              alt=""
                            />
                            <img
                              src="images/Dog-white.png"
                              className="selected"
                              alt=""
                            />
                          </div>
                          <p>
                            <span>dog</span>
                          </p>
                        </div>
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="pets"
                          checked={formik.values.pets === "Other"}
                          onChange={() => formik.setFieldValue("pets", "Other")}
                        />
                        <div className="div-bg">
                          <div>
                            <img
                              src="images/Other-pet.png"
                              className="unselected"
                              alt=""
                            />
                            <img
                              src="images/Other-pet-white.png"
                              className="selected"
                              alt=""
                            />
                          </div>
                          <p>
                            <span>other</span>
                          </p>
                        </div>
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="pets"
                          checked={
                            formik.values.pets === "Not interested in pets"
                          }
                          onChange={() =>
                            formik.setFieldValue(
                              "pets",
                              "Not interested in pets"
                            )
                          }
                        />
                        <div className="div-bg">
                          <div>
                            <img
                              src="images/Not-interested-in-pets.png"
                              className="unselected"
                              alt=""
                            />
                            <img
                              src="images/Not-interested-in-pets-white.png"
                              className="selected"
                              alt=""
                            />
                          </div>
                          <p>
                            <span>Not interested in pets</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                  {formik.errors.pets ? (
                    <span className="error">{formik.errors.pets}</span>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default Pets;
