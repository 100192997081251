/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */

import React from "react";
import { Link } from "react-router-dom";

class NotFoundPage extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          margin: 0,
          textAlign: "center",
          height: "100vh",
          paddingTop: "25vh",
        }}
      >
        <h1
          style={{
            display: "inline-block",
            borderRight: "1px solid rgba(0, 0, 0,.3)",
            margin: 0,
            marginRight: "20px",
            padding: "10px 23px 10px 0",
            fontSize: "24px",
            fontWeight: 500,
            verticalAlign: "top",
          }}
        >
          404
        </h1>
        <div
          style={{
            display: "inline-block",
            textAlign: "left",
            lineHeight: "49px",
            height: "49px",
            verticalAlign: "middle",
          }}
        >
          <h2
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              lineHeight: "inherit",
              margin: 0,
              padding: 0,
            }}
          >
            This page could not be found.
          </h2>
        </div>
        <p style={{ textAlign: "center" }}>
          <Link to="/">Go to Home </Link>
        </p>
      </div>
    );
  }
}
export default NotFoundPage;
