import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

import {
  getCities,
  getCommunityList,
  getCountries,
  getHobbies,
  getLanguage,
  getPackageList,
  getRashi,
  getReligionList,
  getStates,
  loginUser,
  logoutUser,
  verifyOTP,
} from "../api/accounts";
import {
  getAboutUs,
  getAppGuide,
  getContactUs,
  getFAQs,
  getPrivacyPolicy,
  getTnC,
} from "../api/cms";
import {
  fetchBioDataAccept,
  fetchBlockedProfiles,
  fetchDeactiveUsersCount,
  fetchInterestDeclinedByMeProfiles,
  fetchInterestReceivedProfiles,
  fetchInterestSentProfiles,
  fetchMatchLimit,
  fetchMutuallyLikedProfiles,
  fetchMyInterestDeclinedProfiles,
  fetchMyMatches,
  fetchPackage,
  fetchSeenProfiles,
  fetchShortlistProfiles,
  fetchUserDetails,
  fetchUserFamilyIncomeDetails,
  fetchUsersCount,
  fetchUsersList,
  fetchVisitorsProfiles,
} from "../api/users";
import {
  fetchAllUsers,
  fetchClassList,
  fetchEarningDetails,
  fetchHigherEducation,
  fetchRevenue,
  fetchSubscribedUsers,
  fetchSubscription,
  fetchSubscriptionDetails,
  fetchTransaction,
} from "../api/subscription";
import { eraseCookie, setCookie } from "../utils";
import {
  fetchCommunityList,
  fetchCourse,
  fetchCourseDetails,
  fetchCourseList,
  fetchDevak,
  fetchDevakList,
  fetchEmploymentList,
  fetchFaqDetails,
  fetchGothra,
  fetchGothraList,
  fetchNotification,
  fetchNotificationDetails,
  fetchNotifications,
  fetchOccupation,
  fetchOffer,
  fetchOfferDetails,
  fetchPhotos,
  fetchSpecialization,
  fetchSpecializationList,
  fetchSubcasteList,
  fetchUnreadNotifications,
} from "../api/offer";
import { fetchBanner, fetchBannerDetails } from "../api/banner";
import {
  fetchDashboard,
  fetchQuery,
  fetchStory,
  fetchStoryDetails,
} from "../api/story";

export const accountSlice = createSlice({
  initialState: {
    userPageNumber: localStorage.getItem("userPageNumber")
      ? localStorage?.getItem("userPageNumber")
      : 1,
  },
  name: "account",
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    loading: (state) => {
      state.status = "loading";
    },
    changeuserPageNumber: (state, action) => {
      state.userPageNumber = action?.payload;
    },
    idle: (state) => {
      state.status = "idle";
    },
    userLoggedIn: (state, action) => {
      const { varificationToken, userdetails } = action.payload;
      if (varificationToken) {
        const decodedToken = jwtDecode(varificationToken);
        state.isLoggedIn = true;
        state.JWToken = varificationToken;
        state.user = userdetails;
        setCookie("clszdi", varificationToken, decodedToken?.exp);
      }
    },
    userForgotPassword: (state, action) => {
      const { token } = action.payload;
      if (token) {
        state.token = token;
        setCookie("clszdi", token, 300);
      }
    },
    userLogout: (state) => {
      logoutUser();
      eraseCookie("clszdi");
      state.user = null;
      state.status = "idle";
      state.isLoggedIn = false;
    },
    usersCount: (state, action) => {
      state.usersCount = action.payload;
    },
    usersList: (state, action) => {
      state.usersList = action.payload;
    },
    usersListWithoutPagination: (state, action) => {
      state.usersListWithoutPagination = action.payload;
    },
    userDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    userFamilyIncomeDetails: (state, action) => {
      state.userFamilyIncomeDetails = action.payload;
    },
    transactionList: (state, action) => {
      state.transactionList = action.payload;
    },
    revenueList: (state, action) => {
      state.revenueList = action.payload;
    },
    subscribedUserList: (state, action) => {
      state.subscribedUserList = action.payload;
    },
    matchesList: (state, action) => {
      if (state?.matchesList?.data?.length && action.payload) {
        state.matchesList = {
          ...action.payload,
          data: [...state.matchesList.data, ...action.payload.data].filter(
            (v, i, a) => a.findIndex((t) => t._id === v._id) === i
          ),
        };
      } else {
        state.matchesList = action.payload;
      }
    },

    //

    matchLimit: (state, action) => {
      state.matchLimit = action.payload;
    },
    allusers: (state, action) => {
      state.allusers = action.payload;
    },
    higherEducation: (state, action) => {
      state.higherEducation = action.payload;
    },
    classList: (state, action) => {
      state.classList = action.payload;
    },
    courseList: (state, action) => {
      state.courseList = action.payload;
    },
    specializationList: (state, action) => {
      state.specializationList = action.payload;
    },
    employmentList: (state, action) => {
      state.employmentList = action.payload;
    },
    comminityList: (state, action) => {
      state.comminityList = action.payload;
    },
    subcasteList: (state, action) => {
      state.subcasteList = action.payload;
    },
    gothraList: (state, action) => {
      state.gothraList = action.payload;
    },
    devakList: (state, action) => {
      state.devakList = action.payload;
    },

    subscription: (state, action) => {
      state.subscription = action.payload;
    },
    subscriptionDetail: (state, action) => {
      state.subscriptionDetail = action.payload;
    },
    earningDetail: (state, action) => {
      state.earningDetail = action.payload;
    },
    offer: (state, action) => {
      state.offer = action.payload;
    },
    occupation: (state, action) => {
      state.occupation = action.payload;
    },
    offerDetail: (state, action) => {
      state.offerDetail = action.payload;
    },
    faqDetail: (state, action) => {
      state.faqDetail = action.payload;
    },
    notification: (state, action) => {
      state.notification = action.payload;
    },
    course: (state, action) => {
      state.course = action.payload;
    },

    photos: (state, action) => {
      state.photos = action.payload;
    },
    notificationList: (state, action) => {
      state.notificationList = action.payload;
    },
    unreadNotification: (state, action) => {
      state.unreadNotification = action.payload;
    },
    courcourseListse: (state, action) => {
      state.courseList = action.payload;
    },
    courseDetail: (state, action) => {
      state.courseDetail = action.payload;
    },
    gothra: (state, action) => {
      state.gothra = action.payload;
    },
    devak: (state, action) => {
      state.devak = action.payload;
    },
    specialization: (state, action) => {
      state.specialization = action.payload;
    },
    specializationDetail: (state, action) => {
      state.specializationDetail = action.payload;
    },
    notificationDetail: (state, action) => {
      state.notificationDetail = action.payload;
    },
    banner: (state, action) => {
      state.banner = action.payload;
    },
    bannerDetail: (state, action) => {
      state.bannerDetail = action.payload;
    },
    story: (state, action) => {
      state.story = action.payload;
    },
    storyDetail: (state, action) => {
      state.storyDetail = action.payload;
    },
    allquery: (state, action) => {
      state.allquery = action.payload;
    },
    dashboard: (state, action) => {
      state.dashboard = action.payload;
    },
    userPackage: (state, action) => {
      state.userPackage = action.payload;
    },
    cms: (state, action) => {
      state.cms = action.payload;
    },
    contact: (state, action) => {
      state.contact = action.payload;
    },

    //

    religionList: (state, action) => {
      state.religionList = action.payload;
    },
    communityList: (state, action) => {
      state.communityList = action.payload;
    },
    subCasteList: (state, action) => {
      state.subCasteList = action.payload;
    },
    languageList: (state, action) => {
      state.languageList = action.payload;
    },
    hobbiesList: (state, action) => {
      state.hobbiesList = action.payload;
    },
    rashiList: (state, action) => {
      state.rashiList = action.payload;
    },
    countriesList: (state, action) => {
      state.countriesList = action.payload;
    },
    statesList: (state, action) => {
      state.statesList = action.payload;
    },
    citiesList: (state, action) => {
      state.citiesList = action.payload;
    },
    packageDetails: (state, action) => {
      state.packageDetails = action.payload;
    },
  },
});

export const {
  userLoggedIn,
  userForgotPassword,
  userLogout,
  loading,
  idle,
  usersCount,
  usersList,
  usersListWithoutPagination,
  userDetails,
  userFamilyIncomeDetails,
  matchesList,
  transactionList,
  subscribedUserList,
  revenueList,

  //

  matchLimit,
  subscription,
  userPackage,
  subscriptionDetail,
  earningDetail,
  offer,
  offerDetail,
  faqDetail,
  notification,
  notificationDetail,
  cms,
  course,
  notificationList,
  unreadNotification,
  photos,
  contact,
  courseDetail,
  gothra,
  devak,
  occupation,
  specialization,
  specializationDetail,
  banner,
  bannerDetail,
  story,
  allusers,
  higherEducation,
  classList,
  courseList,
  specializationList,
  employmentList,
  comminityList,
  subcasteList,
  gothraList,
  devakList,
  storyDetail,
  allquery,
  dashboard,

  //

  religionList,
  communityList,
  subCasteList,
  languageList,
  hobbiesList,
  rashiList,
  countriesList,
  statesList,
  citiesList,
  packageDetails,
  changeuserPageNumber,
} = accountSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state) => state.accounts.user;
export const isUserLoggedIn = (state) => state.accounts.isLoggedIn;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

// Account Management

export const login = (payload) => async (dispatch, getState) => {
  dispatch(loading());
  return loginUser(payload).then((response) => {
    if (response?.error_code === 0) {
      dispatch(userLoggedIn(response));
    }
    dispatch(idle());
    return response;
  });
};

export const verify = (payload) => async (dispatch, getState) => {
  dispatch(loading());
  return verifyOTP(payload).then((response) => {
    if (response?.code) {
      dispatch(userForgotPassword(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

// User Management

export const getUsersCount = (params) => async (dispatch, getState) => {
  return fetchUsersCount(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(usersCount(response?.data));
    }
    return response;
  });
};

export const getUsersList = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchUsersList(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(usersList(response));
    }
    dispatch(idle());
    return response;
  });
};

export const getUsersListWithoutPagination =
  (params) => async (dispatch, getState) => {
    dispatch(loading());
    return fetchUsersList(params).then((response) => {
      if (response?.error_code === 0) {
        dispatch(usersListWithoutPagination(response));
      }
      dispatch(idle());
      return response;
    });
  };
export const getTransactionList = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchTransaction(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(transactionList(response));
    }
    dispatch(idle());
    return response;
  });
};
export const getRevenueList = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchRevenue(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(revenueList(response));
    }
    dispatch(idle());
    return response;
  });
};
export const getSubscribedUsers = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchSubscribedUsers(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(subscribedUserList(response));
    }
    dispatch(idle());
    return response;
  });
};

export const getUserDetails = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchUserDetails(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(userDetails(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getUserFamilyIncome = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchUserFamilyIncomeDetails(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(userFamilyIncomeDetails(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getSubscriptionDetails =
  (params) => async (dispatch, getState) => {
    dispatch(loading());
    return fetchSubscriptionDetails(params).then((response) => {
      if (response?.error_code === 0) {
        dispatch(subscriptionDetail(response?.data));
      }
      dispatch(idle());
      return response;
    });
  };
export const getEarningDetails = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchEarningDetails(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(earningDetail(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getOfferDetails = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchOfferDetails(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(offerDetail(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getFaqDetails = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchFaqDetails(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(faqDetail(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getCourseDetails = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchCourseDetails(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(courseDetail(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getSpecializationDetails =
  (params) => async (dispatch, getState) => {
    dispatch(loading());
    return fetchSpecialization(params).then((response) => {
      if (response?.error_code === 0) {
        dispatch(specializationDetail(response?.data));
      }
      dispatch(idle());
      return response;
    });
  };
export const getCourse = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchCourse(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(courseList(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getNotificationDetails =
  (params) => async (dispatch, getState) => {
    dispatch(loading());
    return fetchNotificationDetails(params).then((response) => {
      if (response?.error_code === 0) {
        dispatch(notificationDetail(response?.data));
      }
      dispatch(idle());
      return response;
    });
  };
export const getBannerDetails = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchBannerDetails(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(bannerDetail(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getStoryDetails = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchStoryDetails(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(storyDetail(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getMutuallyLikedProfiles =
  (params) => async (dispatch, getState) => {
    dispatch(loading());
    return fetchMutuallyLikedProfiles(params).then((response) => {
      if (response?.error_code === 0) {
        dispatch(matchesList({ ...response, more: params.page > 1 }));
      }
      dispatch(idle());
      return response;
    });
  };

export const getMyMatches = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchMyMatches(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(matchesList({ ...response, more: params.page > 1 }));
    }
    dispatch(idle());
    return response;
  });
};

export const getSeenProfiles = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchSeenProfiles(params).then((response) => {
    dispatch(matchesList({ ...response, more: params.page > 1 }));
    if (response?.error_code === 0) {
    }
    dispatch(idle());
    return response;
  });
};
export const getVisitorsProfiles = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchVisitorsProfiles(params).then((response) => {
    dispatch(matchesList({ ...response, more: params.page > 1 }));
    if (response?.error_code === 0) {
    }
    dispatch(idle());
    return response;
  });
};
export const getShortlistProfiles = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchShortlistProfiles(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(matchesList({ ...response, more: params.page > 1 }));
    }
    dispatch(idle());
    return response;
  });
};

export const getInterestSentProfiles =
  (params) => async (dispatch, getState) => {
    dispatch(loading());
    return fetchInterestSentProfiles(params).then((response) => {
      dispatch(matchesList({ ...response, more: params.page > 1 }));
      if (response?.error_code === 0) {
      }
      dispatch(idle());
      return response;
    });
  };

export const getInterestReceivedProfiles =
  (params) => async (dispatch, getState) => {
    dispatch(loading());
    return fetchInterestReceivedProfiles(params).then((response) => {
      if (response?.error_code === 0) {
        dispatch(matchesList({ ...response, more: params.page > 1 }));
      }
      dispatch(idle());
      return response;
    });
  };

export const getMyInterestDeclinedProfiles =
  (params) => async (dispatch, getState) => {
    dispatch(loading());
    return fetchMyInterestDeclinedProfiles(params).then((response) => {
      if (response?.error_code === 0) {
        dispatch(matchesList({ ...response, more: params.page > 1 }));
      }
      dispatch(idle());
      return response;
    });
  };

export const getInterestDeclinedByMeProfiles =
  (params) => async (dispatch, getState) => {
    dispatch(loading());
    return fetchInterestDeclinedByMeProfiles(params).then((response) => {
      if (response?.error_code === 0) {
        dispatch(matchesList({ ...response, more: params.page > 1 }));
      }
      dispatch(idle());
      return response;
    });
  };

export const getBioDataAccept = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchBioDataAccept(params).then((response) => {
    if (response?.status_code === 1) {
      dispatch(matchesList({ ...response, more: params.page > 1 }));
    }
    dispatch(idle());
    return response;
  });
};
export const getBlockedProfiles = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchBlockedProfiles(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(matchesList({ ...response, more: params.page > 1 }));
    }
    dispatch(idle());
    return response;
  });
};

//

export const getDeactiveUsersCount = (params) => async (dispatch, getState) => {
  return fetchDeactiveUsersCount(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(usersCount(response?.data));
    }
    return response;
  });
};

export const getMatchLimit = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchMatchLimit(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(matchLimit(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getAllUsers = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchAllUsers(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(allusers(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getHigherEducation = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchHigherEducation(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(higherEducation(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getClassList = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchClassList(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(classList(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

export const getCourseList = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchCourseList(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(courseList(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getSpecializationList = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchSpecializationList(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(specializationList(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getEmployment = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchEmploymentList(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(employmentList(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

export const getCommunityAll = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchCommunityList(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(comminityList(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getSubcasteAll = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchSubcasteList(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(subcasteList(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

export const getGothraAll = (params) => async (dispatch, getState) => {
  // dispatch(loading());
  return fetchGothraList(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(gothraList(response?.data));
    }
    // dispatch(idle());
    return response;
  });
};
export const getDevakAll = (params) => async (dispatch, getState) => {
  // dispatch(loading());
  return fetchDevakList(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(devakList(response?.data));
    }
    // dispatch(idle());
    return response;
  });
};

export const getSubscription = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchSubscription(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(subscription(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getOffer = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchOffer(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(offer(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getOccupation = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchOccupation(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(occupation(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getCourses = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchCourse(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(course(response?.data));
    }

    dispatch(idle());
    return response;
  });
};
export const getNotifications = (params) => async (dispatch, getState) => {
  // dispatch(loading());
  return fetchNotifications(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(notificationList(response));
    }
    // dispatch(idle());
    return response;
  });
};
export const getUnreadNotifications =
  (params) => async (dispatch, getState) => {
    // dispatch(loading());
    return fetchUnreadNotifications(params).then((response) => {
      if (response?.error_code === 0) {
        dispatch(unreadNotification(response));
      }
      // dispatch(idle());
      return response;
    });
  };
export const getPhotos = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchPhotos(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(photos(response));
    }

    dispatch(idle());
    return response;
  });
};
export const getSpecialization = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchSpecialization(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(specialization(response?.data));
    }

    dispatch(idle());
    return response;
  });
};
export const getGothra = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchGothra(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(gothra(response?.data));
    }

    dispatch(idle());
    return response;
  });
};
export const getDevak = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchDevak(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(devak(response?.data));
    }

    dispatch(idle());
    return response;
  });
};
export const getNotification = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchNotification(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(notification(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getBanner = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchBanner(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(banner(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getStory = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchStory(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(story(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getQueries = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchQuery(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(allquery(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const getDashboardData = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchDashboard(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(dashboard(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

export const getPackage = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return fetchPackage(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(userPackage(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

// CMS

export const fetchAboutUs = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return getAboutUs(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(cms(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

export const fetchAppGuide = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return getAppGuide(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(cms(response?.data));
    }
    dispatch(idle());
    return response;
  });
};
export const fetchContactUs = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return getContactUs(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(contact(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

export const fetchFAQs = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return getFAQs(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(cms(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

export const fetchPrivacyPolicy = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return getPrivacyPolicy(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(cms(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

export const fetchTnC = (params) => async (dispatch, getState) => {
  dispatch(loading());
  return getTnC(params).then((response) => {
    if (response?.error_code === 0) {
      dispatch(cms(response?.data));
    }
    dispatch(idle());
    return response;
  });
};

//

export const fetchReligions = () => async (dispatch, getState) => {
  // dispatch(loading());
  return getReligionList().then((response) => {
    if (response?.error_code === 0) {
      dispatch(religionList(response.data));
    }
    // dispatch(idle());
    return response;
  });
};

export const fetchCommunities = (query) => async (dispatch, getState) => {
  // dispatch(loading());
  return getCommunityList(query).then((response) => {
    if (response?.error_code === 0) {
      dispatch(communityList(response));
    }
    // dispatch(idle());
    return response;
  });
};

export const fetchLanguage = (query) => async (dispatch, getState) => {
  dispatch(loading());
  return getLanguage(query).then((response) => {
    if (response?.error_code === 0) {
      dispatch(languageList(response));
    }
    dispatch(idle());
    return response;
  });
};

export const fetchHobbies = (query) => async (dispatch, getState) => {
  dispatch(loading());
  return getHobbies(query).then((response) => {
    if (response?.error_code === 0) {
      dispatch(hobbiesList(response));
    }
    dispatch(idle());
    return response;
  });
};
export const fetchRashi = (query) => async (dispatch, getState) => {
  dispatch(loading());
  return getRashi(query).then((response) => {
    if (response?.error_code === 0) {
      dispatch(rashiList(response));
    }
    dispatch(idle());
    return response;
  });
};

export const fetchCountries = (query) => async (dispatch, getState) => {
  // dispatch(loading());
  return getCountries(query).then((response) => {
    if (response?.error_code === 0) {
      dispatch(countriesList(response.data));
    }
    // dispatch(idle());
    return response;
  });
};

export const fetchStates = (query) => async (dispatch, getState) => {
  // dispatch(loading());
  return getStates(query).then((response) => {
    if (response?.error_code === 0) {
      dispatch(statesList(response.data));
    }
    // dispatch(idle());
    return response;
  });
};

export const fetchCities = (query) => async (dispatch, getState) => {
  // dispatch(loading());
  return getCities(query).then((response) => {
    if (response?.error_code === 0) {
      dispatch(citiesList(response.data));
    }
    // dispatch(idle());
    return response;
  });
};

export const fetchPackageDetails = (query) => async (dispatch, getState) => {
  // dispatch(loading());
  return getPackageList(query).then((response) => {
    if (response?.error_code === 0) {
      dispatch(packageDetails(response?.data));
    }
    // dispatch(idle());
    return response;
  });
};

export default accountSlice.reducer;
