import React from 'react'

const FamilyMember = ({ setSister, setBrother, setMother, setFather }) => {

    return (
        <div className='family_member mt-4'>
            <h4 className='mx-5'>Father</h4>
            <div className="form-grouppp mx-5 mb-3">
                <select
                    className="form-control add_user_info"
                    onChange={(e) => setFather(e.target.value)}
                >
                    <option value="">
                        Select
                    </option>
                    <option value="Any">Any</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>

            </div>
            <h4 className='mx-5'>Mother</h4>

            <div className="form-grouppp mx-5 mb-3">
                <select
                    className="form-control add_user_info"
                    onChange={(e) => setMother(e.target.value)}

                >
                    <option value="">
                        Select
                    </option>
                    <option value="Any">Any</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>

            </div>

            <h4 className='mx-5'>Brother</h4>
            <div className="form-grouppp mx-5 mb-3">
                <select
                    className="form-control add_user_info"
                    onChange={(e) => setBrother(e.target.value)}

                >
                    <option value="">
                        Select
                    </option>
                    <option value="Any">Any</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>

            </div>
            <h4 className='mx-5'>Sister</h4>

            <div className="form-grouppp mx-5 mb-3">
                <select
                    className="form-control add_user_info"
                    onChange={(e) => setSister(e.target.value)}

                >
                    <option value="">
                        Select
                    </option>
                    <option value="Any">Any</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>

            </div>

        </div>
    )
}

export default FamilyMember