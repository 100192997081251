import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { editGraduationDetails } from "../../../api/edit";
import { getUserDetails } from "../../slice";
import SimpleBackdrop from "../../../components/backdrop";

const GraduationDetails = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      graduation: data?.educationsDetails?.graduation_details?.length
        ? data.educationsDetails.graduation_details
        : [
            {
              university_name: "",
              college_name: "",
              graduation_degree: "",
              year_of_passout: "",
              is_highest_qualification: 1,
            },
          ],
      certification: data?.educationsDetails?.certification_course || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      graduation: Yup.array().of(
        Yup.object().shape({
          university_name: Yup.string().required("Required"),
          college_name: Yup.string().required("Required"),
          graduation_degree: Yup.string().required("Required"),
          year_of_passout: Yup.number()
            .min(1945, "* Can not be less than 1945")
            .max(2099)
            .required("Required"),
        })
      ),
      certification: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      let payload = {
        userid: userID,
        graduation: values.graduation,
        certification_course: values.certification,
      };

      editGraduationDetails(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          goBack();
        }
      });
    },
  });

  const handleAddMore = () => {
    const prevList = formik.values.graduation;
    const length = prevList.length - 1;
    if (
      prevList[length].university_name &&
      prevList[length].college_name &&
      prevList[length].graduation_degree &&
      prevList[length].year_of_passout
    ) {
      formik.setFieldValue("graduation", [
        ...prevList,
        {
          university_name: "",
          college_name: "",
          graduation_degree: "",
          year_of_passout: "",
          is_highest_qualification: 0,
        },
      ]);
    }
  };

  const handleRemove = (index) => {
    const prevList = formik.values.graduation;
    let newList = [];
    newList = prevList.filter((el, i) => i !== index);
    if (newList.length === 0) {
      newList = [
        {
          university_name: "",
          college_name: "",
          graduation_degree: "",
          year_of_passout: "",
          is_highest_qualification: 1,
        },
      ];
    }
    formik.setFieldValue("graduation", newList);
  };

  const handleHighestQualification = (i) => {
    const newList = formik.values.graduation.map((element, index) => {
      if (i === index) {
        return {
          ...element,
          is_highest_qualification: 1,
        };
      }
      return {
        ...element,
        is_highest_qualification: 0,
      };
    });
    formik.setFieldValue(`graduation`, [...newList]);
  };

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Graduation Details"
              : "Add User Graduation Details"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <div className="">
                    <img
                      src="images/favicon.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 graduation">
                  {formik.values.graduation?.length
                    ? formik.values.graduation.map((el, i) => (
                        <Fragment key={i}>
                          <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                            <div className="input-container Present-checkbox highest-qualification">
                              <input
                                type="radio"
                                id={"HighestQualification" + i}
                                name="HighestQualification"
                                checked={
                                  formik.values.graduation[i]
                                    ?.is_highest_qualification || 0
                                }
                                onChange={() => handleHighestQualification(i)}
                              />
                              <label htmlFor={"HighestQualification" + i}>
                                Mark as Highest Qualification
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                              <div className="input-container add_user_info">
                                <input
                                  type="text"
                                  className="form-control ps-4"
                                  placeholder="University Name"
                                  {...formik.getFieldProps(
                                    `graduation[${i}].university_name`
                                  )}
                                />
                                <img
                                  src="images/University-Name.png"
                                  className="input-img pass"
                                  alt=""
                                />
                                {formik.touched.graduation &&
                                formik.errors.graduation ? (
                                  <span className="error">
                                    {
                                      formik.errors.graduation[i]
                                        ?.university_name
                                    }
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                              <div className="input-container add_user_info">
                                <input
                                  type="text"
                                  className="form-control ps-4"
                                  placeholder="College Name"
                                  {...formik.getFieldProps(
                                    `graduation[${i}].college_name`
                                  )}
                                />
                                <img
                                  src="images/University-Name.png"
                                  className="input-img pass"
                                  alt=""
                                />
                                {formik.touched.graduation &&
                                formik.errors.graduation ? (
                                  <span className="error">
                                    {formik.errors.graduation[i]?.college_name}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                              <div className="input-container add_user_info">
                                <input
                                  type="text"
                                  className="form-control ps-4"
                                  placeholder="Degree"
                                  {...formik.getFieldProps(
                                    `graduation[${i}].graduation_degree`
                                  )}
                                />
                                <img
                                  src="images/Cartification-Course.png"
                                  className="input-img"
                                  alt=""
                                />
                                {formik.touched.graduation &&
                                formik.errors.graduation ? (
                                  <span className="error">
                                    {
                                      formik.errors.graduation[i]
                                        ?.graduation_degree
                                    }
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                              <div className="input-container add_user_info">
                                <input
                                  type="number"
                                  min={1900}
                                  max={2099}
                                  className="form-control ps-4 datepicker"
                                  placeholder="Year of Passout"
                                  {...formik.getFieldProps(
                                    `graduation[${i}].year_of_passout`
                                  )}
                                />
                                <img
                                  src="images/year.png"
                                  className="input-img"
                                  alt=""
                                />
                                <img
                                  src="images/choose-date.png"
                                  className="field-icon"
                                  alt=""
                                />
                                {formik.touched.graduation &&
                                formik.errors.graduation ? (
                                  <span className="error">
                                    {
                                      formik.errors.graduation[i]
                                        ?.year_of_passout
                                    }
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            {formik.values.graduation?.length > 1 && (
                              <div className="form-group col-sm-12 col-md-12 col-lg-12">
                                <button
                                  type="button"
                                  id="remove_1"
                                  className="remove"
                                  onClick={() => handleRemove(i)}
                                  style={{ background: "none", border: "none" }}
                                >
                                  <img src="images/delete.png" alt="" />
                                </button>
                              </div>
                            )}
                          </div>
                        </Fragment>
                      ))
                    : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <button
                    type="button"
                    className="AddtoList"
                    onClick={handleAddMore}
                    style={{ background: "none", border: "none" }}
                  >
                    Add More + Post Graduation
                  </button>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="input-container add_user_info">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Certification Course (Optional)"
                      {...formik.getFieldProps("certification")}
                    />
                    <img
                      src="images/Cartification-Course.png"
                      className="input-img"
                      alt=""
                    />
                    {formik.touched.certification &&
                    formik.errors.certification ? (
                      <span className="error">
                        {formik.errors.certification}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default GraduationDetails;
