import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { getUsersCount, getUsersList, idle, loading } from "../slice";
import { verifyProfilePic } from "../../api/users";
import { useEffect } from "react";
import { getUserDetails, userDetails } from "../slice";
import axios from "axios";
import { getCookie } from "../../utils";


const HandleVerifyProfilePic = ({ isOpen, toggle, userID, page, userType, status, imageId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { goBack } = useHistory();
    const limit = useSelector((state) => state?.limit);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        document.body.classList.toggle("modal-open", isOpen);
    }, [isOpen]);

    const handleUpdate = () => {
        setLoading(true)
        axios({
            method: 'patch',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "Authorization": getCookie("clszdi")
            },
            url: `http://13.200.97.246:5011/admin/user/update_user_profile_image_status`,
            data: {
                "user_id": userID,
                "profile_image_id": imageId,
                "is_verified": status === 1 ? 1 : 2          // 1 for approved // 2 for reject // 0 for N/A
            }
        })
            .then(res => {
                console.log("data new", res)
                setLoading(false)
                if (res.data.error_code === 0) {
                    enqueueSnackbar(res.data.message);
                    dispatch(getUserDetails({ user_id: userID }));
                    toggle()
                    // toast.success(res.data.message, theme)
                    // dispatch(getAllOffer({ view: view, pageNo: pageNo, search: search }))
                    // setLoading(false)
                    // handleShow()
                }
            })
    }

    return (
        <>
            <div
                className={isOpen ? "modal fade show" : "modal fade"}
                id="delUserDetails"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">
                                {status === 1 && "Approve Picture"}
                                {status === 2 && "Reject Picture"}
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={toggle}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="p-4">
                            <h4 className="del_user text-center">
                                Are you sure, you want to {status === 1 && "Approve"}
                                {status === 2 && "Reject"} picture?
                            </h4>
                            {/* {status === 2 ? <div className="">
                                <textarea
                                    className="form-control"
                                    placeholder="Write reason..."
                                    rows={4}
                                    onChange={(event) => setReason(event.target.value)}
                                />
                            </div> : ""} */}
                        </div>

                        <div className="modal-footer">

                            {loading ?
                                <button className="Del_btn" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="sr-only"></span>
                                </button> : <button
                                    type="button"
                                    className="Del_btn"
                                    data-dismiss="modal"
                                    onClick={handleUpdate}
                                >
                                    Confirm
                                </button>}
                            <button
                                type="button"
                                className="Can_btn"
                                data-dismiss="modal"
                                onClick={toggle}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

HandleVerifyProfilePic.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    userID: PropTypes.string.isRequired,
    page: PropTypes.number,
    userType: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
};

export default HandleVerifyProfilePic;
