import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { requestForToken } from "../../../firebase";
import { login } from "../../slice";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.status);
  const [showPwd, setShowPwd] = useState(false);

  useEffect(() => {
    SubscribeUser();
  }, []);

  const SubscribeUser = () => {
    try {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          registerServiceWorker();
          console.log("call");
        } else if (permission !== "denied") {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              registerServiceWorker();
            }
          });
        }
      });
    } catch (error) {
      if (error instanceof TypeError) {
        console.log("Not supported");
      } else {
        throw error;
      }
    }
  };

  const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("firebase-messaging-sw.js", {
          // scope: `https://veer-matrimony-admin-panel.vercel.app`,
          scope: `http://localhost:3000/veer-matrimony-admin-panel/build/login`,
          updateViaCache: "none",
        })
        .then(function (registration) {
          requestForToken(registration);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required(" *Required"),
      password: Yup.string().required(" *Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      let payload = {
        email_address: values.email,
        password: values.password,
      };

      dispatch(login(payload)).then((response) => {
        console.log("response", response);
        if (!response?.error_code) {
          resetForm({});
          history.push("/dashboard");
        }
        enqueueSnackbar(response?.message);
      });
    },
  });

  return (
    <div id="loginArea">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="login_left_area">
              <div className="lft_content">
                <img
                  src="images/transparent _logo.png"
                  alt="logo"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="login_main">
              <div className="row">
                <div className="col-md-6 offset-md-4">
                  <div className="login_right_area">
                    <div className="lon_in_content">
                      <div className="log_in_head">
                        <h1 className="login_heading-txt">Log In</h1>
                      </div>
                      <form
                        className="login_form"
                        onSubmit={formik.handleSubmit}
                      >
                        {formik.touched.email && formik.errors.email ? (
                          <span className="error">{formik.errors.email}</span>
                        ) : null}
                        <div className="form_input_main form-group">
                          <input
                            type="email"
                            className="log_inp_area form-control"
                            placeholder="Email Address"
                            {...formik.getFieldProps("email")}
                          />
                          <img
                            src="images/email.png"
                            alt="Email"
                            className="inp_icon"
                          />
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <span className="error">
                            {formik.errors.password}
                          </span>
                        ) : null}
                        <div className="form_input_main form-group">
                          <input
                            type={showPwd ? "text" : "password"}
                            className="log_inp_area form-control"
                            placeholder="Password"
                            {...formik.getFieldProps("password")}
                          />
                          <img
                            src="images/password.png"
                            alt="pwd"
                            className="inp_icon2"
                          />
                          <img
                            src={
                              showPwd ? "images/view.png" : "images/hide.png"
                            }
                            alt="pwd"
                            className="view_icon"
                            onClick={() => setShowPwd(!showPwd)}
                          />
                        </div>

                        <div className="log_in_btn">
                          <button
                            type="submit"
                            className="login_btn"
                            disabled={status === "loading"}
                          >
                            {status === "loading" ? (
                              <CircularProgress color="inherit" />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
