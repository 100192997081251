import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../slice";
import { editEducationDetails } from "../../../api/edit";
import SimpleBackdrop from "../../../components/backdrop";

const currentYear = new Date().getFullYear();

const EducationDetails = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      name: data?.educationsDetails?.school_name || "",
      year: data?.educationsDetails?.passout_year || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("*Required"),
      year: Yup.number()
        .min(1945, "* Can not be less than 1945")
        .max(currentYear, "* Can not be greater than current year")
        .required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      const payload = {
        userid: userID,
        school_name: values.name,
        passout_year: values.year,
      };

      editEducationDetails(payload).then((response) => {
        setSaving(true);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          goBack();
        }
      });
    },
  });

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Education Details"
              : "Add User Education Details"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <div className="">
                    <img
                      src="images/favicon.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 graduation">
                  <div className="element" id="div_1">
                    <div className="row">
                      <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div className="input-container add_user_info">
                          <input
                            type="text"
                            className="form-control ps-4"
                            placeholder="School Name"
                            {...formik.getFieldProps("name")}
                          />
                          <img
                            src="images/University-Name.png"
                            className="input-img pass"
                            alt=""
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <span className="error">{formik.errors.name}</span>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div className="input-container add_user_info">
                          <input
                            type="number"
                            min={1900}
                            max={2099}
                            className="form-control ps-4"
                            placeholder="Year of Passout"
                            id="datepicker"
                            {...formik.getFieldProps("year")}
                          />
                          <img
                            src="images/year.png"
                            className="input-img"
                            alt=""
                          />
                          {formik.touched.year && formik.errors.year ? (
                            <span className="error">{formik.errors.year}</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default EducationDetails;
