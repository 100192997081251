import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { changeuserPageNumber, getUsersList, usersList } from "../slice";
import Header from "./Header";
import Tab from "./Tab";
import SimpleBackdrop from "../../components/backdrop";
import HandleDelete from "./HandleDelete";
import { useToggle } from "../../app/hooks";
import HandleVerify from "./HandleVerify";
import ImgFluid from "../../components/image/ImgFluid";

import { useLocation } from "react-router-dom";
import { getUsersListWithoutPagination } from "../slice";
import HandleVerifyProfilePic from "./handleVerifyProfilePic";
import HandleVerifyMultiple from "./HandleVerifyMultiple";

const ref = React.createRef();

const NewUser = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const status = useSelector((state) => state?.status);
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state?.usersList);
  const data2 = useSelector((state) => state?.usersListWithoutPagination);
  const [isOpenDelete, toggleDelete] = useToggle(false);
  const [isOpenVerify, toggleVerify] = useToggle(false);
  const [isOpenVerifyMultiple, toggleVerifyMultiple] = useToggle(false);
  const [verifyProfilePic, setVerifyProfilePic] = useState(false);
  const [gender, setGender] = useState("");
  const [percentage, setPercentage] = useState("");
  const [page, setPage] = useState(1);
  const [userID, setUserID] = useState("");
  const [value, setValue] = useState(0);
  const [action, setAction] = useState(false);
  const [statusPic, setStatusPic] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const userPageNumber = useSelector((state) => state?.userPageNumber);

  useEffect(() => {
    if (userPageNumber) {
      setPage(userPageNumber);
    }
  }, [userPageNumber]);
  useEffect(() => {
    if (data) {
      setAllUsers(data?.data);
    }
  }, [data]);

  const handleProfilePic = () => {
    setVerifyProfilePic(!verifyProfilePic);
  };
  useEffect(() => {
    dispatch(
      getUsersList({
        tab_type: "new",
        page: page,
        perpage: 50,
      })
    );
    dispatch(
      getUsersListWithoutPagination({
        tab_type: "new",
      })
    );
  }, [dispatch, page, limit]);

  const handleDeleteSelect = (e) => {
    setAction(true);
    const { name, checked } = e.target;

    if (name === "allUsers") {
      setAction(!action);
      let tempUsers = allUsers?.map((user) => {
        return { ...user, isChecked: checked };
      });
      setAllUsers(tempUsers);
    } else {
      let tempUsers = allUsers?.map((user) =>
        user?._id == name ? { ...user, isChecked: checked } : user
      );
      setAllUsers(tempUsers);
    }
  };

  const handleGenderSearch = (e) => {
    setGender(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({ tab_type: "new", page: 1, gender: e.target.value })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({ tab_type: "reject", page: 1, gender: e.target.value })
        );
        break;

      default:
        break;
    }
  };
  const handlePercentageSearch = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            profile_percentage: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            profile_percentage: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            profile_percentage: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };
  const handleManglikStatus = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            user_manglik_status: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            user_manglik_status: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            user_manglik_status: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };
  const handleMaritialStatus = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            user_merital_status: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            user_merital_status: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            user_merital_status: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };

  const handleFamilyType = (e) => {
    // setGender(e.target.value)
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            family_type: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            family_type: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            family_type: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };

  const handleFamilyStatus = (e) => {
    setPercentage(e.target.value);
    switch (pathname) {
      case "/users/new":
        dispatch(
          getUsersList({
            tab_type: "new",
            page: 1,
            gender: gender,
            family_status: e.target.value,
          })
        );
        break;

      case "/users/approved":
        dispatch(
          getUsersList({
            tab_type: "approved",
            page: 1,
            gender: gender,
            family_status: e.target.value,
          })
        );
        break;

      case "/users/rejected":
        dispatch(
          getUsersList({
            tab_type: "reject",
            page: 1,
            gender: gender,
            family_status: e.target.value,
          })
        );
        break;

      default:
        break;
    }
  };

  const exportData = data?.data?.map((res) => {
    return {
      "Matrimonial Id": res?.veer_id,
      "First Name": res?.first_name,
      "Middle Name": res?.middle_name,
      "Last Name": res?.last_name,
      email: res?.email,
      Mobile: res?.mobile_number,
      Gender: res?.gender,
      "D.O.B": res?.date_of_birth?.substr(0, 10),
      "ID Proof Uploaded": res?.upload_doccuments_steper == 1 ? "Yes" : "No",
    };
  });

  useEffect(() => {
    return () => dispatch(usersList(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <Header />
      <Tab exportData={exportData} />
      <div className="row">
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num mx-3"> Gender</label>
          <select
            className="form-control ps-4 mx-2"
            onChange={(e) => {
              handleGenderSearch(e);
            }}
          >
            <option value="">All</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num">Profile Percentage</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handlePercentageSearch(e);
            }}
          >
            <option value="">All</option>
            <option value="25">Below 25 %</option>
            <option value="50">Below 50 %</option>
            <option value="75">Below 75 %</option>
            <option value="100">100 %</option>
          </select>
        </div>
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num">Manglik Status</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleManglikStatus(e);
            }}
          >
            <option value="">All</option>
            <option value="Yes">Yes</option>
            <option value="Yes but Mild">Yes but Mild</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="col-sm-12 col-lg-2 col-md-2">
          <label className="num">Maritial Status</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleMaritialStatus(e);
            }}
          >
            <option value="">All</option>
            <option value="never married">Never married</option>
            <option value="divorced">Divorced</option>
            <option value="widow">Widow</option>
            <option value="awaiting divorce">awaiting divorce</option>
          </select>
        </div>
        <div className="col-md-2">
          <label className="num"> Family Type</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleFamilyType(e);
            }}
          >
            <option value="">All</option>
            <option value="Joint">Joint</option>
            <option value="Nuclear">Nuclear</option>
          </select>
        </div>
        <div className="col-md-2">
          <label className="num">Family Status</label>
          <select
            className="form-control ps-4"
            onChange={(e) => {
              handleFamilyStatus(e);
            }}
          >
            <option value="">All</option>
            <option value="Middle class">Middle Class</option>
            <option value="Upper middle class">Upper Middle Class</option>
            <option value="Rich">Rich</option>
          </select>
        </div>

        <div className="col-md-4 mt-4">
          <div className="d-flex">
            <button
              title="Approve"
              className="btn save_btnn mx-2"
              onClick={() => {
                setValue(1);
                toggleVerifyMultiple();
              }}
            >
              Approve
            </button>
            <button
              title="Reject"
              onClick={() => {
                setValue(2);
                toggleVerifyMultiple();
              }}
              className="btn save_btnn mx-2"
            >
              Reject
            </button>
          </div>
        </div>
      </div>

      <div className="brand_table">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="brand_table_inner">
                <div className="table-responsive" ref={ref}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col mx-5">
                          <label className="container-select">
                            <input
                              type="checkbox"
                              className=""
                              name="allUsers"
                              onChange={handleDeleteSelect}
                              checked={
                                allUsers.filter(
                                  (user) => user?.isChecked !== true
                                ).length < 1
                              }
                            />
                            <span className="checkmark-check"></span>
                          </label>
                        </th>
                        <th scope="col"> S.No</th>
                        <th scope="col">Matrimony Id</th>
                        <th scope="col">Photo</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Middle Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Email ID</th>
                        <th scope="col">Mobile Number</th>
                        {/* <th scope="col">Registration Steps Completed</th> */}
                        <th scope="col">ID Proof Uploaded</th>
                        <th scope="col">Registration Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data && allUsers?.length ? (
                        allUsers?.map((el, i) => (
                          <tr key={el._id}>
                            <td className="bt-non">
                              <label className="container-select">
                                <input
                                  type="checkbox"
                                  className=""
                                  name={el?._id}
                                  checked={el?.isChecked || false}
                                  onChange={handleDeleteSelect}
                                />
                                <span className="checkmark-check mt-1"></span>
                              </label>
                            </td>
                            <td>
                              {data.current === 1
                                ? i + 1
                                : (data.current - 1) * 50 + (i + 1)}
                            </td>
                            <td className="bt-non">{el.veer_id || "NA"}</td>
                            <td className="bt-non">
                              <div className="p_pic">
                                <ImgFluid
                                  src={el?.profile_images?.map((res) => {
                                    return res?.is_profile === 1
                                      ? res?.image_url
                                      : "images/avatar.png";
                                  })}
                                />
                              </div>
                            </td>
                            <td className="bt-non">{el.first_name || "N/A"}</td>
                            <td className="bt-non">
                              {el.middle_name || "N/A"}
                            </td>
                            <td className="bt-non">{el.last_name || "N/A"}</td>
                            <td className="bt-non">{el.gender || "N/A"}</td>
                            <td className="bt-non">{el.email || "N/A"}</td>
                            <td className="bt-non">
                              {`${el.country_code || ""} ${
                                el.mobile_number || ""
                              }`}
                            </td>
                            <td>
                              {el?.upload_doccuments_steper == 1 ? "Yes" : "No"}
                            </td>
                            {/* <td className="bt-non">{el.profile_steper || 0}</td> */}
                            <td className="bt-non">
                              {el?.createdAt?.substr(0, 10) || "NA"}
                            </td>
                            <td className="bt-non">
                              <span className="table-action">
                                <Link to={`/users/new/${el._id}`} title="View">
                                  <img
                                    src="images/view.png"
                                    className="action-view"
                                    alt="view"
                                  />
                                </Link>
                                <button
                                  style={{ background: "none", border: "none" }}
                                  title="Approve"
                                  onClick={() => {
                                    setUserID(el._id);
                                    setValue(1);
                                    toggleVerify();
                                  }}
                                >
                                  <img
                                    src="images/approve.png"
                                    className="action-view"
                                    alt="approve"
                                  />
                                </button>
                                <button
                                  style={{ background: "none", border: "none" }}
                                  title="Reject"
                                  onClick={() => {
                                    setUserID(el._id);
                                    setValue(2);
                                    toggleVerify();
                                  }}
                                >
                                  <img
                                    src="images/reject.png"
                                    className="action-view"
                                    alt="reject"
                                  />
                                </button>

                                <button
                                  onClick={() => {
                                    setUserID(el._id);
                                    toggleDelete();
                                  }}
                                  style={{ background: "none", border: "none" }}
                                  title="Delete"
                                >
                                  <img
                                    src="images/delete.png"
                                    className="action-view"
                                    alt="delete"
                                  />
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={13} style={{ padding: "10px" }}>
                            No Results Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {data?.pages > 1 ? (
                    <Pagination
                      count={data.pages}
                      page={page && page}
                      onChange={(e, page) => {
                        (async () => {
                          await localStorage.setItem("userPageNumber", page);
                        })();
                        dispatch(changeuserPageNumber(page));
                        setPage(page);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleBackdrop open={status === "loading"} />
      <HandleDelete
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        userID={userID}
        page={page}
        userType="new"
      />
      <HandleVerify
        isOpen={isOpenVerify}
        toggle={toggleVerify}
        userID={userID}
        page={page}
        userType="new"
        status={value}
      />
      <HandleVerifyMultiple
        isOpen={isOpenVerifyMultiple}
        toggle={toggleVerifyMultiple}
        userID={userID}
        page={page}
        userType="new"
        status={value}
        id={allUsers
          ?.filter((user) => user?.isChecked === true)
          .map((user) => {
            return user?._id;
          })}
      />
      <HandleVerifyProfilePic
        isOpen={verifyProfilePic}
        toggle={handleProfilePic}
        userID={userID}
        page={page}
        userType="new"
        status={status}
      />
    </div>
  );
};

export default NewUser;
