import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import {
    usersCount,
    usersList,
} from "../slice";

import SimpleBackdrop from "../../components/backdrop";
import { getRevenueList } from "../slice";
import RevenueDetail from "./RevenueDetail";
import { useToggle } from "../../app/hooks";
import CsvDownloadButton from 'react-json-to-csv'

const RevenueList = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state?.status);
    const limit = useSelector((state) => state?.limit);
    const data = useSelector((state) => state?.revenueList);
    const [page, setPage] = useState(1);
    const [month, setMonth] = useState("")
    const [type, setType] = useState("")
    const [startDate, setStartDate] = useState(new Date())
    const [isOpen, toggle] = useToggle()
    const [id, setId] = useState("")
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        dispatch(getRevenueList({ page: 1 }));
    }, [dispatch]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        // setPageNo(pageNumber)
        dispatch(getRevenueList({ page: pageNumber }))
    }
    const handleType = (e) => {
        setType(e.target.value)
        dispatch(getRevenueList({ plan_type: e.target.value, month: "" }));
    }
    const handleMonth = (e) => {
        setMonth(e.target.value)
        dispatch(getRevenueList({ month: e.target.value }));
    }
    const handleStartDate = (e) => {
        setStartDate(e.target.value)
        dispatch(getRevenueList({ start_date: e.target.value, month }));
    }

    useEffect(() => {
        return () => dispatch(usersList(null));
    }, [dispatch]);

    useEffect(() => {
        return () => dispatch(usersCount(null));
    }, [dispatch]);

    return (
        <div className="m-main-content">
            <div className="brand_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="brand_head_data">
                                <div className="brands_total deactive_user">
                                    {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                                    <p className="t_brand_txt">Total Earnings</p>
                                    <h4 className="brand_num">{data?.data?.total_earning_count}</h4>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="brand_table">
                <div className="container-fluid">
                    <div className="row">
                        <div className="colsm-12 col-lg-12 col-md-12 my-4 ">
                            <div className="search_area">
                                {/* <div className='d-flex'>
                                    <input type="date" className="form-selectt mx-4" id="startDate" name="startDate" onChange={handleStartDate} placeholder="start date" />
                                </div> */}
                                <CsvDownloadButton
                                    data={data?.data?.earnings}
                                    filename="earning.csv"
                                    style={{ //pass other props, like styles
                                        boxShadow: "inset 0px 1px 0px 0px #e184f3",
                                        background: "linear-gradient(to bottom, #EC008C 5%, #0F75BC 100%)",
                                        backgroundColor: "#c123de",
                                        borderRadius: "6px",
                                        border: "1px solid #a511c0",
                                        display: "inline-block",
                                        cursor: "pointer", "color": "#ffffff",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        padding: "6px 24px",
                                        textDecoration: "none",
                                        textShadow: "0px 1px 0px #9b14b3"
                                    }}
                                >
                                    Export CSV ✨
                                </CsvDownloadButton>
                                <div className='d-flex'>
                                    <select className="form-selectt mx-3" aria-label="Default select example" defaultValue="" onChange={(e) => handleType(e)}>
                                        <option value="Basic">Basic</option>
                                        <option value="Premium">Premium</option>
                                        <option value="Professionals">Professionals</option>
                                    </select>
                                    <select className="form-select mx-3" aria-label="Default select example" defaultValue="6" onChange={(e) => handleMonth(e)}>
                                        <option value="1">Jan</option>
                                        <option value="2">Feb</option>
                                        <option value="3">Mar</option>
                                        <option value="4">Apr</option>
                                        <option value="5">May</option>
                                        <option value="6">Jun</option>
                                        <option value="7">Jul</option>
                                        <option value="8">Aug</option>
                                        <option value="9">Sep</option>
                                        <option value="10">Oct</option>
                                        <option value="11">Nov</option>
                                        <option value="12">Dec</option>
                                    </select>

                                    {/* <button type="submit" className="savee_btnn mx-2 mt-2">Search</button> */}

                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="brand_table_inner">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Total Amount</th>
                                                {/* <th scope="col">Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.data?.earnings?.length
                                                ? (
                                                    data.data.earnings
                                                        .map((el, i) => (
                                                            <tr key={el._id}>
                                                                <td className="bt-non">
                                                                    {i + 1}
                                                                </td>
                                                                <td className="bt-non">
                                                                    {el?._id}
                                                                </td>
                                                                <td className="bt-non">
                                                                    {el?.totalAmount}
                                                                </td>
                                                                {/* <td className="bt-non">
                                                            <span className="table-action">
                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggle();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Delete"
                                                                >
                                                                    <img
                                                                        src="images/view.png"
                                                                        className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                            </span>
                                                        </td> */}
                                                            </tr>
                                                        ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={7} style={{ padding: "10px" }}>
                                                            No Results Found
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                    {data?.data?.total_earning_count
                                        >= 9 ?
                                        <div className='mt-4'>
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={data?.data?.total_earning_count
                                                }
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                            />
                                        </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleBackdrop open={status === "loading"} />
            {isOpen ? <RevenueDetail show={isOpen} handleShow={toggle} id={id} /> : ""}
            {/* <DeleteTransaction
                isOpen={isOpenDelete}
                toggle={toggleDelete}
                userID={userID}
                page={page}
                userType="deactivated"
            /> */}

        </div>
    );
};

export default RevenueList;
