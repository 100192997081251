import React, { useState, useEffect } from 'react'
import { Drawer } from "antd";
import { useFormik } from 'formik'
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import { addNotification, addUpdateOffer } from '../../api/offer';
import { getAllUsers } from '../slice';
import { getNotification } from '../slice';
import Multiselect from 'multiselect-react-dropdown';


const AddNotification = ({ show, handleShow, id, status, search }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.offerDetail);
    const { enqueueSnackbar } = useSnackbar();
    const [viewportWidth, setViewportWidth] = useState("")
    const users = useSelector((state) => state?.allusers)
    const [allUsers, setAllUsers] = useState([])


    useEffect(() => {
        if (typeof (window.innerWidth) != 'undefined') {
            setViewportWidth(window.innerWidth);
        }
    }, [])

    useEffect(() => {
        dispatch(getAllUsers({ status: 1 }))
    }, [dispatch,])

    const handleSelect = (l, i) => {
        setAllUsers(l?.map((res) => {
            return res?._id
        }))
    }

    const formik = useFormik({
        initialValues: {
            title: "",
            body: "",
            users: "",
            notifyAllUsers: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            title: Yup.string().required("*Required"),
            body: Yup.string().required("*Required"),
        }),

        onSubmit: (values, { resetForm }) => {
            const { title, body, notifyAllUsers } = values;
            const payload = {
                title: title,
                body: body,
                users: allUsers ? allUsers : notifyAllUsers ? notifyAllUsers : [],
                send_to_all_user: notifyAllUsers

            };
            addNotification(payload).then((response) => {
                // setSaving(false);
                enqueueSnackbar(response?.message);
                if (response?.error_code === 0) {
                    resetForm({});
                    dispatch(getNotification({}));
                    handleShow()
                }
            });

        },
    });


    return (
        <>
            <Drawer placement="right" onClose={handleShow} open={show} width={viewportWidth > 700 ? "40%" : "100%"}>

                <div className="px-5 pb-5">
                    <div className="text-center">
                        <h2 className="offcanvas-title mb-3">{"Add Notification"} </h2>
                    </div>
                    <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="form-groupp">
                                    <label className="text_label">Notification Title</label>
                                    <div className="input-containerr">
                                        <input type="text" className="form-control add_user_info" {...formik.getFieldProps("title")}
                                            placeholder="Title" />
                                        {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                    </div>
                                    {formik.touched.title && formik.errors.title ? (
                                        <span className="error">{formik.errors.title}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="form-groupp">
                                    <label className="text_label">Information</label>
                                    <div className="input-containerr">
                                        <input type="text" className="form-control add_user_info" {...formik.getFieldProps("body")}
                                            placeholder="Information" />
                                        {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                    </div>
                                    {formik.touched.body && formik.errors.body ? (
                                        <span className="error">{formik.errors.body}</span>
                                    ) : null}
                                </div>
                            </div>


                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label">User Notify</label>
                                <Multiselect
                                    className="form-contro add_user_info"
                                    options={users?.userList}
                                    onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem)}
                                    // onRemove={this.onRemove} 
                                    displayValue="first_name"
                                />

                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                                <div className="input-container Present-checkbox highest-qualification">
                                    <input
                                        type="checkbox"
                                        id="notifyAllUsers"
                                        name="notifyAllUsers"
                                        // checked={
                                        //     formik.values.notifyAllUsers === "Yes"

                                        // }
                                        onChange={() => formik.setFieldValue(`notifyAllUsers`, formik?.values?.notifyAllUsers === "Yes" ? "No" : "Yes")}
                                    />
                                    <label htmlFor={"notifyAllUsers"}>
                                        Notify All Users
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="button_area text-center">
                            <button type="submit" className="save_btn">Submit</button>
                        </div>
                    </form>

                </div>
            </Drawer>

        </>
    )
}

export default AddNotification;