import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { getUserDetails } from "../../slice";
import countryProvinces from "../../../utils/countryProvinces.json";
import { useToggle } from "../../../app/hooks";
import { editNationality } from "../../../api/edit";
import NoRelatives from "./NoRelatives";
import SimpleBackdrop from "../../../components/backdrop";

const Nationality = () => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [isOpen, toggle] = useToggle(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      nationality: data?.nationality || "",
      relatives: data?.relatives_in_india || 0,
      addressLineOne: data?.relatives_address || "",
      addressLineTwo: data?.relatives_address_2 || "",
      city: data?.relatives_city || "",
      state: data?.relatives_state || "",
      country: "India",
      zipCode: data?.relatives_zipcode || "",
      mobileNumber: data?.relative_country_code
        ? data?.relative_country_code + data?.relative_contact_number
        : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      nationality: Yup.string().required("*Required"),
      relatives: Yup.number().when("nationality", {
        is: "abroad",
        then: Yup.number().required("Required"),
      }),
      addressLineOne: Yup.string().when("relatives", {
        is: 1,
        then: Yup.string().required("Required"),
      }),
      addressLineTwo: Yup.string().when("relatives", {
        is: 1,
        then: Yup.string(),
      }),
      city: Yup.string().when("relatives", {
        is: 1,
        then: Yup.string().required("Required"),
      }),
      state: Yup.string().when("relatives", {
        is: 1,
        then: Yup.string().required("Required"),
      }),
      country: Yup.string().when("relatives", {
        is: 1,
        then: Yup.string().required("Required"),
      }),
      zipCode: Yup.string().when("relatives", {
        is: 1,
        then: Yup.string().required("Required"),
      }),
      mobileNumber: Yup.string().when("relatives", {
        is: 1,
        then: Yup.string().required("Required"),
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      const {
        nationality,
        relatives,
        addressLineOne,
        addressLineTwo,
        zipCode,
        city,
        state,
        country,
        mobileNumber,
      } = values;
      if (relatives === 1 ? validateMobile() : true) {
        setSaving(true);
        let payload = {
          userid: userID,
          nationality: nationality,
        };

        if (nationality === "abroad") {
          payload.relatives_in_india = relatives;
          if (relatives === 1) {
            const countryCode =
              formatPhoneNumberIntl(mobileNumber).split(" ")[0];
            payload.relatives_address = addressLineOne;
            payload.relatives_address_2 = addressLineTwo;
            payload.relatives_state = state;
            payload.relatives_city = city;
            payload.relatives_country = country;
            payload.relatives_zipcode = zipCode;
            payload.relative_country_code = countryCode;
            payload.relative_contact_number = mobileNumber.replace(
              countryCode,
              ""
            );
          }
        }

        editNationality(payload).then((response) => {
          setSaving(false);
          enqueueSnackbar(response?.message);
          if (response?.error_code === 0) {
            resetForm({});
            goBack();
          }
        });
      }
    },
  });

  const validateMobile = () => {
    if (!isValidPhoneNumber(formik.values.mobileNumber || "")) {
      formik.setFieldError("mobileNumber", "Inavalid");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const country = formik.values.country;
    if (country) {
      const provinces = countryProvinces.find(
        (el) => el.name === country
      )?.stateProvinces;
      setStateProvinces(provinces);
    }
  }, [formik.values.country]);

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Nationality"
              : "Add User Nationality"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form
                className="row form-style profile-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                  <div className="">
                    <img
                      src="images/nationality.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                {formik.errors.nationality ? (
                  <span className="error">{formik.errors.nationality}</span>
                ) : null}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                  <div className="marital-status">
                    <span>
                      <input
                        type="radio"
                        id="Indian"
                        name="nationality"
                        checked={
                          formik.values.nationality?.toLowerCase() === "india"
                        }
                        onChange={() => {
                          formik.setFieldValue("nationality", "India");
                          formik.setFieldValue("relatives", 0);
                        }}
                      />
                      <label htmlFor="Indian">Indian</label>
                    </span>

                    <span>
                      <input
                        type="radio"
                        id="Abroad"
                        name="nationality"
                        checked={
                          formik.values.nationality?.toLowerCase() === "abroad"
                        }
                        onChange={() =>
                          formik.setFieldValue("nationality", "abroad")
                        }
                      />
                      <label htmlFor="Abroad">NRI</label>
                    </span>
                  </div>
                </div>
                {formik.values.nationality === "abroad" ? (
                  <Fragment>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                      <div className="nationality-box">
                        <p>
                          Do you have any relatives in India?{" "}
                          {formik.errors.nationality ? (
                            <span className="error">
                              {formik.errors.nationality}
                            </span>
                          ) : null}
                        </p>
                        <div className="marital-status yesNo">
                          <span>
                            <input
                              type="radio"
                              id="Yes"
                              name="relatives"
                              checked={formik.values.relatives === 1}
                              onChange={() =>
                                formik.setFieldValue("relatives", 1)
                              }
                            />
                            <label htmlFor="Yes">Yes</label>
                          </span>

                          <span>
                            <input
                              type="radio"
                              id="No"
                              name="relatives"
                              checked={formik.values.relatives === 0}
                              onChange={() => {
                                toggle();
                                formik.setFieldValue("relatives", 0);
                              }}
                            />
                            <label
                              htmlFor="No"
                              data-bs-toggle="modal"
                              data-bs-target="#relativesModal"
                            >
                              No
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                    {formik.values.relatives === 1 ? (
                      <Fragment>
                        <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                          <div className="input-container">
                            <PhoneInput
                              name="mobile"
                              className="form-control ps-4 d-flex"
                              placeholder="Relative Mobile Number"
                              international
                              defaultCountry="IN"
                              countryCallingCodeEditable={false}
                              initialValueFormat="national"
                              value={formik.values.mobileNumber || ""}
                              required
                              onBlur={validateMobile}
                              onChange={(value) =>
                                formik.setFieldValue("mobileNumber", value)
                              }
                            />
                            <img
                              src="image/mobile.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {formik.touched.mobileNumber &&
                          formik.errors.mobileNumber ? (
                            <span className="error">
                              {formik.errors.mobileNumber}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group col-sm-12 col-md-6 col-lg-6 text-center mb-4">
                          <div className="input-container add_user_info">
                            <input
                              type="text"
                              className="form-control ps-4"
                              placeholder="Relative Address Line 1"
                              {...formik.getFieldProps("addressLineOne")}
                            />
                            <img
                              src="images/address.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {formik.touched.addressLineOne &&
                          formik.errors.addressLineOne ? (
                            <span className="error">
                              {formik.errors.addressLineOne}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                          <div className="input-container add_user_info">
                            <input
                              type="text"
                              className="form-control ps-4"
                              placeholder="Relative Address Line 2 (Optional)"
                              {...formik.getFieldProps("addressLineTwo")}
                            />
                            <img
                              src="images/address.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {formik.touched.addressLineTwo &&
                          formik.errors.addressLineTwo ? (
                            <span className="error">
                              {formik.errors.addressLineTwo}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                          <div className="input-container add_user_info">
                            <input
                              type="text"
                              className="form-control ps-4"
                              placeholder="City"
                              {...formik.getFieldProps("city")}
                            />
                            <img
                              src="images/address.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {formik.touched.city && formik.errors.city ? (
                            <span className="error">{formik.errors.city}</span>
                          ) : null}
                        </div>

                        <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                          <div className="input-container add_user_info">
                            <input
                              type="text"
                              className="form-control ps-4"
                              placeholder="Zip Code"
                              {...formik.getFieldProps("zipCode")}
                            />
                            <img
                              src="images/address.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {formik.touched.zipCode && formik.errors.zipCode ? (
                            <span className="error">
                              {formik.errors.zipCode}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                          <div className="input-container add_user_info">
                            <select
                              className="form-control ps-4"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "country",
                                  event.target.value
                                );
                                formik.setFieldValue("state", "");
                              }}
                              value={formik.values.country || ""}
                              disabled
                            >
                              <option value="" disabled>
                                Select Country
                              </option>
                              {countryProvinces?.length
                                ? countryProvinces.map((el) => (
                                    <option value={el.name} key={el.name}>
                                      {el.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <img
                              src="images/address.png"
                              className="input-img"
                              alt=""
                            />
                            {formik.touched.country && formik.errors.country ? (
                              <span className="error">
                                {formik.errors.country}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                          <div className="input-container add_user_info">
                            <select
                              className="form-control ps-4"
                              {...formik.getFieldProps("state")}
                              disabled={!stateProvinces?.length}
                              value={formik.values.state || ""}
                            >
                              <option value="" disabled>
                                Select State
                              </option>
                              {stateProvinces?.length
                                ? stateProvinces.map((el) => (
                                    <option value={el.name} key={el.name}>
                                      {el.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <img
                              src="images/address.png"
                              className="input-img"
                              alt=""
                            />
                            {formik.touched.state && formik.errors.state ? (
                              <span className="error">
                                {formik.errors.state}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : null}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <NoRelatives isOpen={isOpen} toggle={toggle} />}
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default Nationality;
