import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import {
    getOffer,
    getUsersList,
    usersCount,
    usersList,
} from "../slice";

import SimpleBackdrop from "../../components/backdrop";
import { useToggle } from "../../app/hooks";
import { getCourses } from "../slice";
import { getSpecialization } from "../slice";
import AddSpecialization from "./addSpecialization";
import DeleteSpecialization from "./deleteSpecialization";


const SpecializationList = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state?.status);
    const limit = useSelector((state) => state?.limit);
    const data = useSelector((state) => state?.specialization);
    const count = useSelector((state) => state.usersCount);
    const [activePage, setActivePage] = useState(1);
    const [isOpen, toggle] = useToggle(false);
    const [isOpenDelete, toggleDelete] = useToggle(false);
    const [isOpenEdit, toggleEdit] = useToggle(false)
    const [isOpenStatus, toggleStatus] = useToggle(false)
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [id, setId] = useState("");
    const [status2, setStatus2] = useState("")


    useEffect(() => {
        dispatch(getSpecialization({ page: page }))
    }, [dispatch, page, limit]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        dispatch(getSpecialization({ page: pageNumber }))
    }
    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(
            getUsersList({ tab_type: "deactivated", page: 1, perpage: limit, search })
        );
    };

    useEffect(() => {
        return () => dispatch(usersList(null));
    }, [dispatch]);

    useEffect(() => {
        return () => dispatch(usersCount(null));
    }, [dispatch]);

    return (
        <div className="m-main-content">
            <div className="brand_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="brand_head_data">
                                <div className="brands_total deactive_user">
                                    {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                                    <p className="t_brand_txt">Total Specialization</p>
                                    <h4 className="brand_num">{data?.total_specializations}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end col-md-8">
                            {/* <form className="search_area" onSubmit={handleSearch}>
                                <div className="top-search-bar p-top">
                                    <input
                                        type="text"
                                        name="search"
                                        value={search}
                                        placeholder="Search  by name"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    {search ? (
                                        <img
                                            src="images/cancel.png"
                                            className="search_icon pointer"
                                            alt="clear"
                                            onClick={() => setSearch("")}
                                        />
                                    ) : (
                                        <img
                                            src="images/search.png"
                                            className="search_icon"
                                            alt="search"
                                        />
                                    )}
                                </div>
                            </form> */}
                            <span className="brand_add mt-5" onClick={toggle}>
                                <img src="images/add.png" alt="add" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brand_table">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="brand_table_inner">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Specialization Name</th>
                                                <th scope="col">Course</th>
                                                <th scope="col">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data?.specialization_list?.length ? (
                                                data?.specialization_list?.map((el, i) => (
                                                    <tr key={i}>
                                                        <td className="bt-non">
                                                            {el?.name}
                                                        </td>
                                                        <td className="bt-non">
                                                            {el?.course?.name}
                                                        </td>

                                                        <td className="bt-non">
                                                            <span className="table-action">

                                                                {/* <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggleEdit();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Edit"
                                                                >
                                                                    <img
                                                                        src="images/edit.png" className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button> */}
                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggleDelete();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Delete"
                                                                >
                                                                    <img
                                                                        src="images/delete.png"
                                                                        className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} style={{ padding: "10px" }}>
                                                        No Results Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {data?.total_specializations
                                        >= 9 ?
                                        <div className='mt-4'>
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={data?.total_specializations}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                            />
                                        </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleBackdrop open={status === "loading"} />
            {isOpen ? <AddSpecialization show={isOpen} handleShow={toggle} page={page} /> : ""}
            {isOpenEdit ? <AddSpecialization show={isOpenEdit} handleShow={toggleEdit} id={id} /> : ""}


            <DeleteSpecialization
                isOpen={isOpenDelete}
                toggle={toggleDelete}
                id={id}
                page={page}
                userType="deactivated"
            />

        </div>
    );
};

export default SpecializationList;
