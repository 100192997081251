import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";

import { getUserDetails } from "../../slice";
import { editHeight } from "../../../api/edit";
import SimpleBackdrop from "../../../components/backdrop";
import { Link } from "react-router-dom"
import UserProfile from "../details/Profile";

const Height = ({ setHeightFrom, setHeightTo }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { userID } = useParams();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);
  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      height_type: data?.heightsDetails?.height_type || "",
      feet: data?.heightsDetails?.height || "",
      cm: data?.heightsDetails?.height || "",
      disability: data?.disability || "",
      complexion: data?.complexion || "",
      spectacles: data?.spectacles || "",
      body_weight: data?.body_weight,
      blood_group: data?.blood_group || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      height_type: Yup.string().required("*Required"),
      // feet: Yup.number().min(1).max(10).when("height_type", {
      //   is: "feet",
      //   then: Yup.number().required("Required"),
      // }),
      // cm: Yup.number()
      //   .min(50)
      //   .max(300)
      //   .when("height_type", {
      //     is: "cm",
      //     then: Yup.number().required("Required"),
      //   }),
      disability: Yup.string().required("*Required"),
      complexion: Yup.string().required("*Required"),
      spectacles: Yup.string().required("*Required"),
      body_weight: Yup.string().required("*Required"),
      blood_group: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      const { height_type, feet, cm, disability, complexion, spectacles, body_weight, blood_group } = values;
      let payload = {
        userid: userID,
        height_type: height_type,
        disability: disability,
        complexion: complexion,
        spectacles: spectacles,
        body_weight: body_weight,
        blood_group: blood_group,
      };

      if (height_type === "feet") {
        payload.feet = feet?.toString()?.split(".")[0]
        payload.inc = feet?.toString()?.split(".")[1] ? feet?.toString()?.split(".")[1] : 0
      }

      if (height_type === "cm") {
        payload.cm = cm;
      }

      editHeight(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          dispatch(getUserDetails({ user_id: userID }));
          // resetForm({});
          // goBack();
        }
      });
    },
  });


  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit Body Details"
              : "Add Body Details"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img
                src="images/edit.png"
                className="action-view"
                alt="edit"
              />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                {/* <Link to={`/users/edit-gender/${userID}`}>Gender</Link> */}
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>
                  Lifestyle
                </Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                  My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>
                  About Me
                </Link>
                <Link to={`/users/my-document/${userID}`}>
                  My Document
                </Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row ">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style justify-content-center" onSubmit={formik.handleSubmit}>

                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Height</label>
                  <div className="input-container add_user_info">
                    <div className="form-groupp">
                      <div className="nutritionRadio">
                        <p className="mb-0">
                          <input type="radio" id="test1" name="radio-group"
                            onChange={() => formik.setFieldValue("height_type", "feet")}
                            checked={formik.values.height_type === "feet"}
                          />
                          <label for="test1">Feet</label>
                        </p>
                        <p className="mb-0">
                          <input type="radio" id="test2" name="radio-group"
                            onChange={() => formik.setFieldValue("height_type", "cm")}
                            checked={formik.values.height_type === "cm"} />
                          <label for="test2">Cm</label>
                        </p>

                      </div>
                    </div>

                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  {formik.values.height_type === "cm" ?
                    <div className="input-container add_user_info">
                      <input
                        type="number"
                        className="form-control ps-4"
                        placeholder="Height Cm"
                        {...formik.getFieldProps("cm")}
                      />

                      {formik.touched.cm && formik.errors.cm ? (
                        <span className="error">{formik.errors.cm}</span>
                      ) : null}
                    </div> : ""}
                  {formik.values.height_type === "feet" ?
                    <div className="input-container add_user_info">
                      <input
                        type="number"
                        className="form-control ps-4"
                        placeholder="Height Ft"
                        {...formik.getFieldProps("feet")}
                      />

                      {formik.touched.feet && formik.errors.feet ? (
                        <span className="error">{formik.errors.feet}</span>
                      ) : null}

                    </div> : ""}
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Weight</label>
                  <div className="input-container add_user_info">
                    <input
                      type="number"
                      className="form-control ps-4"
                      placeholder="Weight"
                      {...formik.getFieldProps("body_weight")}
                    />
                    {formik.touched.body_weight && formik.errors.body_weight ? (
                      <span className="error">{formik.errors.body_weight}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Spectacles</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("spectacles")}
                    >
                      <option disabled value="">
                        Select
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {/* <img
                      src="images/astrology.png"
                      className="input-img pass"
                      alt=""
                    /> */}
                    {formik.touched.spectacles && formik.errors.spectacles ? (
                      <span className="error">{formik.errors.spectacles}</span>
                    ) : null}
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Complexion</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("complexion")}
                    >
                      <option disabled value="">
                        Select Complexion
                      </option>
                      <option value="Fair">Fair</option>
                      <option value="Medium Fair">Medium Fair</option>
                      <option value="Naturally Brown">Naturally Brown</option>
                      <option value="Dark Brown">Dark Brown</option>
                    </select>
                    {/* <img
                      src="images/astrology.png"
                      className="input-img pass"
                      alt=""
                    /> */}
                    {formik.touched.complexion && formik.errors.complexion ? (
                      <span className="error">{formik.errors.complexion}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Disability</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("disability")}
                    >
                      <option disabled value="">
                        Select Disability
                      </option>
                      <option value="Not applicable">Not applicable</option>
                      <option value="Blindness">Blindness</option>
                      <option value="Low-vision">Low-vision</option>
                      <option value="Hearing impairment">Hearing impairment</option>
                      <option value="Dwarfism">Dwarfism</option>
                      <option value="Muscular Dystrophy">Muscular Dystrophy</option>
                      <option value="Mental illness">Mental illness</option>
                      <option value="Autism Spectrum Disorder">Autism Spectrum Disorder</option>


                    </select>
                    {/* <img
                      src="images/astrology.png"
                      className="input-img pass"
                      alt=""
                    /> */}
                    {formik.touched.disability && formik.errors.disability ? (
                      <span className="error">{formik.errors.disability}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-8 col-lg-8 mb-4 text-center">
                  <label className="num"> Blood Group</label>
                  <div className="gander blood-group">
                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.blood_group === "O-"}
                        onChange={() => formik.setFieldValue("blood_group", "O-")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>O-</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.blood_group === "O+"}
                        onChange={() => formik.setFieldValue("blood_group", "O+")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>O+</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.blood_group === "AB-"}
                        onChange={() => formik.setFieldValue("blood_group", "AB-")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>AB-</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.blood_group === "AB+"}
                        onChange={() => formik.setFieldValue("blood_group", "AB+")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>AB+</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.blood_group === "B-"}
                        onChange={() => formik.setFieldValue("blood_group", "B-")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>B-</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.blood_group === "B+"}
                        onChange={() => formik.setFieldValue("blood_group", "B+")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>B+</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.blood_group === "A-"}
                        onChange={() => formik.setFieldValue("blood_group", "A-")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>A-</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.blood_group === "A+"}
                        onChange={() => formik.setFieldValue("blood_group", "A+")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>A+</span>
                      </p>
                    </label>
                  </div>
                  {formik.errors.group ? (
                    <span className="error">{formik.errors.blood_group}</span>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default Height;
