import React, { useState, useEffect } from 'react'
import { getHigherEducation } from "../../slice";
import { useSelector, useDispatch } from 'react-redux';
import { getCourseList } from "../../slice";


const Course = ({ courseValue, setSpecializationValue, checkedItems, setCheckedItems }) => {
    const dispatch = useDispatch()
    const courses = useSelector((state) => state?.courseList)

    // useEffect(() => {
    //     if (checkedItems?.length === 0) {
    //         dispatch(getCourseList({ higher_education: courseValue }))
    //     }
    // }, [])
    console.log("courses api", courses)
    const handleCheckOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setCheckedItems([...checkedItems, value]); // add the value to the array
            setSpecializationValue(...checkedItems, value)
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== value)); // remove the value from the array
        }
    };
    console.log("333", courses)

    return (
        <div className='gov text-center mb-4 mt-3'>
            <h4>Course List</h4>
            {courses?.map((res, i) => {
                return <div className="" key={i}>
                    <input
                        type="checkbox"
                        id="notifyAllUsers"
                        name="notifyAllUsers"
                        value={res?._id}
                        // checked={checkedItems.includes(res?.id)}
                        onChange={handleCheckOptions}
                    />
                    <label htmlFor="groupA" className='mx-3'>
                        {res?.name}
                    </label>
                </div>
            })}
        </div>
    )
}

export default Course