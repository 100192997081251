import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";

import { getUserDetails } from "../../slice";
import { editGender } from "../../../api/edit";
import SimpleBackdrop from "../../../components/backdrop";
import { Link } from "react-router-dom";
import UserProfile from "../details/Profile";

const Gender = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      gender: data?.gender || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      gender: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      let payload = {
        gender: values.gender,
        userid: userID,
      };

      editGender(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          dispatch(getUserDetails({ user_id: userID }));
        }
      });
    },
  });

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Gender"
              : "Add User Gender"}
          </h2>
          <UserProfile data={data} />

          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img
                src="images/edit.png"
                className="action-view"
                alt="edit"
              />
               <div className="edit-pages">
                      <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                      <Link to={`/users/edit-gender/${userID}`}>Gender</Link>
                      <Link to={`/users/edit-marital-status/${userID}`}>
                        Personal Details
                      </Link>
                      <Link to={`/users/edit-religion/${userID}`}>
                        Religion/ caste
                      </Link>
                      <Link to={`/users/edit-horoscope-details/${userID}`}>
                        Horoscope Details
                      </Link>
                      <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                      <Link to={`/users/edit-occupation-details/${userID}`}>
                        Professional Details
                      </Link>
                      <Link to={`/users/edit-family-details/${userID}`}>
                        Family Details
                      <Link to={`/users/edit-lifestyle/${userID}`}>
                        Lifestyle
                      </Link>
                      </Link>
                      <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                      <Link to={`/users/edit-partner-preferences/${userID}`}>
                        Partner Preferences
                      </Link>
                      <Link to={`/users/about-me/${userID}`}>
                        About Yourself
                      </Link>
                    </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <form className="col-md-12" onSubmit={formik.handleSubmit}>
              <div className="add_new_details_mian">
                <div className="gander">
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      checked={formik.values.gender === "male"}
                      onChange={() => formik.setFieldValue("gender", "male")}
                    />
                    <div>
                      <img
                        src="images/male-unselected.png"
                        className="unselected"
                        alt=""
                      />
                      <img
                        src="images/male-selected.png"
                        className="selected"
                        alt=""
                      />
                    </div>
                    <p>
                      <span>MALE</span>
                    </p>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      checked={formik.values.gender === "female"}
                      onChange={() => formik.setFieldValue("gender", "female")}
                    />
                    <div>
                      <img
                        src="images/female-unselected.png"
                        className="unselected"
                        alt=""
                      />
                      <img
                        src="images/female-selected.png"
                        className="selected"
                        alt=""
                      />
                    </div>
                    <p>
                      <span>FEMALE</span>
                    </p>
                  </label>
                </div>
                {formik.errors.gender ? (
                  <span className="error">{formik.errors.gender}</span>
                ) : null}
              </div>
              <div className="button_area">
                <button type="submit" className="save_btn" disabled={saving}>
                  {saving ? (
                    <CircularProgress color="inherit" />
                  ) : pathname.includes("/edit") ? (
                    "Save Changes"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default Gender;
