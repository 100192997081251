/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";

const addBodyClass = (className) => document.body.classList.add(className);
const removeBodyClass = (className) =>
  document.body.classList.remove(className);

export function usePrevious(value) {
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const useInputValue = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  return {
    value,
    onChange: (e) => {
      setValue(e.target.value || e.target.innerText);
    },
  };
};

export const useToggle = (initial) => {
  const [open, setOpen] = useState(initial);

  return [open, useCallback(() => setOpen((status) => !status), [])];
};

export default function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

//create your forceUpdate hook
export function useForceUpdate() {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

export function useAddressPredictions(input) {
  const [predictions, setPredictions] = useState([]);

  const autocomplete = useRef();

  if (!autocomplete.current) {
    autocomplete.current = new window.google.maps.places.AutocompleteService();
  }

  function getPlacePredictions(input) {
    autocomplete.current.getPlacePredictions({ input }, (predictions) => {
      if (predictions && predictions.length) {
        setPredictions(
          predictions.map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id,
          }))
        );
      }
    });
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    []
  );

  useEffect(() => {
    debouncedGetPlacePredictions(input);
  }, [input, debouncedGetPlacePredictions]);

  return predictions;
}

export function useGeoCoder(placeId) {
  const [geometry, setGeometry] = useState(null);

  function getGeomtry(placeId) {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId }, function (results, status) {
      if (status === "OK") {
        const result = results[0];
        const address = result?.formatted_address;
        const zipCode =
          result?.address_components[result.address_components.length - 1]
            ?.long_name;
        const country =
          result?.address_components[result.address_components.length - 2]
            ?.long_name;
        const state =
          result?.address_components[result.address_components.length - 3]
            ?.long_name;
        const city =
          result?.address_components[result.address_components.length - 4]
            ?.long_name;
        const latitude = result.geometry.location.lat();
        const logitude = result.geometry.location.lng();

        setGeometry({
          latitude,
          logitude,
          address,
          zipCode,
          country,
          state,
          city,
        });
      } else {
        console.error(results);
      }
    });
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getGeomtry, 500),
    []
  );

  useEffect(() => {
    debouncedGetPlacePredictions(placeId);
  }, [placeId, debouncedGetPlacePredictions]);

  return geometry;
}

export function useBodyClass(className) {
  useEffect(() => {
    // Set up
    className instanceof Array
      ? className.map(addBodyClass)
      : addBodyClass(className);

    // Clean up
    return () => {
      className instanceof Array
        ? className.map(removeBodyClass)
        : removeBodyClass(className);
    };
  }, [className]);
}
