import React from 'react'

const EmploymentType = ({ checkedItems, setCheckedItems, setEmploymentValue }) => {
    const employment_type = [{ _id: "Government", name: "Government" }, { _id: "Private", name: "Private" }, { _id: "Business", name: "Business" }, { _id: "Defence", name: "Defence" }, { _id: "Self employed", name: "Self employed" }, { _id: "Private Service & Business", name: "Private Service & Business" }, { _id: "Government Service & Business", name: "Government Service & Business" }, { _id: "Self Employed & Business", name: "Self Employed & Business" }]


    const handleCheckOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setCheckedItems([...checkedItems, value]); // add the value to the array
            setEmploymentValue([...checkedItems, value])
        } else {
            setEmploymentValue(checkedItems.filter((item) => item !== value)); // remove the value from the array
        }
    };


    return (
        <div className='gov text-center mb-4 mt-3'>
            <h4>Employment Type</h4>
            {employment_type?.map((res, i) => {
                return <div className="" key={i}>
                    <input
                        type="checkbox"
                        id="notifyAllUsers"
                        name="notifyAllUsers"
                        value={res?._id}
                        // checked={checkedItems.includes(res?.id)}
                        onChange={handleCheckOptions}
                    />
                    <label htmlFor="groupA" className='mx-3'>
                        {res?.name}
                    </label>
                </div>
            })}
        </div>
    )
}

export default EmploymentType