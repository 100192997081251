import React, { useEffect, useState } from 'react'
import { getNotifications, getUnreadNotifications } from '../slice';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import axios from 'axios';
import { getCookie } from "../../utils";
import Pagination from "react-js-pagination";


const AllNotifications = () => {
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    const [activePage, setActivePage] = useState(1);
    const data = useSelector((state) => state?.notificationList);

    useEffect(() => {
        dispatch(getNotifications({ page: page }))
    }, []);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        // setPageNo(pageNumber)
        dispatch(getNotifications({ page: pageNumber }))
    }
    const handleReadNotification = (e, id) => {
        e.preventDefault();
        handleUpdate(id)
    }
    const handleUpdate = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "Authorization": getCookie("clszdi")
            },
            url: `https://api.pairsindia.in/user/read_notification`,
            data: {

                "notification_id": id
            }
        })
            .then(res => {
                if (res.data.error_code === 0) {
                    dispatch(getNotifications({ page: page }));
                    dispatch(getUnreadNotifications({}));
                }
            })
    }

    return (
        <div className="m-main-content">
            <div className="brand_table">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="brand_table_inner">
                                <h3 className='text-center'>All Notifications</h3>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data?.data?.length ? (
                                                data?.data?.map((res, i) => (
                                                    <tr key={i}>
                                                        <td className="bt-non" onClick={(e) => handleReadNotification(e, res?._id)}>
                                                            {res?.adminByStatus === 0 && res?.title === "New User signup" ?
                                                                <Link to={`/users/new/${res.action_id}`} title="View">
                                                                    <span className={res?.seen === 1 ? "read" : "unread"}> <i class="fa fa-circle" aria-hidden="true" style={{ fontSize: "15px" }}></i>{res?.body}
                                                                    </span>
                                                                    <p className={res?.seen === 1 ? "read mx-5" : "unread mx-5"}>
                                                                        {res?.createdAt
                                                                            ? new Date(
                                                                                res?.createdAt
                                                                            ).toLocaleString()
                                                                            : "-"}
                                                                    </p>
                                                                </Link> :
                                                                ""
                                                            }
                                                            {res?.adminByStatus === 1 && res?.title === "New User signup" ?

                                                                <Link
                                                                    to={`/users/approved/${res.action_id}`}
                                                                    title="View"
                                                                >
                                                                    <span className={res?.seen === 1 ? "read" : "unread"}> <i class="fa fa-circle" aria-hidden="true" style={{ fontSize: "15px" }}></i>{res?.body}
                                                                    </span>
                                                                    <p className={res?.seen === 1 ? "read mx-5" : "unread mx-5"}>
                                                                        {res?.createdAt
                                                                            ? new Date(
                                                                                res?.createdAt
                                                                            ).toLocaleString()
                                                                            : "-"}
                                                                    </p>
                                                                </Link> : ""
                                                            }
                                                            {res?.adminByStatus === 1 && res?.title === "New images found" ?
                                                                <Link
                                                                    to={`approve-photos`}
                                                                    title="View"
                                                                >
                                                                    <span className={res?.seen === 1 ? "read" : "unread"}> <i class="fa fa-circle" aria-hidden="true" style={{ fontSize: "15px" }}></i>{res?.body}
                                                                    </span>
                                                                    <p className={res?.seen === 1 ? "read mx-5" : "unread mx-5"}>
                                                                        {res?.createdAt
                                                                            ? new Date(
                                                                                res?.createdAt
                                                                            ).toLocaleString()
                                                                            : "-"}
                                                                    </p>
                                                                </Link> : ""
                                                            }
                                                            {res?.adminByStatus === 0 && res?.title === "New images found" ?
                                                                <Link
                                                                    to={`approve-photos`}
                                                                    title="View"
                                                                >
                                                                    <span className={res?.seen === 1 ? "read" : "unread"}> <i class="fa fa-circle" aria-hidden="true" style={{ fontSize: "15px" }}></i>{res?.body}
                                                                    </span>
                                                                    <p className={res?.seen === 1 ? "read mx-5" : "unread mx-5"}>
                                                                        {res?.createdAt
                                                                            ? new Date(
                                                                                res?.createdAt
                                                                            ).toLocaleString()
                                                                            : "-"}
                                                                    </p>
                                                                </Link> : ""
                                                            }
                                                            {res?.adminByStatus === 1 && res?.title === "Purchased a new subscription plan" ?

                                                                <Link
                                                                    to={`/users/approved/${res.action_id}`}
                                                                    title="View"
                                                                >
                                                                    <span className={res?.seen === 1 ? "read" : "unread"}> <i class="fa fa-circle" aria-hidden="true" style={{ fontSize: "15px" }}></i>{res?.body}
                                                                    </span>
                                                                    <p className={res?.seen === 1 ? "read mx-5" : "unread mx-5"}>
                                                                        {res?.createdAt
                                                                            ? new Date(
                                                                                res?.createdAt
                                                                            ).toLocaleString()
                                                                            : "-"}
                                                                    </p>
                                                                </Link>
                                                                : ""
                                                            }
                                                            {res?.adminByStatus === 0 && res?.title === "Purchased a new subscription plan" ?
                                                                <>
                                                                    <span className={res?.seen === 1 ? "read" : "unread"}> <i class="fa fa-circle" aria-hidden="true" style={{ fontSize: "15px" }}></i>{res?.body}
                                                                    </span>
                                                                    <p className={res?.seen === 1 ? "read mx-5" : "unread mx-5"}>
                                                                        {res?.createdAt
                                                                            ? new Date(
                                                                                res?.createdAt
                                                                            ).toLocaleString()
                                                                            : "-"}
                                                                    </p>
                                                                </> : ""
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} style={{ padding: "10px" }}>
                                                        No Results Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {data?.totalCount
                                        >= 9 ?
                                        <div className='mt-4'>
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={data?.totalCount
                                                }
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                            />
                                        </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllNotifications