import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";

import { fetchPackageDetails, getUserDetails } from "../../slice";
import { editAnnualPackage } from "../../../api/edit";
import InputSlider from "../../../components/slider/InputSlider";
import SimpleBackdrop from "../../../components/backdrop";

const AnnualPackage = () => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const packageData = useSelector((state) => state.packageDetails);
  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  useEffect(() => {
    dispatch(fetchPackageDetails({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      value: data?.annual_package || "",
      lessThan: false,
      currency: data?.currency?.toUpperCase() || "INR",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      value: Yup.string().required("*Required"),
      currency: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (!values.lessThan) {
        setSaving(true);
        let payload = {
          userid: userID,
          currency: values.currency || "INR",
          annual_package: values.value,
        };

        editAnnualPackage(payload).then((response) => {
          setSaving(false);
          enqueueSnackbar(response?.message);
          if (response?.error_code === 0) {
            resetForm({});
            goBack();
          }
        });
      }
    },
  });

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Annual Package"
              : "Add User Annual Package"}
          </h2>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form
                className="row form-style profile-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                  <div className="">
                    <img
                      src="images/annual-package.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="marital-status">
                    <span>
                      <input
                        type="radio"
                        id="INR"
                        name="currency"
                        checked={
                          formik.values.currency?.toUpperCase() === "INR"
                        }
                        onChange={() => {
                          formik.setFieldValue("currency", "INR");
                          formik.setFieldValue("value", 0);
                        }}
                      />
                      <label className="pointer" htmlFor="INR">
                        INR
                      </label>
                    </span>

                    <span>
                      <input
                        type="radio"
                        id="USD"
                        name="currency"
                        checked={
                          formik.values.currency?.toUpperCase() === "USD"
                        }
                        onChange={() => {
                          formik.setFieldValue("currency", "USD");
                          formik.setFieldValue("value", 0);
                        }}
                      />
                      <label className="pointer" htmlFor="USD">
                        USD
                      </label>
                    </span>
                  </div>
                  <div className="range">
                    <InputSlider
                      formik={formik}
                      minimum={
                        data?.currency?.toUpperCase() === "USD"
                          ? packageData?.dollar_min
                          : packageData?.rs_min
                      }
                      maximum={
                        data?.currency?.toUpperCase() === "USD"
                          ? packageData?.dollar_max
                          : packageData?.rs_max
                      }
                      currency={data?.currency?.toUpperCase()}
                    />
                  </div>
                  {formik.errors.value ? (
                    <span className="error">{formik.errors.value}</span>
                  ) : null}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default AnnualPackage;
