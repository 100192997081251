import React from "react";
import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useParams,
} from "react-router-dom";
import Blocked from "./Blocked";
import InterestDeclinedByMe from "./InterestDeclinedByMe";
import InterestReceived from "./InterestReceived";
import InterestSent from "./InterestSent";
import MutuallyLiked from "./MutuallyLiked";
import MyInterestDeclined from "./MyInterestDeclined";
import MyMatches from "./MyMatches";
import SeenProfiles from "./SeenProfiles";
import BioDataAccept from "./bioDataAccept";
import BioDataSent from "./bioDataSent";
import BioDataReceive from "./bioDataReceive";
import BioDataAcceptByThem from "./bioDataAcceptByThem";
import BioDataReject from "./bioDataReject";
import BioDataRejectByThem from "./biodataRejectByThem";
import ShortlistProfiles from "./shortlistProfiles";
import ProfileVisitors from "./profileVisitors";

const UserMatches = () => {
  let { path } = useRouteMatch();
  const { userID } = useParams();

  return (
    <>
      <h4 className="report_details_head">User Matches</h4>
      <div className="user_match_menu">
        <ul className="user_match_info">
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/mutually-liked`}
              className="match_link"
            >
              Mutually Liked
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/interest-sent`}
              className="match_link"
            >
              Interest Sent
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/interest-received`}
              className="match_link"
            >
              Interest Received
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/interest-declined`}
              className="match_link"
            >
              Interest Declined By Me
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/interest-declined-by-them`}
              className="match_link"
            >
              Interest Declined By Them
            </NavLink>
          </li>

          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/shortlistprofile`}
              className="match_link"
            >
              Shortlisted Profiles
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/seen-profile`}
              className="match_link"
            >
              Seen Profiles
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/profile-visitors`}
              className="match_link"
            >
              Profile Visitors
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/biodata-sent`}
              className="match_link"
            >
              Biodata Request sent
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/biodata-receive`}
              className="match_link"
            >
              Biodata Request received
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/biodata-accept`}
              className="match_link"
            >
              Biodata Request Accepted
            </NavLink>
          </li>
          {/* <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/biodata-accept-by-them`}
              className="match_link"
            >
              Biodata Request Accepted By Them
            </NavLink>
          </li> */}
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/biodata-reject`}
              className="match_link"
            >
              Biodata Request Decline By Me
            </NavLink>
          </li>
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/biodata-reject-by-them`}
              className="match_link"
            >
              Biodata Request Decline By Them
            </NavLink>
          </li>



          {/*         
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/interest-declined`}
              className="match_link"
            >
              Interest Declined By Me
            </NavLink>
          </li>
         
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/declined-interest`}
              className="match_link"
            >
              My Interest Declined
            </NavLink>
          </li> */}
          <li className="user_match_list">
            <NavLink
              to={`/users/approved/${userID}/blocked`}
              className="match_link"
            >
              Blocked Profiles
            </NavLink>
          </li>
        </ul>
      </div>
      <Switch>
        <Route exact path={`${path}/mutually-liked`}>
          <MutuallyLiked />
        </Route>
        {/* <Route exact path={`${path}/my-matches`}>
          <MyMatches />
        </Route> */}
        <Route exact path={`${path}/interest-sent`}>
          <InterestSent />
        </Route>
        <Route exact path={`${path}/interest-received`}>
          <InterestReceived />
        </Route>
        <Route exact path={`${path}/interest-declined-by-them`}>
          <MyInterestDeclined />
        </Route>
        <Route exact path={`${path}/interest-declined`}>
          <InterestDeclinedByMe />
        </Route>
        <Route exact path={`${path}/shortlistprofile`}>
          <ShortlistProfiles />
        </Route>

        <Route exact path={`${path}/seen-profile`}>
          <SeenProfiles />
        </Route>
        <Route exact path={`${path}/profile-visitors`}>
          <ProfileVisitors/>
        </Route>

        <Route exact path={`${path}/biodata-sent`}>
          <BioDataSent />
        </Route>
        <Route exact path={`${path}/biodata-receive`}>
          <BioDataReceive />
        </Route>
        <Route exact path={`${path}/biodata-accept`}>
          <BioDataAccept />
        </Route>
        {/* <Route exact path={`${path}/biodata-accept-by-them`}>
          <BioDataAcceptByThem />
        </Route> */}
        <Route exact path={`${path}/biodata-reject`}>
          <BioDataReject />
        </Route>
        <Route exact path={`${path}/biodata-reject-by-them`}>
          <BioDataRejectByThem />
        </Route>
        <Route exact path={`${path}/biodata-request-received`}>
          <SeenProfiles />
        </Route>
        <Route exact path={`${path}/biodata-request-decline-by-them`}>
          <SeenProfiles />
        </Route>
        <Route exact path={`${path}/biodata-request-decline-by-me`}>
          <SeenProfiles />
        </Route>
        <Route exact path={`${path}/blocked`}>
          <Blocked />
        </Route>
        {/* <Route exact path={`${path}/declined-interest`}>
          <MyInterestDeclined />
        </Route> */}

        {/* <Redirect to={`${path}/mutually-liked`} /> */}
      </Switch>
    </>
  );
};

export default UserMatches;
