import React, { useState, useEffect } from 'react'
import { getHigherEducation } from "../../slice";
import { useSelector, useDispatch } from 'react-redux';
import { getSpecializationList } from "../../slice";

const Specialization = ({ specializationValues, checkedItems, setCheckedItems, setCheckedItemsSpecialization }) => {
    const dispatch = useDispatch()
    const specialization = useSelector((state) => state.specializationList)

    // useEffect(() => {
    //     if (checkedItems?.length > 0) {
    //         dispatch(getSpecializationList({ course: specializationValues }))
    //     }
    // }, [])

    const handleCheckOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setCheckedItems([...checkedItems, value]); // add the value to the array
            setCheckedItemsSpecialization(...checkedItems, value)
        } else {
            setCheckedItemsSpecialization(checkedItems.filter((item) => item !== value)); // remove the value from the array
        }
    };

    console.log("spec", specializationValues)


    return (
        <div className='gov text-center mb-4 mt-5'>
            <h4>Specialization</h4>
            {specialization?.map((res, i) => {
                return <div className="" key={i}>
                    <input
                        type="checkbox"
                        id="notifyAllUsers"
                        name="notifyAllUsers"
                        value={res?._id}
                        // checked={checkedItems.includes(res?.id)}
                        onChange={handleCheckOptions}
                    />
                    <label htmlFor="groupA" className='mx-3'>
                        {res?.name}
                    </label>
                </div>
            })}
        </div>
    )
}

export default Specialization