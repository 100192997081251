import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import jwtDecode from "jwt-decode";
import NotFoundPage from "../components/error";
import Sidebar from "../features/sidebar";
import publicRoutes from "./public";
import privateRoutes from "./private";
import { useToggle } from "../app/hooks";
import { getCookie } from "../utils";
import { userLoggedIn } from "../features/slice";
import Header from "../features/header/header";

const Routes = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.isLoggedIn);
  const [isOpen, toggle] = useToggle(false);

  const token = getCookie("clszdi");

  useEffect(() => {
    const decodedToken = token && jwtDecode(token);
    if (decodedToken?.exp * 1000 > new Date().getTime()) {
      dispatch(userLoggedIn({ varificationToken: token }));
    }
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        {!isLoggedIn &&
          publicRoutes.length &&
          publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.main />}
            />
          ))}

        {token && (
          <Fragment>
            <div className="wrapper-main">
              <div className="management-wrapper">
                <Header isOpen={isOpen} />
                <Sidebar isOpen={isOpen} />
                {privateRoutes.length &&
                  privateRoutes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      children={<route.main />}
                    />
                  ))}
                {/* <Redirect from="/login" to="/dashboard" /> */}
              </div>
            </div>
            <div className="mobile-menu-tab">
              <i
                className={isOpen ? "fa fa-times" : "fa fa-bars"}
                onClick={toggle}
              ></i>
            </div>
            <div
              className="mobile-menu-overlay"
              style={{ display: isOpen ? "block" : "none" }}
            ></div>
          </Fragment>
        )}
        <Redirect from="/" to="/login" />
        <Route path="/404">
          <NotFoundPage />
        </Route>
        <Redirect from="/*" to="/404" />
      </Switch>
    </Router>
  );
};

export default Routes;
