import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";


import SimpleBackdrop from "../../components/backdrop";
import { useToggle } from "../../app/hooks";
import SupportFormResponse from "./SupportFormResponse";
import { getQueries } from "../slice";



const SupportFormList = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state?.status);
    const data = useSelector((state) => state?.allquery);
    const [isOpen, toggle] = useToggle(false);
    const [id, setId] = useState("")
    const [userQuery, setUserQuery] = useState("")
    const [response, setResponse] = useState("")

    useEffect(() => {
        dispatch(getQueries({}));
    }, [dispatch]);

    console.log("@@", data)

    return (
        <div className="m-main-content">
            <div className="brand_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="brand_head_data">
                                <div className="brands_total deactive_user">
                                    {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                                    <p className="t_brand_txt">Total Queries</p>
                                    <h4 className="brand_num">{data?.total_query || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end col-md-8">
                            {/* <form className="search_area" onSubmit={handleSearch}>
                                <div className="top-search-bar p-top">
                                    <input
                                        type="text"
                                        name="search"
                                        value={search}
                                        placeholder="Search  by name"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    {search ? (
                                        <img
                                            src="images/cancel.png"
                                            className="search_icon pointer"
                                            alt="clear"
                                            onClick={() => setSearch("")}
                                        />
                                    ) : (
                                        <img
                                            src="images/search.png"
                                            className="search_icon"
                                            alt="search"
                                        />
                                    )}
                                </div>
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="brand_table">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="brand_table_inner">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">User name</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.query_list?.length ? (
                                                data?.query_list.map((el) => (
                                                    <tr>
                                                        <td className="bt-non">
                                                            {el?.userid?.first_name} {el?.userid?.last_name}
                                                        </td>
                                                        <td className="bt-non">
                                                            <span className={el?.status === "pending" ? "rejectedStatus" : "successStatus"} >{el?.status}</span>
                                                        </td>
                                                        <td className="bt-non">
                                                            {el?.createdAt?.substr(0, 10)}
                                                        </td>

                                                        <td className="bt-non">
                                                            <span className="table-action">
                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        setUserQuery(el?.your_query);
                                                                        setResponse(el?.response );
                                                                        toggle();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Delete"
                                                                >
                                                                    <img
                                                                        src="images/view.png"
                                                                        className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                            </span>
                                                        </td>
                                                    </tr>

                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} style={{ padding: "10px" }}>
                                                        No Results Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {/* {data?.pages > 1 ? (
                                        <Pagination
                                            count={data.pages}
                                            page={data.current}
                                            onChange={(e, page) => setPage(page)}
                                        />
                                    ) : null} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleBackdrop open={status === "loading"} />
            {isOpen ? <SupportFormResponse show={isOpen} handleShow={toggle} id={id} userQuery={userQuery} responseData={response} /> : ""}

        </div>
    );
};

export default SupportFormList;
