import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { getUserDetails, userDetails } from "../../slice";
import UserProfile from "./Profile";
import SimpleBackdrop from "../../../components/backdrop";
import HandleDelete from "../HandleDelete";
import { useToggle } from "../../../app/hooks";
import HandleVerify from "../HandleVerify";

const RejectedUserDetails = () => {
  const { userID } = useParams();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);
  const [isOpenDelete, toggleDelete] = useToggle(false);
  const [isOpenVerify, toggleVerify] = useToggle(false);

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  useEffect(() => {
    return () => dispatch(userDetails(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div
                className="new_user_detail_heading"
                style={{ position: "relative" }}
              >
                <button
                  className="back-arrow"
                  onClick={() => {
                    push("/users/rejected");
                  }}
                >
                  <img src="images/back.png" alt="back" />
                </button>
                <h2 className="new_user_txt">Rejected User Details</h2>
              </div>
              <UserProfile data={data} />
              <div className="upload_report_area">
                <div className="upload_btn_main">
                  <button
                    className="upload_btn"
                    type="button"
                    title="Reject"
                    onClick={() => {
                      setValue(1);
                      toggleVerify();
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="upload_close_btn"
                    type="button"
                    title="Delete"
                    onClick={toggleDelete}
                  >
                    <img src="images/delete-button.png" alt="delete" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleBackdrop open={status === "loading"} />
      <HandleDelete
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        userID={userID}
        userType="reject"
      />
      <HandleVerify
        isOpen={isOpenVerify}
        toggle={toggleVerify}
        userID={userID}
        userType="reject"
        status={value}
      />
    </div>
  );
};

export default RejectedUserDetails;
