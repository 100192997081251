import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { getUsersCount, getUsersList, idle, loading } from "../slice";
import { assignMembership, verifyUser } from "../../api/users";
import { useEffect } from "react";
import { getSubscription } from "../slice";

const HandleMembership = ({
  isOpen,
  toggle,
  userID,
  page,
  userType,
  id,
  setUserCheck,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { goBack } = useHistory();
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state.subscription);
  const [subscriptionId, setSubscriptionId] = useState("");

  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

  useEffect(() => {
    dispatch(getSubscription({}));
  }, []);

  console.log("data subscription", subscriptionId);
  console.log("all", id);
  const handleVerification = () => {
    if (id) {
      dispatch(loading());
      const payload = {
        package: subscriptionId,
        userIds: id?.toString(),
      };

      assignMembership(payload).then((response) => {
        setUserCheck(1);
        if (!response?.error_code) {
          if (page) {
            dispatch(
              getUsersList({ tab_type: userType, page, perpage: limit })
            );
            dispatch(getUsersCount({}));
          } else {
            goBack();
          }
          toggle();
        }
        getUsersList({ tab_type: userType, page, perpage: limit });

        enqueueSnackbar(response?.message);
        dispatch(idle());
        toggle();
      });
    }
  };

  return (
    <>
      <div
        className={isOpen ? "modal fade show" : "modal fade"}
        id="delUserDetails"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Assign Membership</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={toggle}
              >
                &times;
              </button>
            </div>
            <div className="p-4">
              <h5>Select Membership Plan*</h5>
              <select
                className="form-control add_user_info"
                value={subscriptionId}
                onChange={(e) => setSubscriptionId(e.target.value)}
              >
                <option></option>
                {data?.subscription_list?.map((res, i) => {
                  return <option value={res?._id}>{res?.package}</option>;
                })}
              </select>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="Del_btn"
                data-dismiss="modal"
                onClick={handleVerification}
              >
                Assign
              </button>
              <button
                type="button"
                className="Can_btn"
                data-dismiss="modal"
                onClick={toggle}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

HandleMembership.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  userID: PropTypes.string.isRequired,
  page: PropTypes.number,
  userType: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default HandleMembership;
