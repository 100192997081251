import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ImgFluid from "../../../../components/image/ImgFluid";
import { getBlockedProfiles, matchesList } from "../../../slice";

const Blocked = () => {
  const { userID } = useParams();
  const dispatch = useDispatch();
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state?.matchesList);

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getBlockedProfiles({ userid: userID, page, perpage: limit }));
  }, [dispatch, page, limit, userID]);

  useEffect(() => {
    return () => dispatch(matchesList(null));
  }, [dispatch]);

  return (
    <>
      <div className="user_match_card_main">
        {data?.data?.length ? (
          data.data.map((el) => (
            <div className="match_card_content" key={el._id}>
              <div className="u_card_img">
                <ImgFluid
                  src={data?.profile_image || el.profile_image_video_path}
                  className="c_usr_img"
                />
              </div>
              <div className="u_card_info">
                <p className="mat_percent">
                  <i className="fa fa-heart"></i> {el.t_percentage}% match
                </p>
                <h4 className="m_name">
                  {`${el.first_name} ${el.last_name}`}`,{" "}
                  <span className="m_age">{el.birthdaysDetails?.age}</span>
                </h4>
                <div className="m_relegious">
                  <span className="m_hnd">
                    <img
                      src="images/religion-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {el.religionDetails?.name}
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/community-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {el.communitiesDetails?.name}
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/language-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {el.mothertonguesDetails?.name}
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/height-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {/* {el.heightsDetails?.height_type === "feet"
                      ? `${el.heightsDetails.feet}' ${el.heightsDetails.inc}"`
                      : el.heightsDetails.cm + "cm"} */}
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/salary-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />{" "}
                    {el.annual_package?.toLocaleString(
                      el.currency?.toUpperCase() === "USD" ? "en-US" : "en-IN",
                      {
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: el.currency?.toUpperCase() || "INR",
                      }
                    ) || "N/A"}
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/higher-education.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {
                      el.educationsDetails?.graduation_details?.[0]
                        .graduation_degree
                    }
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/location-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {`${el.city || ""} ${el.state || ""} ${el.country || ""}`}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No records found</div>
        )}
      </div>
      {data?.pages > data?.current ? (
        <div className="see_all_head">
          <button
            style={{ background: "none", border: "none" }}
            className="see_all_txt"
            onClick={() => setPage(data.current + 1)}
          >
            See More...
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Blocked;
