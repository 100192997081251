import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";

import { getUserDetails } from "../../slice";
import { editMaritalStatus } from "../../../api/edit";
import SimpleBackdrop from "../../../components/backdrop";
import { Link } from "react-router-dom";
import UserProfile from "../details/Profile";

const MaritalStatus = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);
  console.log("##", userID)

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      maritalStatus: data?.married || "",
      child: data?.second_marriage_option || "",
      decree: data?.living_option || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      maritalStatus: Yup.string().required("*Required"),
      child: Yup.string().when("maritalStatus", {
        is: "widow",
        then: Yup.string().required("Required"),
      },
        {
          is: "divorced",
          then: Yup.string().required("Required"),
        }, {
        is: "awaiting divorce",
        then: Yup.string().required("Required"),
      }),
      decree: Yup.string().when("maritalStatus", {
        is: "widow" || "divorced" || "awaiting divorce",
        then: Yup.string().required("Required"),
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);

      const { maritalStatus, child, decree } = values;
      let payload = {
        userid: userID,
        married: maritalStatus,

      };

      if (maritalStatus === "widow" || maritalStatus === "awaiting divorce" || maritalStatus === "divorced") {
        payload.second_marriage_option = child;
        payload.living_option = decree;
      }

      editMaritalStatus(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          dispatch(getUserDetails({ user_id: userID }));
        }
      });
    },
  });

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Personal Details"
              : "Add User Personal Details"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img
                src="images/edit.png"
                className="action-view"
                alt="edit"
              />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                {/* <Link to={`/users/edit-gender/${userID}`}>Gender</Link> */}
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>
                  Lifestyle
                </Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                 My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>
                  About Me
                </Link>
                <Link to={`/users/my-document/${userID}`}>
                  My Document
                </Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form
                className="row form-style profile-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group col-sm-12 col-md-12 text-center mb-5">

                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                  <div className="marital-status">
                    <span>
                      <input
                        type="radio"
                        id="NeverMarried"
                        name="merital_status"
                        checked={
                          formik.values.maritalStatus === "never married"
                        }
                        onChange={() =>
                          formik.setFieldValue("maritalStatus", "never married")
                        }
                      />
                      <label htmlFor="NeverMarried">Never Married</label>
                    </span>

                    <span>
                      <input
                        type="radio"
                        id="widow"
                        name="merital_status"
                        checked={
                          formik.values.maritalStatus === "widow"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "maritalStatus",
                            "widow"
                          )
                        }
                      />
                      <label htmlFor="widow">Widow</label>
                    </span>
                    <span>
                      <input
                        type="radio"
                        id="Divorced"
                        name="merital_status"
                        checked={
                          formik.values.maritalStatus === "divorced"
                        }
                        onChange={() =>
                          formik.setFieldValue("maritalStatus", "divorced")
                        }
                      />
                      <label htmlFor="Divorced">Divorced</label>
                    </span>
                    <span>
                      <input
                        type="radio"
                        id="AwaitingDivorce"
                        name="merital_status"
                        checked={
                          formik.values.maritalStatus === "awaiting divorce"
                        }
                        onChange={() =>
                          formik.setFieldValue("maritalStatus", "awaiting divorce")
                        }
                      />
                      <label htmlFor="AwaitingDivorce">Awaiting Divorce</label>
                    </span>
                  </div>
                </div>
                {formik.values.maritalStatus === "divorced" || formik.values.maritalStatus === "widow" || formik.values.maritalStatus === "awaiting divorce" ? (
                  <Fragment>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                      <span className="botr"></span>
                      {formik.errors.child ? (
                        <span className="error">{formik.errors.child}</span>
                      ) : null}
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                      <div className="marital-status">
                        <span>
                          <input
                            type="radio"
                            id="WithChild"
                            name="child"
                            checked={formik.values.child === "With Child"}
                            onChange={() =>
                              formik.setFieldValue("child", "With Child")
                            }
                          />
                          <label htmlFor="WithChild">With Child</label>
                        </span>

                        <span>
                          <input
                            type="radio"
                            id="WithoutChild"
                            name="child"
                            checked={formik.values.child === "Without Child"}
                            onChange={() =>
                              formik.setFieldValue("child", "Without Child")
                            }
                          />
                          <label htmlFor="WithoutChild">Without Child</label>
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                      <span className="botr"></span>
                      {formik.errors.decree ? (
                        <span className="error">{formik.errors.decree}</span>
                      ) : null}
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                      <div className="marital-status">
                        <span>
                          <input
                            type="radio"
                            id="DecreeReceived"
                            name="decree"
                            checked={
                              formik.values.decree ===
                              "living together"
                            }
                            onChange={() =>
                              formik.setFieldValue(
                                "decree",
                                "living together"
                              )
                            }
                          />
                          <label htmlFor="DecreeReceived">
                            Living Together
                          </label>
                        </span>

                        <span>
                          <input
                            type="radio"
                            id="Decreecome"
                            name="decree"
                            checked={
                              formik.values.decree === "not living together"
                            }
                            onChange={() =>
                              formik.setFieldValue(
                                "decree",
                                "not living together"
                              )
                            }
                          />
                          <label htmlFor="Decreecome">Not Living Together</label>
                        </span>


                      </div>
                    </div>
                  </Fragment>
                ) : null}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default MaritalStatus;
