import ChangePassword from "../features/accounts/Settings/ChangePassword";
import UserLimits from "../features/limits/Limits";
import UserPackage from "../features/package/Package";
import Subscription from "../features/subscription/Subscription";
import ApprovedUser from "../features/users/ApprovedUser";
import AddUser from "../features/users/AddUser";
import NewUser from "../features/users/NewUser";
import RejectedUser from "../features/users/RejectedUser";
import NewUserDetails from "../features/users/details/NewUser";
import ApprovedUserDetails from "../features/users/details/ApprovedUser";
import RejectedUserDetails from "../features/users/details/RejectedUser";
import DeactiveUser from "../features/users/DeactiveUser";
import AboutUs from "../features/contentManagement/AboutUs";
import FAQs from "../features/contentManagement/FAQs";
import AppGuide from "../features/contentManagement/AppGuide";
import AddUpdateFAQs from "../features/contentManagement/addUpdateFaq";
import TermsNconditions from "../features/contentManagement/TermsNconditions";
import PrivacyPolicy from "../features/contentManagement/PrivacyPolicy";
import ContactUs from "../features/contentManagement/ContactUs";
import Gender from "../features/users/edit/Gender";
import AnnualPackage from "../features/users/edit/AnnualPackage";
import BirthDetails from "../features/users/edit/BirthDetails";
import Height from "../features/users/edit/Height";
import ImagesVideos from "../features/users/edit/ImagesVideos";
import MaritalStatus from "../features/users/edit/MaritalStatus";
import Nationality from "../features/users/edit/Nationality";
import PartnerPrefrences from "../features/users/edit/PartnerPrefrences";
import Hobbies from "../features/users/edit/Hobbies";
import Pets from "../features/users/edit/Pets";
import LifeStyle from "../features/users/edit/LifeStyle";
import FamilyDetails from "../features/users/edit/FamilyDetails";
import PastExperience from "../features/users/edit/PastExperience";
import OccupationDetails from "../features/users/edit/OccupationDetails";
import GraduationDetails from "../features/users/edit/GraduationDetails";
import EducationDetails from "../features/users/edit/EducationDetails";
import MotherTongue from "../features/users/edit/MotherTongue";
import Community from "../features/users/edit/Community";
import Religion from "../features/users/edit/Religion";
import BloodGroup from "../features/users/edit/BloodGroup";
import Questionnaire from "../features/users/edit/questionnaire";
import Subscriptionlist from "../features/subscription/Subscriptionlist";
import OfferList from "../features/offers/Offerslist";
import SupportFormList from "../features/supportForm/SupportFormList";
import TransactionList from "../features/transactions/TransactionList";
import Dashboard from "../features/dashboard";
import HoroscopeDetails from "../features/users/edit/HoroscopeDetails";
import BannerList from "../features/contentManagement/Banner/Bannerlist";
import SuccessList from "../features/contentManagement/SuccessStory/SuccessList";
import RevenueList from "../features/revenue/RevenueList";
import RevenueGenerated from "../features/reports/RevenueGenerated";
import SubscribedUsers from "../features/reports/SuscribedUsers";
import NotificationList from "../features/notifications/notification";
import CourseList from "../features/course/courseList";
import SpecializationList from "../features/specialization/specialization";
import GothraList from "../features/gothra/gothraList";
import DevakList from "../features/devak/devakList";
import OccupationList from "../features/occupation/occupationList";
import AboutMe from "../features/users/edit/Aboutme";
import MyDocuments from "../features/users/edit/MyDocument";
import UserPhotos from "../features/photos/photos";
import AllNotifications from "../features/allNotifications";
import ManageUserQueries from "../features/userQueries";
import DeactivatedUserDetails from "../features/users/details/DeactivatedUser";

const routes = [
  {
    path: "/dashboard",
    exact: true,
    main: () => <Dashboard />,
  },
  {
    path: "/approve-photos",
    exact: true,
    main: () => <UserPhotos />,
  },
  {
    path: "/users/edit-question/:userID",
    exact: false,
    main: () => <Questionnaire />,
  },
  {
    path: "/users/edit-partner-preferences/:userID",
    exact: true,
    main: () => <PartnerPrefrences />,
  },
  {
    path: "/users/edit-hobbies/:userID",
    exact: true,
    main: () => <Hobbies />,
  },
  {
    path: "/users/edit-pets/:userID",
    exact: true,
    main: () => <Pets />,
  },
  {
    path: "/users/edit-lifestyle/:userID",
    exact: true,
    main: () => <LifeStyle />,
  },
  {
    path: "/users/edit-family-details/:userID",
    exact: true,
    main: () => <FamilyDetails />,
  },
  {
    path: "/users/edit-past-experience/:userID",
    exact: true,
    main: () => <PastExperience />,
  },
  {
    path: "/users/edit-occupation-details/:userID",
    exact: true,
    main: () => <OccupationDetails />,
  },
  {
    path: "/users/edit-graduation-details/:userID",
    exact: true,
    main: () => <GraduationDetails />,
  },
  {
    path: "/users/edit-education-details/:userID",
    exact: true,
    main: () => <EducationDetails />,
  },
  {
    path: "/users/edit-mother-tongue/:userID",
    exact: true,
    main: () => <MotherTongue />,
  },
  {
    path: "/users/edit-community/:userID",
    exact: true,
    main: () => <Community />,
  },
  {
    path: "/users/edit-religion/:userID",
    exact: true,
    main: () => <Religion />,
  },
  {
    path: "/users/edit-blood-group/:userID",
    exact: true,
    main: () => <BloodGroup />,
  },
  {
    path: "/users/body-details/:userID",
    exact: true,
    main: () => <Height />,
  },
  {
    path: "/users/edit-birth/:userID",
    exact: true,
    main: () => <BirthDetails />,
  },
  {
    path: "/users/edit-images-videos/:userID",
    exact: true,
    main: () => <ImagesVideos />,
  },
  {
    path: "/users/edit-annual-package/:userID",
    exact: true,
    main: () => <AnnualPackage />,
  },
  {
    path: "/users/edit-nationality/:userID",
    exact: true,
    main: () => <Nationality />,
  },
  {
    path: "/users/about-me/:userID",
    exact: true,
    main: () => <AboutMe />,
  },
  {
    path: "/users/my-document/:userID",
    exact: true,
    main: () => <MyDocuments />,
  },
  {
    path: "/users/edit-marital-status/:userID",
    exact: true,
    main: () => <MaritalStatus />,
  },
  {
    path: "/users/edit-horoscope-details/:userID",
    exact: true,
    main: () => <HoroscopeDetails />,
  },
  {
    path: "/users/edit-gender/:userID",
    exact: true,
    main: () => <Gender />,
  },
  {
    path: "/users/edit/:userID",
    exact: true,
    main: () => <AddUser />,
  },
  {
    path: "/users/add",
    exact: true,
    main: () => <AddUser />,
  },
  {
    path: "/users/new/:userID",
    exact: true,
    main: () => <NewUserDetails />,
  },
  {
    path: "/users/new",
    exact: true,
    main: () => <NewUser />,
  },
  {
    path: "/users/approved/:userID",
    main: () => <ApprovedUserDetails />,
  },
  {
    path: "/deactive-user-detail/:userID",
    main: () => <DeactivatedUserDetails />,
  },
  {
    path: "/users/approved",
    exact: true,
    main: () => <ApprovedUser />,
  },
  {
    path: "/users/rejected/:userID",
    exact: true,
    main: () => <RejectedUserDetails />,
  },
  {
    path: "/users/rejected",
    exact: true,
    main: () => <RejectedUser />,
  },
  {
    path: "/deactive-users",
    exact: true,
    main: () => <DeactiveUser />,
  },
  {
    path: "/user-package",
    exact: true,
    main: () => <UserPackage />,
  },
  {
    path: "/subscription",
    exact: true,
    main: () => <Subscriptionlist />,
  },
  {
    path: "/offers",
    exact: true,
    main: () => <OfferList />,
  },
  {
    path: "/all-notifications",
    exact: true,
    main: () => <AllNotifications />,
  },
  {
    path: "/notifications",
    exact: true,
    main: () => <NotificationList />,
  },
  {
    path: "/support-form",
    exact: true,
    main: () => <SupportFormList />,
  },
  {
    path: "/user-queries",
    exact: true,
    main: () => <ManageUserQueries />,
  },
  {
    path: "/transaction",
    exact: true,
    main: () => <TransactionList />,
  },
  {
    path: "/report-revenue",
    exact: true,
    main: () => <RevenueGenerated />,
  },
  {
    path: "/report/subscribed-users",
    exact: true,
    main: () => <SubscribedUsers />,
  },
  {
    path: "/revenue",
    exact: true,
    main: () => <RevenueList />,
  },

  {
    path: "/user-limits",
    exact: true,
    main: () => <UserLimits />,
  },
  {
    path: "/settings/change-password",
    exact: true,
    main: () => <ChangePassword />,
  },
  {
    path: "/content-management/about-us",
    exact: true,
    main: () => <AboutUs />,
  },
  {
    path: "/content-management/app-guide",
    exact: true,
    main: () => <AppGuide />,
  },
  {
    path: "/content-management/faqs",
    exact: true,
    main: () => <FAQs />,
  },
  {
    path: "/content-management/add-update-faq",
    exact: true,
    main: () => <AddUpdateFAQs />,
  },
  {
    path: "/content-management/banners",
    exact: true,
    main: () => <BannerList />,
  },
  {
    path: "/content-management/success-story",
    exact: true,
    main: () => <SuccessList />,
  },
  {
    path: "/content-management/contact-us",
    exact: true,
    main: () => <ContactUs />,
  },
  {
    path: "/content-management/terms-conditions",
    exact: true,
    main: () => <TermsNconditions />,
  },
  {
    path: "/content-management/privacy-policy",
    exact: true,
    main: () => <PrivacyPolicy />,
  },
  {
    path: "/others/course",
    exact: true,
    main: () => <CourseList />,
  },
  {
    path: "/others/specialization",
    exact: true,
    main: () => <SpecializationList />,
  },
  {
    path: "/others/gothra",
    exact: true,
    main: () => <GothraList />,
  },
  {
    path: "/others/devak",
    exact: true,
    main: () => <DevakList />,
  },
  {
    path: "/others/occupation",
    exact: true,
    main: () => <OccupationList />,
  },
];

export default routes;
