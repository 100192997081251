import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { cms, fetchFAQs } from "../slice";
import { updateFAQs } from "../../api/cms";
import SimpleBackdrop from "../../components/backdrop";
import { CircularProgress } from "@mui/material";
import JoditEditor from 'jodit-react';
import { useLocation, useHistory } from 'react-router-dom'
import { getFaqDetails } from "../slice";



const AddUpdateFAQs = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const dataFaq = useSelector((state) => state.faqDetail)
    const status = useSelector((state) => state.status);
    const faqs = useSelector((state) => state.cms);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([{ title: "", description: "" }]);
    const id = location?.state?.params
    const editor = useRef(null);
    const [content, setContent] = useState(dataFaq?.description);

    useEffect(() => {
        if (faqs?.length) {
            setData([...faqs]);
        }
    }, [faqs]);
    useEffect(() => {
        dispatch(getFaqDetails({ id }))
    }, [dispatch,])

    const handleBack = () => {
        history.push("/content-management/faqs")
    }
    console.log("new", id)

    const formik = useFormik({
        initialValues: {
            title: id ? dataFaq?.title : "",
            description: id? dataFaq?.description : "",
            _id: "",
        },
        enableReinitialize: true,
        // validationSchema: Yup.object({
        //     title: Yup.string().required(" *Required"),
        //     description: Yup.string().required(" *Required"),
        // }),
        onSubmit: (values, { resetForm }) => {
            setLoading(true);
            let payload = {
                title: values?.title,
                description: content,
            };

            if (id) {
                payload.faq_id = id;
            }

            updateFAQs(payload).then((response) => {
                enqueueSnackbar(response?.message);
                // if (response?.error_code === 0) {
                //   resetForm({});
                // }
                history.push("/content-management/faqs")
                dispatch(fetchFAQs({}));
                setLoading(false);
            });
        },
    });

    const handleAddMore = () => {
        const prevList = data;
        const length = prevList.length - 1;
        if (prevList[length].title && prevList[length].description) {
            setData([...prevList, { title: "", description: "" }]);
        }
    };

    // const handleRemove = (index) => {
    //   const prevList = formik.values.faqs;
    //   let newList = [];
    //   newList = prevList.filter((el, i) => i !== index);
    //   if (newList.length === 0) {
    //     newList = [{ title: "", description: "" }];
    //   }
    //   formik.setFieldValue("faqs", newList);
    // };

    const handleEdit = (element) => {
        formik.setFieldValue("title", element.title);
        formik.setFieldValue("description", element.description);
        formik.setFieldValue("_id", element._id);
    };

    useEffect(() => {
        return () => dispatch(cms(null));
    }, [dispatch]);

    return (
        <div className="m-main-content">
            <div className="user_package_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <img src="images/back.png" alt="package-img" onClick={handleBack} />
                            <div className="user_package_content">
                                <div className="user_head_img">
                                    <img src="images/FAQs icon.png" alt="package-img" />
                                </div>
                                <div className="manage_salary">
                                    <h4 className="manage_txt">
                                        {id ? "Edit Frequently asked question" : "Add Frequently asked question"}
                                    </h4>
                                </div>

                                <form
                                    className="user_package_content"
                                    onSubmit={formik.handleSubmit}
                                >
                                    <div className="min-package-area subs_area" >
                                        <div className="package_main">
                                            <p className="pact_txt">
                                                Question
                                                {formik.values._id &&
                                                    formik.touched.title &&
                                                    formik.errors.title ? (
                                                    <span className="error">
                                                        {formik.errors.title}
                                                    </span>
                                                ) : null}
                                            </p>
                                            <input
                                                type="text"
                                                className="form-control shadow-box p-2"
                                                placeholder="Describe here..."
                                                value={

                                                    formik.values.title

                                                }
                                                onChange={(e) =>
                                                    formik.setFieldValue("title", e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="package_main">
                                            <p className="pact_txt">
                                                Answer
                                                {
                                                    formik.touched.description &&
                                                        formik.errors.description ? (
                                                        <span className="error">
                                                            {formik.errors.description}
                                                        </span>
                                                    ) : null}
                                            </p>
                                            {/* <textarea
                          type="text"
                          className="form-control shadow-box p-2"
                          placeholder="Describe here..."
                          rows="5"
                          value={
                            formik.values.description
                              
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              "description",
                              e.target.value
                            )
                          }
                        /> */}
                                            <JoditEditor
                                                ref={editor}
                                                value={content}
                                                tabIndex={1}
                                                onBlur={newContent => setContent(newContent)}
                                                {...formik.getFieldProps("description")}
                                                onChange={newContent => setContent(newContent)}
                                            />
                                        </div>
                                    </div>
                                    <div className="button_area">
                                        <button
                                            type="submit"
                                            className="save_btn"
                                            disabled={status === "loading" || loading}
                                        >
                                            {loading ? <CircularProgress color="inherit" /> : "Save"}
                                        </button>
                                    </div>
                                </form>
                                {/* <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <button
                    type="button"
                    className="AddtoList"
                    onClick={handleAddMore}
                    style={{ background: "none", border: "none" }}
                  >
                    + Add More
                  </button>
                </div>
                <div className="button_area">
                  <button
                    type="submit"
                    className="save_btn"
                    disabled={status === "loading" || loading}
                  >
                    {loading ? <CircularProgress color="inherit" /> : "Save"}
                  </button>
                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
        </div>
    );
};

export default AddUpdateFAQs;
