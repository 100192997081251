import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormik } from 'formik'
import * as Yup from "yup";
import Age from "./age";
import Maritial from "./maritial";
import Height from "./height";
import GovernmentClass from "./governmentClass";
import Cookies from 'universal-cookie'
import { higherEducation, occupation } from "../../slice";
import HighestEducation from "./highestEducation";
import Course from "./course";
import Specialization from "./specialization";
import { useDispatch } from "react-redux";
import { getSpecializationList } from "../../slice";
import { getCourseList } from "../../slice";
import { getHigherEducation } from "../../slice";
import EmploymentType from "./employmentType";
import { getEmployment } from "../../slice";
import Occupations from "./occupation";
import FamilyIncome from "./familyIncome";
import Complexion from "./complexion";
import FamilyMember from "./familyMember";
import PartnerPackage from "./partnerPackage";
import { getUsersList } from "../../slice";
import AdvanceCriteria from "./advanceCriteria";
import ManglikStatus from "./manglikStatus";
import OccupationCountry from "./occupationCountry";
import OccupationState from "./occupationState";
import OccupationCity from "./occupationCity";
import NativeCountry from "./nativeCountry";
import NativeState from "./nativeState";
import NativeCity from "./nativeCity";

const MINPACKAGE = 2000000;
const MAXPACKAGE = 20000000;


const MINAGE = 18;
const MAXAGE = 60;

const UserSearch = ({ isOpen, toggle, setSearchText }) => {
    const dispatch = useDispatch();
    const cookie = new Cookies();
    const [age, setAge] = useState(true)
    const [height, setHeight] = useState(false)
    const [maritial, setMaritial] = useState(false)
    const [marriage, setMarriage] = useState(false)
    const [highestEducation, setHighestEucation] = useState(false)
    const [certificationCourse, setCertificationCourse] = useState(false)
    const [specialization, setSpecialization] = useState(false)
    const [emplyomentType, setEmploymentType] = useState(false)
    const [governmentClass, setGovernmentClass] = useState(false)
    const [Occupation, setOccupation] = useState(false)
    const [occupationCountry, setOccupationCountry] = useState(false)
    const [occupationState, setOccupationState] = useState(false)
    const [occupationCity, setOccupationCity] = useState(false)
    const [partnerPackage, setPartnerPackage] = useState(false)
    const [familyIncome, setFamilyIncome] = useState(false)
    const [complexion, setComplexion] = useState(false)
    const [familyMember, setFamilyMember] = useState(false)
    const [manglik, setManglik] = useState(false)
    const [advanceSearch, setAdvanceSearch] = useState(false)
    const [valuePackage, setValuePackage] = useState([MINPACKAGE, MAXPACKAGE]);
    const [valueAge, setValueAge] = useState([MINAGE, MAXAGE]);
    const [checkOwnhouse, setCheckOwnhouse] = useState()
    const [checekAbrod, setCheckAbroad] = useState()
    const [nativeCountry, setNativeCountry] = useState(false)
    const [nativeState, setNativeState] = useState(false)
    const [nativeCity, setNativeCity] = useState(false)

    const [courseValue, setCourseValue] = useState("")
    const [specializationValues, setSpecializationValue] = useState("")
    const [employmentValues, setEmploymentValue] = useState("")
    const [familyIncomeValues, setFamilyIncomeValues] = useState("")
    const [complexionValue, setComplexionValue] = useState("")
    const [heightFrom, setHeightFrom] = useState("")
    const [heightTo, setHeightTo] = useState("")
    const [father, setFather] = useState("")
    const [mother, setMother] = useState("")
    const [sister, setSister] = useState("")
    const [brother, setBrother] = useState("")
    const [marriageValue, setMarriageValue] = useState("")

    const [checkedItemsCourse, setCheckedItemsCourse] = useState([]);
    const [checkedItemsSpecialization, setCheckedItemsSpecialization] = useState([]);
    const [checkedItemsHigherEducation, setCheckedItemsHigherEducation] = useState([]);
    const [checkedItemsEmployment, setCheckedItemsEmployment] = useState([]);
    const [checkedItemsOccupation, setCheckedItemsOccupation] = useState([]);
    const [checkedItemsFamilyIncome, setCheckedItemsFamilyIncome] = useState([]);
    const [checkedGovernmentValues, setCheckedGovernmentValues] = useState([]);
    const [checkedManglikValues, setCheckedManglikValues] = useState([]);
    const [checkedOccupationCountryValues, setCheckedOccupationCountryValues] = useState([]);
    const [checkedOccupationStateValues, setCheckedOccupationStateValues] = useState([]);
    const [checkedOccupationCityValues, setCheckedOccupationCityValues] = useState([]);
    const [checkedNativeCountryValues, setCheckedNativeCountryValues] = useState([]);
    const [checkedNativeStateValues, setCheckedNativeStateValues] = useState([]);
    const [checkedNativeCityValues, setCheckedNativeCityValues] = useState([]);
    const [stateProvinces, setStateProvinces] = useState([]);
    const [stateProvincesNative, setStateProvincesNative] = useState([]);


    const handleSearch = () => {
        setSearchText("Advance Search Option Applied")
        cookie.set("search", "Advance Search Option Applied")

        cookie.set("age_from", valueAge[0])
        cookie.set("age_to", valueAge[1])
        cookie.set("have_father", father)
        cookie.set("have_mother", mother)
        cookie.set("have_brother", brother)
        cookie.set("have_sister", sister)
        cookie.set("complexion", complexionValue)
        cookie.set("ready_to_abroad", checekAbrod)
        cookie.set("own_house_occupation", checkOwnhouse)
        cookie.set("user_merital_status", marriageValue)
        cookie.set("employment_type", employmentValues)
        cookie.set("class", checkedGovernmentValues)
        cookie.set("occupation", checkedItemsOccupation)
        cookie.set("user_manglik_status", checkedManglikValues)
        cookie.set("occupation_country", checkedOccupationCountryValues)
        cookie.set("occupation_state", checkedOccupationStateValues)
        cookie.set("occupation_city", checkedOccupationCityValues)
        cookie.set("user_country", checkedNativeCountryValues)
        cookie.set("user_state", checkedNativeStateValues)
        cookie.set("user_city", checkedNativeCityValues)
        cookie.set("heighest_education", checkedItemsHigherEducation)
        cookie.set("stream", checkedItemsCourse)
        cookie.set("specialization", checkedItemsSpecialization)
        cookie.set("user_height_from", heightFrom)
        cookie.set("user_height_to", heightTo)









        dispatch(getUsersList({ tab_type: "approved", page: 1, age_from: valueAge[0] > 18 ? valueAge[0] : "", age_to: valueAge[1] > 18 ? valueAge[1] : "", heighest_education: checkedItemsHigherEducation?.length > 0 ? checkedItemsHigherEducation.toString() : "", stream: checkedItemsCourse?.length > 0 ? checkedItemsCourse?.toString() : "", specialization: checkedItemsSpecialization?.length > 0 ? checkedItemsSpecialization?.toString() : "", user_height_from: heightFrom ? heightFrom : "", user_height_to: heightTo ? heightTo : "", have_father: father, have_mother: mother, have_brother: brother, have_sister: sister, complexion: complexionValue ? complexionValue : "", ready_to_abroad: checekAbrod === true ? "Yes" : checekAbrod === false ? "No" : "", own_house_occupation: checkOwnhouse === true ? "Yes" : checkOwnhouse === false ? "No" : "", user_merital_status: marriageValue ? marriageValue : "", employment_type: employmentValues?.length > 0 ? employmentValues?.toString() : "", class: checkedGovernmentValues?.length > 0 ? checkedGovernmentValues?.toString() : "", occupation: checkedItemsOccupation?.length > 0 ? checkedItemsOccupation?.toString() : "", user_manglik_status: checkedManglikValues?.length > 0 ? checkedManglikValues?.toString() : "", occupation_country: checkedOccupationCountryValues?.length > 0 ? checkedOccupationCountryValues?.toString() : "", occupation_state: checkedOccupationStateValues?.length > 0 ? checkedOccupationStateValues?.toString() : "", occupation_city: checkedOccupationCityValues?.length > 0 ? checkedOccupationCityValues?.toString() : "", user_country: checkedNativeCountryValues?.length > 0 ? checkedNativeCountryValues?.toString() : "", user_state: checkedNativeStateValues?.length > 0 ? checkedNativeStateValues?.toString() : "", user_city: checkedNativeCityValues?.length > 0 ? checkedNativeCityValues?.toString() : "" }))
        toggle()
    }


    const handleClearSearch = () => {
        setSearchText("")
        cookie.remove("search")
        cookie.remove("age_to")
        cookie.remove("age_from")
        cookie.remove("have_father")
        cookie.remove("have_mother")
        cookie.remove("have_brother")
        cookie.remove("have_sister")
        cookie.remove("complexion")
        cookie.remove("ready_to_abroad")
        cookie.remove("own_house_occupation")
        cookie.remove("user_merital_status")
        cookie.remove("employment_type")
        cookie.remove("class")
        cookie.remove("occupation")
        cookie.remove("user_manglik_status")
        cookie.remove("occupation_country")
        cookie.remove("occupation_state")
        cookie.remove("occupation_city")
        cookie.remove("user_country")
        cookie.remove("user_state")
        cookie.remove("user_city")
        cookie.remove("heighest_education")
        cookie.remove("stream")
        cookie.remove("specialization")
        cookie.remove("user_height_from")
        cookie.remove("user_height_to")

        dispatch(getUsersList({ tab_type: "approved", page: 1 }))
        setHeightTo()
        setHeightFrom()
        toggle()
        window.location.reload()
    }

    useEffect(() => {
        if (checkedItemsCourse?.length === 0) {
            dispatch(getCourseList({ higher_education: courseValue }))
        }
    }, [checkedItemsHigherEducation])

    useEffect(() => {
        if (checkedItemsSpecialization?.length === 0) {
            dispatch(getSpecializationList({ course: specializationValues }))
        }
    }, [checkedItemsCourse])

    useEffect(() => {
        if (checkedItemsHigherEducation?.length === 0) {
            dispatch(getHigherEducation())
        }
    }, [])
    useEffect(() => {
        if (checkedItemsEmployment?.length === 0) {
            dispatch(getEmployment({ employment_type: employmentValues }))
        }
    }, [checkedItemsEmployment])


    const handleAge = () => {
        setAge(true)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setHeight(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
        setFamilyIncome(false)
    }
    const handleHeight = () => {
        setAge(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setHeight(true)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleMaritial = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(true)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleHighestEducation = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(true)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }

    const handleCertification = () => {
        setHeight(false)
        setManglik(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(true)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleSpecialization = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setAge(false)
        setFamilyIncome(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(true)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleEmployment = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setAge(false)
        setFamilyIncome(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(true)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleGovernment = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setAge(false)
        setFamilyIncome(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(true)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleOccupation = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(true)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleOccupationCountry = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(true)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleOccupationCity = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(true)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleOccupationState = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(true)
        setPartnerPackage(false)
    }
    const handlePartnerPackage = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(true)
    }
    const handleFamilyIncome = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(true)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleComplexion = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(true)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }

    const handleAdvanceSearch = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(true)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleMarriage = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(true)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }

    const handleManglik = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(true)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }

    const handleNativeCountry = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(false)
        setNativeCountry(true)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }
    const handleNativeState = () => {
        setHeight(false)
        setNativeCity(false)
        setNativeState(true)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }

    const handleNativeCity = () => {
        setHeight(false)
        setNativeCity(true)
        setNativeState(false)
        setNativeCountry(false)
        setManglik(false)
        setMarriage(false)
        setAdvanceSearch(false)
        setComplexion(false)
        setFamilyIncome(false)
        setAge(false)
        setMaritial(false)
        setHighestEucation(false)
        setCertificationCourse(false)
        setSpecialization(false)
        setEmploymentType(false)
        setGovernmentClass(false)
        setOccupation(false)
        setOccupationCountry(false)
        setOccupationCity(false)
        setOccupationState(false)
        setPartnerPackage(false)
    }

    useEffect(() => {
        document.body.classList.toggle("modal-open", isOpen);
    }, [isOpen]);

    const formik = useFormik({
        initialValues: {
            title: "",
            body: "",
            users: "",
            notifyAllUsers: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            title: Yup.string().required("*Required"),
            body: Yup.string().required("*Required"),
        }),

        onSubmit: (values, { resetForm }) => {
            const { title, body, notifyAllUsers } = values;
            const payload = {
                title: title,
                body: body,
                users: notifyAllUsers,
                send_to_all_user: "No",

            };
            // addNotification(payload).then((response) => {
            //     // setSaving(false);
            //     enqueueSnackbar(response?.message);
            //     if (response?.error_code === 0) {
            //         resetForm({});
            //         // dispatch(getNotification({}));
            //         // handleShow()
            //     }
            // });

        },
    });


    return (
        <>
            <div
                className={isOpen ? "modal fade show" : "modal fade"}
                id="delUserDetails"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Search By Criteria</h4>
                            <p className="modal-title mx-5" style={{ color: "#EC008C", cursor: "pointer" }} onClick={handleClearSearch}>Clear All Search</p>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={toggle}
                            >
                                &times;
                            </button>
                        </div>

                        <div className="user_match_menu">
                            <ul className="user_match_info">
                                <li className="user_match_list" onClick={handleAge}>
                                    <p className={age ? "match_links" : ""}>
                                        Partner Age
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleHeight}>
                                    <p className={height ? "match_links" : ""}>
                                        Partner Height
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleMarriage}>
                                    <p className={marriage ? "match_links" : ""}>
                                        Marital Status
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleHighestEducation}>
                                    <p className={highestEducation ? "match_links" : ""}>
                                        Highest Education
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleCertification}>
                                    <p className={certificationCourse ? "match_links" : ""}>
                                        Certification Course
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleSpecialization}>
                                    <p className={specialization ? "match_links" : ""}>
                                        Specialization
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleEmployment}>
                                    <p className={emplyomentType ? "match_links" : ""}>
                                        Employment Type
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleGovernment}>
                                    <p className={governmentClass ? "match_links" : ""} >
                                        Government Service Class
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleOccupation}>
                                    <p className={Occupation ? "match_links" : ""}>
                                        Occupation
                                    </p>
                                </li>


                                <li className="user_match_list" onClick={handleOccupationCountry}>
                                    <p className={occupationCountry ? "match_links" : ""}>
                                        Occupation Country
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleOccupationState}>
                                    <p className={occupationState ? "match_links" : ""}>
                                        Occupation State
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleOccupationCity}>
                                    <p className={occupationCity ? "match_links" : ""}>
                                        Occupation City
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handlePartnerPackage}>
                                    <p className={partnerPackage ? "match_links" : ""}>
                                        Partner Package
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleNativeCountry}>
                                    <p className={nativeCountry ? "match_links" : ""}>
                                        Native Country
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleNativeState}>
                                    <p className={nativeState ? "match_links" : ""}>
                                        Native State
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleNativeCity} >
                                    <p className={nativeCity ? "match_links" : ""}>
                                        Native City
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleFamilyIncome}>
                                    <p className={familyIncome ? "match_links" : ""}>
                                        Family's Monthly Income
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleManglik}>
                                    <p className={manglik ? "match_links" : ""}>
                                        Manglik Status
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleComplexion}>
                                    <p className={complexion ? "match_links" : ""}>
                                        Complexion
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleAdvanceSearch}>
                                    <p className={advanceSearch ? "match_links" : ""}>
                                        Advance Criterias
                                    </p>
                                </li>
                                <li className="user_match_list" onClick={handleMaritial}>
                                    <p className={maritial ? "match_links" : ""}>
                                        Family Member's
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="">
                            {age ? <Age value={valueAge} MIN={MINAGE} MAX={MAXAGE} setValue={setValueAge} /> : ""}
                            {/* {maritial ? <Maritial /> : ""} */}
                            {height ? <Height setHeightFrom={setHeightFrom} setHeightTo={setHeightTo} /> : ""}
                            {governmentClass ? <GovernmentClass setCheckedItems={setCheckedGovernmentValues} checkedGovernmentValues={checkedGovernmentValues} checkedItems={checkedGovernmentValues} /> : ""}
                            {highestEducation ? <HighestEducation setCourseValue={setCourseValue} setCheckedItems={setCheckedItemsHigherEducation} checkedItems={checkedItemsHigherEducation} /> : ""}
                            {certificationCourse ? <Course courseValue={courseValue} setSpecializationValue={setSpecializationValue} setCheckedItems={setCheckedItemsCourse} checkedItems={checkedItemsCourse} /> : ""}
                            {specialization ? <Specialization specializationValues={specializationValues} setCheckedItems={setCheckedItemsSpecialization} checkedItems={checkedItemsSpecialization} /> : ""}
                            {emplyomentType ? <EmploymentType setCheckedItems={setCheckedItemsEmployment} checkedItems={checkedItemsEmployment} setEmploymentValue={setEmploymentValue} /> : ""}
                            {Occupation ? <Occupations checkedItems={checkedItemsOccupation} setCheckedItems={setCheckedItemsOccupation} /> : ""}

                            {occupationCountry ? <OccupationCountry checkedItems={checkedOccupationCountryValues} setCheckedItems={setCheckedOccupationCountryValues} setStateProvinces={setStateProvinces} stateProvinces={stateProvinces} /> : ""}

                            {occupationState ? <OccupationState checkedItems={checkedOccupationStateValues} setCheckedItems={setCheckedOccupationStateValues} setStateProvinces={setStateProvinces} stateProvinces={stateProvinces} /> : ""}

                            {occupationCity ? <OccupationCity checkedItems={checkedOccupationCityValues} setCheckedItems={setCheckedOccupationCityValues} setStateProvinces={setStateProvincesNative} city={checkedOccupationStateValues} /> : ""}

                            {nativeCountry ? <NativeCountry checkedItems={checkedNativeCountryValues} setCheckedItems={setCheckedNativeCountryValues} setStateProvinces={setStateProvincesNative} stateProvinces={stateProvincesNative} /> : ""}


                            {nativeState ? <NativeState checkedItems={checkedNativeStateValues} setCheckedItems={setCheckedNativeStateValues} setStateProvinces={setStateProvincesNative} stateProvinces={stateProvincesNative} /> : ""}

                            {nativeCity ? <NativeCity checkedItems={checkedNativeCityValues} setCheckedItems={setCheckedNativeCityValues} setStateProvinces={setStateProvincesNative} city={checkedNativeStateValues} /> : ""}

                            {familyIncome ? <FamilyIncome checkedItems={checkedItemsFamilyIncome} setCheckedItems={setCheckedItemsFamilyIncome} /> : ""}
                            {complexion ? <Complexion setComplexionValue={setComplexionValue} /> : ""}

                            {maritial ? <FamilyMember setFather={setFather} setMother={setMother} setBrother={setBrother} setSister={setSister} /> : ""}
                            {partnerPackage ? <PartnerPackage MIN={MINPACKAGE} MAX={MAXPACKAGE} setValue={setValuePackage} value={valuePackage} /> : ""}
                            {advanceSearch ? <AdvanceCriteria setCheckAbroad={setCheckAbroad} setCheckOwnhouse={setCheckOwnhouse} /> : ""}
                            {marriage ? <Maritial setMarriageValue={setMarriageValue} /> : ""}
                            {manglik ? <ManglikStatus setCheckedItems={setCheckedManglikValues} checkedItems={checkedManglikValues} /> : ""}
                        </div>

                        <div className="button_area text-center">
                            <button type="submit" className="save_btn" onClick={handleSearch}>Search</button>
                        </div>
                    </div>
                </div>
            </div>

            {isOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

UserSearch.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    userID: PropTypes.string.isRequired,
    page: PropTypes.number,
    userType: PropTypes.string.isRequired,
};

export default UserSearch;
