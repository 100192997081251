import React, { useState, useEffect } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import jwtDecode from "jwt-decode";
import OtpInput from "react-otp-input";

import { resendOTP } from "../../../api/accounts";
import { idle, loading, verify } from "../../slice";
import { useDispatch, useSelector } from "react-redux";
import { setCookie } from "../../../utils";

const OTPVerificationPage = () => {
  const { replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);

  const [isBlocking, setIsBlocking] = useState(true);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if (isBlocking) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [isBlocking]);

  const handleResend = () => {
    resendOTP({}).then((response) => {
      setTimeLeft(60);
      enqueueSnackbar(response?.message);
    });
  };

  const handleSubmit = (event) => {
    dispatch(loading());
    event.preventDefault();

    dispatch(verify({ otp })).then((response) => {
      if (response?.error_code === 0) {
        const token = response?.verificationToken;
        const decodedToken = jwtDecode(token);
        setCookie("clszdi", token, decodedToken?.exp);
        replace(`/reset-password`);
        setIsBlocking(false);
      } else {
        enqueueSnackbar(response?.message);
      }
      dispatch(idle());
    });
  };

  return (
    <div id="loginArea">
      <Prompt
        when={isBlocking}
        message={() => `Are you sure you want leave this page ?`}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="login_left_area">
              <div className="lft_content">
                <img
                  src="images/transparent _logo.png"
                  alt="logo"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="login_main">
              <div className="row">
                <div className="col-md-6 offset-md-4">
                  <div className="login_right_area">
                    <div className="lon_in_content">
                      <div className="log_in_head">
                        <img
                          src="images/otp.png"
                          alt="Forgot"
                          className="img-fluid"
                        />
                        <h1 className="login_heading-txt forgot_head_txt">
                          OTP Verification
                        </h1>
                      </div>
                      <div className="registration_txt">
                        <p>
                          Please enter 6 digits code, which has been sent on
                          your registered email address
                        </p>
                      </div>
                      <form className="login_form" onSubmit={handleSubmit}>
                        <div className="form_input_otp form-group">
                          <OtpInput
                            className="passcode-wrapper"
                            value={otp}
                            onChange={(value) => setOtp(value)}
                            numInputs={6}
                            // separator={<span>-</span>}
                             shouldAutoFocus
                            isInputNum
                            // isInputSecure
                            inputStyle={{
                              width: "2em",
                            }}
                          />
                        </div>
                        {timeLeft ? (
                          <div className="opt_time">
                            <span className="o_timing">{timeLeft}</span>
                          </div>
                        ) : null}

                        <div className="log_in_btn">
                          <button
                            type="submit"
                            className="login_btn"
                            disabled={otp.length !== 6 || status === "loading"}
                          >
                            {status === "loading" ? (
                              <CircularProgress color="inherit" />
                            ) : (
                              "Verify"
                            )}
                          </button>
                        </div>
                        <div className="resend_code">
                          <button
                            type="button"
                            disabled={timeLeft}
                            onClick={handleResend}
                            style={{ background: "none", border: "none" }}
                          >
                            Resend Code
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerificationPage;
