import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteStory } from "../../../api/story";
import { getStory } from "../../slice";
import { loading, idle } from "../../slice";


const DeleteStory = ({ isOpen, toggle, id, userID, page, userType }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { goBack } = useHistory();
    const limit = useSelector((state) => state?.limit);

    useEffect(() => {
        document.body.classList.toggle("modal-open", isOpen);
    }, [isOpen]);

    const handleDelete = () => {
        if (id) {
            dispatch(loading());
            const payload = {
                id: id,
            };

            deleteStory(payload).then((response) => {
                dispatch(idle());

                dispatch(getStory({}));
                enqueueSnackbar(response.message);
                toggle()
            });
        }
    };

    return (
        <>
            <div
                className={isOpen ? "modal fade show" : "modal fade"}
                id="delUserDetails"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Delete Success Story</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={toggle}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="del_user">
                                Are you sure, you want to delete this story?
                            </h4>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="Del_btn"
                                data-dismiss="modal"
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="Can_btn"
                                data-dismiss="modal"
                                onClick={toggle}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

// HandleDelete.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   toggle: PropTypes.func.isRequired,
//   userID: PropTypes.string.isRequired,
//   page: PropTypes.number,
//   userType: PropTypes.string.isRequired,
// };

export default DeleteStory;
