import { getData, postEncodedData } from ".";

export async function updateAboutUs(params) {
  return postEncodedData("/add_update_about_us", params).then((data) => {
    return data;
  });
}

export async function updateAppGuide(params) {
  return postEncodedData("/add_update_app_guide", params).then((data) => {
    return data;
  });
}

export async function updateContactUs(params) {
  return postEncodedData("/add_update_contact_us", params).then((data) => {
    return data;
  });
}

export async function getAboutUs(params) {
  return getData("/get_about_us", params).then((data) => {
    return data;
  });
}
export async function getAppGuide(params) {
  return getData("/get_app_guide", params).then((data) => {
    return data;
  });
}

export async function getContactUs(params) {
  return getData("/get_contact_us", params).then((data) => {
    return data;
  });
}

export async function updatePrivacyPolicy(params) {
  return postEncodedData("/add_update_private_policies", params).then(
    (data) => {
      return data;
    }
  );
}

export async function getPrivacyPolicy(params) {
  return getData("/get_private_policies", params).then((data) => {
    return data;
  });
}

export async function updateTnC(params) {
  return postEncodedData("/add_update_term_and_condition", params).then(
    (data) => {
      return data;
    }
  );
}

export async function getTnC(params) {
  return getData("/get_term_and_condition", params).then((data) => {
    return data;
  });
}

export async function updateFAQs(params) {
  return postEncodedData("/add_update_faq", params).then((data) => {
    return data;
  });
}

export async function getFAQs(params) {
  return getData("/get_faqs", params).then((data) => {
    return data;
  });
}
