import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getDashboardData } from "../slice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Dashboard = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const count = useSelector((state) => state.usersCount);
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state?.dashboard);
  // const [date, setDate] = useState(new Date());
  const [month, setMonth] = useState("4");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  // const [currentMonth, setCurrentMonth] = useState()
  let currentMonth;
  const d = new Date();
  currentMonth = d.getMonth();

  useEffect(() => {
    dispatch(getDashboardData({ month: "" }));
  }, [dispatch]);

  const handleYear = (e) => {
    dispatch(getDashboardData({ month: month, year: e.target.value }));
  };
  const handleMonth = (e) => {
    setMonth(e.target.value);
    dispatch(getDashboardData({ month: e.target.value }));
  };
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    dispatch(getDashboardData({ start_date: e.target.value }));
  };
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    dispatch(
      getDashboardData({ start_date: startDate, end_date: e.target.value })
    );
  };

  return (
    <div className="m-main-content">
      <div className="brand_main ">
        <div className="container-fluid">
          <div className="row">
            <div className="colsm-12 col-lg-12 col-md-12 my-4 ">
              <div className="search_area">
                <div className="d-flex">
                  {/* <DatePicker
                                        selectsStart
                                        selected={startDate}
                                        onChange={date => handleStartDate(date)}
                                        startDate={startDate}

                                    />
                                    <DatePicker
                                        selectsEnd
                                        selected={endDate}
                                        onChange={date => handleEndDate(date)}
                                    /> */}
                  {/* <div>
                                        <label className="text_labell">Start Date</label>
                                        <input type="date" className="form-selectt mx-4" id="startDate" name="startDate" onChange={handleStartDate}  placeholder="start date"/>
                                    </div>
                                    <div>
                                        <label className="text_labell">End Date</label>
                                        <input type="date" className="form-selectt mx-4" id="endDate" name="endDate" onChange={handleEndDate} />
                                    </div> */}
                </div>
                <div className="d-flex">
                  <select
                    className="form-select mx-3"
                    aria-label="Default select example"
                    onChange={(e) => handleMonth(e)}
                  >
                    <option value="">All</option>
                    <option value="1">Jan</option>
                    <option value="2">Feb</option>
                    <option value="3">Mar</option>
                    <option value="4">Apr</option>
                    <option value="5">May</option>
                    <option value="6">Jun</option>
                    <option value="7">Jul</option>
                    <option value="8">Aug</option>
                    <option value="9">Sep</option>
                    <option value="10">Oct</option>
                    <option value="11">Nov</option>
                    <option value="12">Dec</option>
                  </select>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    defaultValue="2024"
                    onChange={(e) => handleYear(e)}
                  >
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </select>
                  {/* <button type="submit" className="savee_btnn mx-2 mt-2">Search</button> */}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-4 mt-5 ">
              <div className="brand_head_data d-block">
                <div className="brands_total w-100">
                  <img src="images/Manage-Users-white.png" alt="user" />
                  <p className="t_brand_txt">Total Registered Users</p>
                  <h4 className="brand_num">{data?.total_registered_users}</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-4 mt-5 ">
              <div className="brand_head_data d-block">
                <div className="brands_total w-100">
                  <img src="images/Manage-Users-white.png" alt="user" />
                  <p className="t_brand_txt">New Free Members</p>
                  <h4 className="brand_num">{data?.total_free_users}</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-4 mt-5">
              <div className="brand_head_data d-block">
                <div className="brands_total w-100">
                  <img src="images/Manage-Users-white.png" alt="user" />
                  <p className="t_brand_txt">Total Subscribed Users</p>
                  <h4 className="brand_num">{data?.total_subscribed_users}</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-4 ">
              <div className="brand_head_data d-block">
                <div className="brands_total w-100">
                  <img src="images/Deactivated-users-white.png" alt="user" />
                  <p className="t_brand_txt">Total Expired Users</p>
                  <h4 className="brand_num">{data?.total_expired_users}</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-4 ">
              <div className="brand_head_data d-block">
                <div className="brands_total w-100">
                  <img src="images/Earningwhite.png" alt="user" />
                  <p className="t_brand_txt">Total Earnings</p>
                  <h4 className="brand_num">{count?.new_users || "0"}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
