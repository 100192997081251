import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getPackage, idle, loading, userPackage } from "../slice";
import { updatePackage } from "../../api/users";

const UserPackage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const data = useSelector((state) => state.userPackage);

  const [initialValues, setInitialValues] = useState({
    packageType: "male",
    minINRPackage: "",
    maxINRPackage: "",
    minUSDPackage: "",
    maxUSDPackage: "",
  });

  useEffect(() => {
    dispatch(getPackage({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      packageType: Yup.string().required(" *Required"),
      minINRPackage: Yup.number().required(" *Required"),
      maxINRPackage: Yup.number().required(" *Required"),
      minUSDPackage: Yup.number().required(" *Required"),
      maxUSDPackage: Yup.number().required(" *Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loading());
      const {
        packageType,
        minINRPackage,
        maxINRPackage,
        minUSDPackage,
        maxUSDPackage,
      } = values;
      const payload = {
        package_type: packageType,
        rs_min: minINRPackage,
        rs_max: maxINRPackage,
        dollar_min: minUSDPackage,
        dollar_max: maxUSDPackage,
      };

      updatePackage(payload).then((response) => {
        enqueueSnackbar(response?.message);
        // if (response?.error_code === 0) {
        //   resetForm({});
        // }
        dispatch(idle());
      });
    },
  });

  useEffect(() => {
    const type = formik.values.packageType;

    if (type === "male" && data?.[0]?.package_type === "male") {
      setInitialValues({
        packageType: "male",
        minINRPackage: data[0].rs_min || "",
        maxINRPackage: data[0].rs_max || "",
        minUSDPackage: data[0].dollar_min || "",
        maxUSDPackage: data[0].dollar_max || "",
      });
    }
    if (type === "male" && data?.[1]?.package_type === "male") {
      setInitialValues({
        packageType: "male",
        minINRPackage: data[1].rs_min || "",
        maxINRPackage: data[1].rs_max || "",
        minUSDPackage: data[1].dollar_min || "",
        maxUSDPackage: data[1].dollar_max || "",
      });
    }
    if (type === "female" && data?.[0]?.package_type === "female") {
      setInitialValues({
        packageType: "female",
        minINRPackage: data[0].rs_min || "",
        maxINRPackage: data[0].rs_max || "",
        minUSDPackage: data[0].dollar_min || "",
        maxUSDPackage: data[0].dollar_max || "",
      });
    }
    if (type === "female" && data?.[1]?.package_type === "female") {
      setInitialValues({
        packageType: "female",
        minINRPackage: data[1].rs_min || "",
        maxINRPackage: data[1].rs_max || "",
        minUSDPackage: data[1].dollar_min || "",
        maxUSDPackage: data[1].dollar_max || "",
      });
    }
  }, [formik.values.packageType, data]);

  useEffect(() => {
    return () => dispatch(userPackage({}));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="user_package_main">
        <div className="user_head_img">
          <img src="images/annual-package.png" alt="package-img" />
        </div>
        <div /*className="user_package_inner"*/>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <form
                  className="user_package_content"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="manage_salary">
                    <h4 className="manage_txt">
                      Please manage your annual package salary here
                    </h4>
                    <div className="manage_inner">
                      <div className="manage_gender_main">
                        <h4 className="gend_txt">
                          Gender:{" "}
                          <select
                            {...formik.getFieldProps("packageType")}
                            className="g_name"
                          >
                            <option disabled value="">
                              Select Gender
                            </option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </h4>
                        <h4 className="gend_txt">
                          Currency: <span className="g_name">INR and USD</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="min-package-area">
                    <div className="package_main">
                      <p className="pact_txt">
                        Min Package{" "}
                        {formik.touched.minINRPackage &&
                        formik.errors.minINRPackage ? (
                          <span className="error">
                            {formik.errors.minINRPackage}
                          </span>
                        ) : null}
                      </p>
                      <input
                        type="text"
                        className="package_enter"
                        placeholder="Enter"
                        {...formik.getFieldProps("minINRPackage")}
                      />
                      <img src="images/dollar.png" alt="dollar" />
                    </div>
                    <div className="package_main">
                      <p className="pact_txt">
                        Max Package{" "}
                        {formik.touched.maxINRPackage &&
                        formik.errors.maxINRPackage ? (
                          <span className="error">
                            {formik.errors.maxINRPackage}
                          </span>
                        ) : null}
                      </p>
                      <input
                        type="text"
                        className="package_enter"
                        placeholder="Enter"
                        {...formik.getFieldProps("maxINRPackage")}
                      />
                      <img src="images/dollar.png" alt="dollar" />
                    </div>
                  </div>
                  <div className="min-package-area">
                    <div className="package_main">
                      <p className="pact_txt">
                        Min Package{" "}
                        {formik.touched.minUSDPackage &&
                        formik.errors.minUSDPackage ? (
                          <span className="error">
                            {formik.errors.minUSDPackage}
                          </span>
                        ) : null}
                      </p>
                      <input
                        type="text"
                        className="package_enter"
                        placeholder="Enter"
                        {...formik.getFieldProps("minUSDPackage")}
                      />
                      <img src="images/dollar-currency.png" alt="dollar" />
                    </div>
                    <div className="package_main">
                      <p className="pact_txt">
                        Max Package{" "}
                        {formik.touched.maxUSDPackage &&
                        formik.errors.maxUSDPackage ? (
                          <span className="error">
                            {formik.errors.maxUSDPackage}
                          </span>
                        ) : null}
                      </p>
                      <input
                        type="number"
                        className="package_enter"
                        placeholder="Enter"
                        {...formik.getFieldProps("maxUSDPackage")}
                      />
                      <img src="images/dollar-currency.png" alt="dollar" />
                    </div>
                  </div>
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={status === "loading"}
                    >
                      {status === "loading" ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="user_package_content">
                  <div className="manage_salary">
                    <div className="manage_inner">
                      <div className="manage_gender_main">
                        <h4 className="gend_txt">
                          Gender: <span className="g_name">Female</span>
                        </h4>
                        <h4 className="gend_txt">
                          Currency: <span className="g_name">INR and USD</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="min-package-area">
                    <div className="package_main">
                      <p className="pact_txt">
                        Min Package{" "}
                        {formik.touched.amount && formik.errors.amount ? (
                          <span className="error">{formik.errors.amount}</span>
                        ) : null}
                      </p>
                      <input
                        type="text"
                        className="package_enter"
                        placeholder="Enter"
                      />
                      <img src="images/dollar.png" alt="dollar" />
                    </div>
                    <div className="package_main">
                      <p className="pact_txt">
                        Max Package{" "}
                        {formik.touched.amount && formik.errors.amount ? (
                          <span className="error">{formik.errors.amount}</span>
                        ) : null}
                      </p>
                      <input
                        type="text"
                        className="package_enter"
                        placeholder="Enter"
                      />
                      <img src="images/dollar.png" alt="dollar" />
                    </div>
                  </div>
                  <div className="min-package-area">
                    <div className="package_main">
                      <p className="pact_txt">
                        Min Package{" "}
                        {formik.touched.amount && formik.errors.amount ? (
                          <span className="error">{formik.errors.amount}</span>
                        ) : null}
                      </p>
                      <input
                        type="text"
                        className="package_enter"
                        placeholder="Enter"
                      />
                      <img src="images/dollar-currency.png" alt="dollar" />
                    </div>
                    <div className="package_main">
                      <p className="pact_txt">
                        Max Package{" "}
                        {formik.touched.amount && formik.errors.amount ? (
                          <span className="error">{formik.errors.amount}</span>
                        ) : null}
                      </p>
                      <input
                        type="text"
                        className="package_enter"
                        placeholder="Enter"
                      />
                      <img src="images/dollar-currency.png" alt="dollar" />
                    </div>
                  </div>
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={status === "loading"}
                    >
                      {status === "loading" ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default UserPackage;
