import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import {
    getUsersList,
} from "../slice";

import SimpleBackdrop from "../../components/backdrop";
import { useToggle } from "../../app/hooks";
import { getGothra } from "../slice";
import AddDevak from "./addDevak";
import { getDevak } from "../slice";
import DeleteDevak from "./deleteDevak";


const DevakList = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state?.status);
    const limit = useSelector((state) => state?.limit);
    const data = useSelector((state) => state?.devak);
    const count = useSelector((state) => state.usersCount);
    const [activePage, setActivePage] = useState(1);
    const [isOpen, toggle] = useToggle(false);
    const [isOpenDelete, toggleDelete] = useToggle(false);
    const [isOpenEdit, toggleEdit] = useToggle(false)
    const [isOpenStatus, toggleStatus] = useToggle(false)
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [id, setId] = useState("");
    const [status2, setStatus2] = useState("")


    useEffect(() => {
        dispatch(getDevak({ page: page }))
    }, [dispatch, page, limit]);
    console.log("data", data)
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        dispatch(getDevak({ page: pageNumber }))
    }
    console.log("data", data)
    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(
            getUsersList({ tab_type: "deactivated", page: 1, perpage: limit, search })
        );
    };

    console.log(id)
    const handleDelete = (id) => {
        console.log("call")
        setId(id)
        toggleDelete()
    }


    return (
        <div className="m-main-content">
            <div className="brand_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="brand_head_data">
                                <div className="brands_total deactive_user">
                                    {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                                    <p className="t_brand_txt">Total Devak</p>
                                    <h4 className="brand_num">{data?.total_devaks}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end col-md-8">
                            <span className="brand_add mt-5" onClick={toggle}>
                                <img src="images/add.png" alt="add" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brand_table">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="brand_table_inner">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {/* <th scope="col">S.No</th> */}
                                                <th scope="col">Community Name</th>
                                                <th scope="col">Devak Name</th>
                                                <th scope="col">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.devak_list?.length ? (
                                                data?.devak_list?.map((el, i) => (
                                                    <tr key={i}>
                                                                                 {/* <td className="bt-non">{data.current === 1 ? (i + 1) : (data.current - 1) * 10 + (i + 1)}</td> */}
                                                        {/* <td className="bt-non">
                                                            {i+1}
                                                        </td> */}
                                                        <td className="bt-non">
                                                            {el?.community_id?.name}
                                                        </td>


                                                        <td className="bt-non">
                                                            {el?.name}
                                                        </td>

                                                        <td className="bt-non">
                                                            <span className="table-action">

                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggleDelete();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Delete"
                                                                >
                                                                    <img
                                                                        src="images/delete.png"
                                                                        className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} style={{ padding: "10px" }}>
                                                        No Results Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {data?.total_devaks
                                        >= 9 ?
                                        <div className='mt-4'>
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={data?.total_devaks}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                            />
                                        </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleBackdrop open={status === "loading"} />
            {isOpen ? <AddDevak show={isOpen} handleShow={toggle} /> : ""}
            <DeleteDevak
                isOpen={isOpenDelete}
                toggle={toggleDelete}
                id={id}
                page={page}
                userType="deactivated"
            />
            {/* 
            {isOpenEdit ? <AddCourses show={isOpenEdit} handleShow={toggleEdit} id={id} /> : ""}
          */}

        </div>
    );
};

export default DevakList;
