import React from "react";
import { components } from "react-select";

const LoadingText = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <label>Loading...</label>
    </components.DropdownIndicator>
  );
};

export default LoadingText;
