import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";

import SimpleBackdrop from "../../../components/backdrop";
import { getUserDetails, userDetails } from "../../slice";

import UserMatches from "./matches";
import MediaItems from "./MediaItems";
import OtherInfo from "./OtherInfo";
import UserProfile from "./Profile";
import ReportDetails from "./ReportDetails";

const DeactivatedUserDetails = () => {
  const { userID } = useParams();
  const dispatch = useDispatch();
  const { goBack, push } = useHistory();
  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  useEffect(() => {
    return () => dispatch(userDetails(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div
                className="new_user_detail_heading"
                style={{ position: "relative" }}
              >
                <button
                  className="back-arrow"
                  onClick={() => {
                    push("/deactive-users");
                  }}
                >
                  <img src="images/back.png" alt="back" />
                </button>
                <h2 className="new_user_txt">Deactivated User Details</h2>
                {/* <div className="edit-span-button">
                  <span className="position-relative edit-icon-list">
                    <img
                      src="images/edit.png"
                      className="action-view"
                      alt="edit"
                    />
                    <div className="edit-pages">
                      <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                      <Link to={`/users/edit-marital-status/${userID}`}>
                        Personal Details
                      </Link>
                      <Link to={`/users/edit-religion/${userID}`}>
                        Religion Details
                      </Link>
                      <Link to={`/users/body-details/${userID}`}>
                        Body Details
                      </Link>
                      <Link to={`/users/edit-occupation-details/${userID}`}>
                        Professional Details
                      </Link>
                      <Link to={`/users/edit-family-details/${userID}`}>
                        Family Details
                      </Link>
                      <Link to={`/users/edit-lifestyle/${userID}`}>
                        Lifestyle
                      </Link>
                      <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                      <Link to={`/users/edit-horoscope-details/${userID}`}>
                        Horoscope Details
                      </Link>
                      <Link to={`/users/edit-partner-preferences/${userID}`}>
                        My Partner Preferences
                      </Link>
                      <Link to={`/users/about-me/${userID}`}>About Me</Link>
                      <Link to={`/users/my-document/${userID}`}>
                        My Document
                      </Link>
                    </div>
                  </span>
                </div> */}
              </div>
              <UserProfile data={data} />
              {data?.profile_image_video_path?.length ? (
                <MediaItems data={data.profile_images} allData={data} />
              ) : null}
              <div className="a_report_details_main">
                <UserMatches />
              </div>
              <OtherInfo data={data} />
              {data?.report_details && (
                <div className="a_report_details_main">
                  <ReportDetails data={data.report_details} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <SimpleBackdrop open={status === "loading"} />
    </div>
  );
};

export default DeactivatedUserDetails;
