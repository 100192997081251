import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { cms, fetchAboutUs } from "../slice";
import { updateAboutUs } from "../../api/cms";
import SimpleBackdrop from "../../components/backdrop";
import JoditEditor from 'jodit-react';


const AboutUs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const data = useSelector((state) => state.cms);

  const [loading, setLoading] = useState(false);
  const editor = useRef(null);
  const [content, setContent] = useState(data?.description);

  

  useEffect(() => {
    dispatch(fetchAboutUs({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      aboutUs: data?.description || "",
    },
    enableReinitialize: true,
    // validationSchema: Yup.object({
    //   aboutUs: Yup.string().required(" *Required"),
    // }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      let payload = {
        description: content,
      };

      updateAboutUs(payload).then((response) => {
        enqueueSnackbar(response?.message);
        // if (response?.error_code === 0) {
        //   resetForm({});
        // }
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    return () => dispatch(cms(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="user_package_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <form
                className="user_package_content"
                onSubmit={formik.handleSubmit}
              >
                <div className="user_head_img">
                  <img src="images/About Us vector.png" alt="package-img" />
                </div>
                <div className="manage_salary">
                  <h4 className="manage_txt">Manage about us here.</h4>
                </div>
                <div className="min-package-area subs_area">
                  <div className="package_main">
                    <p className="pact_txt">
                      About Us{" "}
                      {formik.touched.aboutUs && formik.errors.aboutUs ? (
                        <span className="error">{formik.errors.aboutUs}</span>
                      ) : null}
                    </p>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      tabIndex={1}
                      onBlur={newContent => setContent(newContent)}
                      {...formik.getFieldProps("aboutUs")}
                      onChange={newContent => setContent(newContent)}
                    />

                  </div>
                </div>
                <div className="button_area">
                  <button
                    type="submit"
                    className="save_btn"
                    disabled={status === "loading" || loading}
                  >
                    {loading ? <CircularProgress color="inherit" /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default AboutUs;
