import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ImgFluid from "../../../../components/image/ImgFluid";
import { getMutuallyLikedProfiles, matchesList } from "../../../slice";

const MutuallyLiked = () => {
  const { userID } = useParams();
  const dispatch = useDispatch();
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state?.matchesList);

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getMutuallyLikedProfiles({ userid: userID, page, perpage: limit })
    );
  }, [dispatch, page, limit, userID]);

  useEffect(() => {
    return () => dispatch(matchesList(null));
  }, [dispatch]);

  function calculateAge(dateOfBirth) {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
  
    // Calculate the difference in years
    const age = currentDate.getFullYear() - birthDate.getFullYear();
  
    // Adjust the age if the birthdate hasn't occurred yet this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
  
    return age;
  }

  return (
    <>
      <div className="user_match_card_main">
        {data?.data?.length ? (
          data.data.map((el) => (
            <div className="match_card_content" key={el._id}>
              <div className="u_card_img">
                <ImgFluid
                  src={data?.profile_image || el.profile_image_video_path}
                  className="c_usr_img"
                />
              </div>
              <div className="u_card_info">
                <p className="mat_percent">
                  <i className="fa fa-heart"></i> {el.t_percentage}% match
                </p>
                <h4 className="m_name">
                  {`${el.first_name} ${el.last_name}`}`,{" "}
                  <span className="m_age">{calculateAge(el?.date_of_birth || "")}</span>
                </h4>
                <div className="m_relegious">
                  <span className="m_hnd">
                    <img
                      src="images/religion-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {el.religion}
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/community-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {el?.caste}
                  </span>
                 
                  <span className="m_hnd">
                    <img
                      src="images/height-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {el?.height}
                    {el?.height_type}
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/salary-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />{" "}
                    {el?.currency}  {el.annual_package}
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/higher-education.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {
                      el?.higher_education
                    }
                  </span>
                  <span className="m_hnd">
                    <img
                      src="images/location-black.png"
                      alt="icon"
                      width=""
                      height=""
                    />
                    {el?.native_location}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No records found</div>
        )}
      </div>
      {data?.pages > data?.current ? (
        <div className="see_all_head">
          <button
            style={{ background: "none", border: "none" }}
            className="see_all_txt"
            onClick={() => setPage(data.current + 1)}
          >
            See More...
          </button>
        </div>
      ) : null}
    </>
  );
};

export default MutuallyLiked;
