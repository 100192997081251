import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import countryProvinces from "../../../utils/countryProvinces.json";
import heightOptions from "../../../utils/heightOptions.json";
import INRpackageOptions from "../../../utils/INRpackageOptions.json";
import USDpackageOptions from "../../../utils/USDpackageOptions.json";
import { getRange, min18Years } from "../../../utils";
import {
  allusers,
  citiesList,
  fetchCities,
  fetchPackageDetails,
  fetchReligions,
  getUserDetails,
} from "../../slice";
import { editPartnerPreferences } from "../../../api/edit";
import MarriageIndicator from "../../../components/dropdownIndicator/MarriageIndicator";
import PackageIndicator from "../../../components/dropdownIndicator/PackageIndicator";
import HeightIndicator from "../../../components/dropdownIndicator/HeightIndicator";
import BirthIndicator from "../../../components/dropdownIndicator/BirthIndicator";
import LocationIndicator from "../../../components/dropdownIndicator/LocationIndicator";
import SimpleBackdrop from "../../../components/backdrop";
import LoadingText from "../../../components/loadingIndicator/LoadingText";
import { Link } from "react-router-dom";
import UserProfile from "../details/Profile";
import { getHigherEducation } from "../../slice";
import { getCommunityAll } from "../../slice";
import { getSubcasteAll } from "../../slice";
import { getCourseList } from "../../slice";
import { getSpecializationList } from "../../slice";
import { getEmployment } from "../../slice";
import Multiselect from "multiselect-react-dropdown";
import { map } from "lodash";

const maxAge = min18Years();
const maxYear = new Date(maxAge).getFullYear();
const birthYearRange = getRange(1975, maxYear, 1);

const PartnerPrefrences = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const courses = useSelector((state) => state?.courseList);
  const specialization = useSelector((state) => state.specializationList);
  const data = useSelector((state) => state.userDetails);
  const community = useSelector((state) => state?.comminityList);
  const subcaste = useSelector((state) => state?.subcasteList);
  const religion = useSelector((state) => state?.religionList);
  // const packageData = useSelector((state) => state.packageDetails);
  const cities = useSelector((state) => state.citiesList);
  const status = useSelector((state) => state?.status);
  const highereducation = useSelector((state) => state?.higherEducation);

  const [loading, setLoading] = useState(false);
  // const [packageOptions, setPackageOptions] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [stateProvincess, setStateProvincess] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [birthOptions, setBirthOptions] = useState([]);
  const occupation = useSelector((state) => state.employmentList);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
      dispatch(
        getCourseList({
          higher_education: data?.occupationsDetails?.highest_education,
        })
      );
      dispatch(
        getSpecializationList({ course: data?.occupationsDetails?.stream })
      );
    }

    dispatch(getEmployment());
  }, [dispatch, userID]);

  const handleChangeHigher = (e) => {
    formik.setFieldValue("highest_education", e.target.value);
    dispatch(getCourseList({ higher_education: e.target.value }));
  };
  useEffect(() => {
    dispatch(fetchPackageDetails({}));
    dispatch(fetchReligions({}));
    dispatch(getHigherEducation());
  }, [dispatch]);

  useEffect(() => {
    if (birthYearRange?.length) {
      const range = birthYearRange.map((year) => {
        return {
          value: year,
          label: year,
        };
      });
      setBirthOptions(range.reverse());
    }
  }, []);

  const labelFixer = (data) => {
    const strData = String(data);
    const length = strData.length;

    if (length === 4) {
      let feetValue = strData[0];
      let inchesValue = strData[3];
      return feetValue + `' ` + inchesValue + `''`;
    } else if (length === 3) {
      if (strData[2] && strData[2] === "1") {
        let feetValue = strData[0];
        let inchesValue = strData[2] + "0";
        return feetValue + `' ` + inchesValue + `''`;
      } else {
        let feetValue = strData[0];
        let inchesValue = strData[2];
        return feetValue + `' ` + inchesValue + `''`;
      }
    } else {
      return data + `" 0''`;
    }
  };

  const formik = useFormik({
    initialValues: {
      mycity: "",
      other: "",
      location_type: data?.partner_preference?.location_type || "My city",
      minHeight: data?.partner_preference?.partner_height_from || "",
      maxHeight: data?.partner_preference?.partner_height_to || "",
      minYear: data?.partner_preference?.partner_year_birth_from
        ? {
            label: data.partner_preference.partner_year_birth_from,
            value: data.partner_preference.partner_year_birth_from,
          }
        : "",
      maxYear: data?.partner_preference?.partner_year_birth_to
        ? {
            label: data.partner_preference.partner_year_birth_to,
            value: data.partner_preference.partner_year_birth_to,
          }
        : "",
      minPackage: data?.partner_preference?.partner_annual_package_from
        ? {
            label: data.partner_preference.partner_annual_package_from,
            value: data.partner_preference.partner_annual_package_from,
          }
        : "",
      maxPackage: data?.partner_preference?.partner_annual_package_to
        ? {
            label: data.partner_preference.partner_annual_package_to,
            value: data.partner_preference.partner_annual_package_to,
          }
        : "",
      minFamilyIncome: data?.partner_preference?.family_monthly_income_from
        ? {
            label: data.partner_preference.family_monthly_income_from,
            value:
              data.partner_preference.family_monthly_income_from == 0
                ? "0"
                : data.partner_preference.family_monthly_income_from,
          }
        : "",
      maxFamilyIncome: data?.partner_preference?.family_monthly_income_to
        ? {
            label: data.partner_preference.family_monthly_income_to,
            value: data.partner_preference.family_monthly_income_to?.toString(),
          }
        : "",
      religion: [data?.partner_preference?.partner_religion[0]] || [],
      caste: [data?.partner_preference?.partner_community[0]] || [],
      subcaste: [data?.partner_preference?.partner_sub_caste[0]] || "",
      community: [data?.partner_preference?.partner_community[0]] || [],
      maritalStatus: data?.partner_preference?.partner_merital_status[0] || [],
      employment_type:
        data?.partner_preference?.partner_employment_type?.map((res) => {
          return { value: res, name: res };
        }) || [],
      country: data?.partner_preference?.partner_country || [],
      state: data?.partner_preference?.partner_state || [],
      city: data?.partner_preference?.partner_city || [],
      occupation: data?.partner_preference?.partner_occupation || "",
      occupationAny:
        data?.partner_preference?.partner_occupation?.length === 0
          ? true
          : false,
      occupation_country:
        data?.partner_preference?.partner_occupation_country || [],
      occupation_state:
        data?.partner_preference?.partner_occupation_state || [],
      occupation_city: data?.partner_preference?.partner_occupation_city || [],
      packageCurrency: data?.packageCurrency?.toUpperCase() || "INR",
      expectation: data?.partner_preference?.about_expectations || "",
      courses: "",
      manglikStatus: data?.partner_preference?.maglik_status || "Any",
      packageStatus:
        data?.partner_preference?.partner_annual_package_from === null
          ? true
          : false,
      educationStatus:
        data?.partner_preference?.partner_education_qualification?.length === 0
          ? true
          : false,
      employmentStatus:
        data?.partner_preference?.partner_employment_type?.length === 0
          ? true
          : false,
      occupationStatus:
        data?.partner_preference?.partner_occupation_country?.length === 0
          ? true
          : false,
      highest_education:
        data?.partner_preference?.partner_education_qualification || "",
      highest_education_status: "",
      stream_status: "",
      specialization: data?.partner_preference?.partner_specialization || "",
      stream: data?.partner_preference?.partner_stream || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      minYear: Yup.object().shape({
        value: Yup.number().required("*Required"),
      }),
      maxYear: Yup.object().shape({
        value: Yup.number().required("*Required"),
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      // setLoading(true);
      const {
        minPackage,
        maxPackage,
        minHeight,
        maxHeight,
        minYear,
        maxYear,
        religion,
        maritalStatus,
        employment_type,
        employmentStatus,
        country,
        state,
        city,
        packageCurrency,
        location_type,
        expectation,
        caste,
        subcaste,
        packageStatus,
        educationStatus,
        maxFamilyIncome,
        minFamilyIncome,
        stream,
        highest_education,
        manglikStatus,
        specialization,
        occupation_country,
        occupation_state,
        occupation_city,
        occupationStatus,
        occupation,
        occupationAny,
        community,
      } = values;
      let payload = {
        userid: userID,
        location_type: location_type ? location_type : "My city",
        partner_year_birth_from: minYear.value,
        partner_year_birth_to: maxYear.value,
        partner_height_from: minHeight,
        partner_height_to: maxHeight,
        partner_annual_package_to:
          packageStatus === true ? "" : maxPackage.value,
        partner_annual_package_from:
          packageStatus === true
            ? ""
            : minPackage.value == 0
            ? 0
            : minPackage.value,
        partner_annual_package_currnecy: packageCurrency,
        partner_merital_status: maritalStatus,
        partner_employment_type:
          employmentStatus == true
            ? []
            : employment_type?.map((res) => {
                return res?.value;
              }),
        partner_religion: religion,
        partner_community: caste,
        partner_sub_caste: subcaste,
        partner_education_qualification:
          educationStatus === true ? [] : highest_education,
        partner_stream: educationStatus === true ? [] : stream,
        partner_specialization: educationStatus === true ? [] : specialization,
        partner_occupation: occupationAny === true ? [] : occupation,
        partner_occupation_country:
          occupationStatus === true ? [] : occupation_country,
        partner_occupation_state:
          occupationStatus === true ? [] : occupation_state,
        partner_occupation_city:
          occupationStatus === true ? [] : occupation_city,
        family_monthly_income_from:
          minFamilyIncome.value == 0 ? 0 : minFamilyIncome.value || "",
        family_monthly_income_to: maxFamilyIncome.value || "",
        family_monthly_income_currnecy: "INR",
        partner_country: location_type === "My city" ? [] : country,
        partner_state: location_type === "My city" ? [] : state,
        partner_city: location_type === "My city" ? [] : city,
        about_expectations: expectation,
        maglik_status: manglikStatus,
      };
      editPartnerPreferences(payload).then((response) => {
        setLoading(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          // resetForm({});
          // goBack();
          dispatch(getUserDetails({ user_id: userID }));
        }
      });
    },
  });

  console.log(
    "formik map",
    formik?.values?.employment_type.map((res) => {
      return res?.value;
    })
  );
  const handleReligionChange = (e) => {
    const selectedReligion = religion?.find(
      (res) => res?._id === e.target.value
    );
    formik.setFieldValue(
      "religion",
      [{ _id: selectedReligion?._id, name: selectedReligion?.name }] || {}
    );
  };

  const handleCasteChange = (e) => {
    const selectedCaste = community?.find((res) => res?._id === e.target.value);
    formik.setFieldValue(
      "caste",
      [{ _id: selectedCaste?._id, name: selectedCaste?.name }] || {}
    );
  };
  const handleSubcasteChange = (e) => {
    const selectedSubcaste = subcaste?.find(
      (res) => res?._id === e.target.value
    );
    formik.setFieldValue(
      "subcaste",
      [{ _id: selectedSubcaste?._id, name: selectedSubcaste?.name }] || {}
    );
  };

  useEffect(() => {
    if (formik?.values?.religion) {
      dispatch(
        getCommunityAll({ religion_id: formik?.values?.religion[0]?._id })
      );
    }
  }, [formik?.values?.religion]);

  useEffect(() => {
    if (formik?.values?.caste) {
      dispatch(getSubcasteAll({ community_id: formik?.values?.caste[0]?._id }));
    }
  }, [formik?.values?.caste]);

  useEffect(() => {
    const country = formik.values.country;
    if (typeof country === "object" && country?.length) {
      let allProvinces = [];
      country.forEach((c) => {
        const provinces = countryProvinces.find(
          (el) => el.name === c.value
        )?.stateProvinces;
        if (provinces) {
          allProvinces = [...allProvinces, ...provinces];
        }
      });
      setStateProvinces(allProvinces);
    }
  }, [formik.values.country]);

  useEffect(() => {
    const country = formik.values.occupation_country;
    if (typeof country === "object" && country?.length) {
      let allProvinces = [];
      country.forEach((c) => {
        const provinces = countryProvinces.find(
          (el) => el.name === c.value
        )?.stateProvinces;
        if (provinces) {
          allProvinces = [...allProvinces, ...provinces];
        }
      });
      setStateProvincess(allProvinces);
    }
  }, [formik.values.occupation_country]);

  useEffect(() => {
    async function getCities() {
      const state = formik.values.state;
      if (typeof state === "object" && state?.length) {
        setLoadingCities(true);
        let allCities = await Promise.all(
          state.map(async (element) => {
            return dispatch(fetchCities({ state_name: element.name })).then(
              (response) => {
                if (response?.error_code === 0) {
                  return response.data;
                }
              }
            );
          })
        );
        dispatch(citiesList(allCities.flat()));
        setLoadingCities(false);
      }
    }
    getCities();
  }, [dispatch, formik.values.state]);

  const handleSelect = (l, i) => {
    formik.setFieldValue("highest_education", l);
    formik.setFieldValue(
      "highest_education_status",
      l?.map((it) => {
        return it?._id;
      })
    );
  };
  const handleSelectOccupation = (l, i) => {
    formik.setFieldValue("occupation", l);
    formik.setFieldValue(
      "highest_education_status",
      l?.map((it) => {
        return it?._id;
      })
    );
  };
  const handleSelectCourse = (l, i) => {
    formik.setFieldValue("stream", l);
    formik.setFieldValue(
      "stream_status",
      l?.map((it) => {
        return it?._id;
      })
    );
  };
  const handleSelectSpecialization = (l, i) => {
    formik.setFieldValue("specialization", l);
  };
  useEffect(() => {
    dispatch(
      getCourseList({
        higher_education: formik.values.highest_education_status?.toString(),
      })
    );
  }, [formik.values.highest_education_status]);

  useEffect(() => {
    dispatch(getSpecializationList({ course: formik.values.stream_status }));
  }, [formik.values.stream_status]);

  const handleCountryChange = (selectedOptions) => {
    const filteredOptions = selectedOptions.map((el) => {
      return { value: el.name, name: el.name };
    });
    formik.setFieldValue("country", filteredOptions);
    if (!selectedOptions?.length) {
      formik.setFieldValue("state", []);
      formik.setFieldValue("city", []);
      dispatch(citiesList(null));
    }
  };

  const handleOccupationCountryChange = (selectedOptions) => {
    const filteredOptions = selectedOptions.map((el) => {
      return { value: el.name, name: el.name };
    });
    formik.setFieldValue("occupation_country", filteredOptions);
    if (!selectedOptions?.length) {
      formik.setFieldValue("occupation_state", []);
      formik.setFieldValue("occupation_city", []);
      dispatch(citiesList(null));
    }
  };
  const handleStateChange = async (selectedOptions) => {
    const filteredOptions = selectedOptions.map((el) => {
      return { value: el.name, name: el.name };
    });
    formik.setFieldValue("state", filteredOptions);
    if (!selectedOptions?.length) {
      formik.setFieldValue("city", []);
      dispatch(citiesList(null));
    }
  };
  const handleStateOccupationChange = async (selectedOptions) => {
    const filteredOptions = selectedOptions.map((el) => {
      return { value: el.name, name: el.name };
    });
    formik.setFieldValue("occupation_state", filteredOptions);
    if (!selectedOptions?.length) {
      formik.setFieldValue("occupation_city", []);
      dispatch(citiesList(null));
    }
  };

  const handleCityChange = (selectedOptions) => {
    const filteredOptions = selectedOptions.map((el) => {
      return { value: el.name, name: el.name };
    });
    formik.setFieldValue("city", filteredOptions);
  };
  const handleOccupationCityChange = (selectedOptions) => {
    const filteredOptions = selectedOptions.map((el) => {
      return { value: el.name, name: el.name };
    });
    formik.setFieldValue("occupation_city", filteredOptions);
  };

  const handleMaritalStatusChange = (selectedOptions) => {
    const filteredOptions = selectedOptions.map((el) => {
      return { value: el.value, name: el.name };
    });
    formik.setFieldValue("maritalStatus", filteredOptions);
  };

  const handleEmploymentType = (selectedOptions) => {
    console.log("selected options", selectedOptions);
    const filteredOptions = selectedOptions.map((el) => {
      return { value: el.value, name: el.name };
    });
    formik.setFieldValue("employment_type", filteredOptions);
  };

  console.log("employment", formik?.values?.employment_type);
  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Partner Preferences"
              : "Add User Partner Preferences"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img src="images/edit.png" className="action-view" alt="edit" />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                {/* <Link to={`/users/edit-gender/${userID}`}>Gender</Link> */}
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>Lifestyle</Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                  My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>About Me</Link>
                <Link to={`/users/my-document/${userID}`}>My Document</Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <div className="row prefen">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5 mt-5">
                      <div className="form-groupp">
                        <div className="nutritionRadio">
                          <h5>
                            <img src="images/Partner-Location.png" alt="" />{" "}
                            Partner Location
                          </h5>
                          {formik.touched.location_type &&
                          formik.errors.location_type ? (
                            <span className="error">
                              {formik.errors.location_type}
                            </span>
                          ) : null}
                          <p className="mb-0">
                            <input
                              type="radio"
                              id="test132"
                              name="radio-group1"
                              checked={
                                formik.values.location_type === "My city"
                              }
                              onChange={() =>
                                formik.setFieldValue("location_type", "My city")
                              }
                            />
                            <label for="test132">My city</label>
                          </p>
                          <p className="mb-0">
                            <input
                              type="radio"
                              id="test221"
                              name="radio-group1"
                              checked={formik.values.location_type === "Other"}
                              onChange={() =>
                                formik.setFieldValue("location_type", "Other")
                              }
                            />
                            <label for="test221">Other</label>
                          </p>
                          {formik.touched.do_you_own_house &&
                          formik.errors.do_you_own_house ? (
                            <span className="error">
                              {formik.errors.do_you_own_house}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {formik.values.location_type === "Other" ? (
                      <div className="form-group col-sm-12 col-md-12 col-lg-12">
                        <div className="mt-2">
                          <h6>Country</h6>
                          <Select
                            components={{
                              DropdownIndicator: LocationIndicator,
                            }}
                            value={formik.values.country}
                            // placeholder="Select Country"
                            options={countryProvinces}
                            getOptionLabel={(options) =>
                              options["value"]
                                ? options["value"]
                                : options["name"]
                            }
                            getOptionValue={(options) =>
                              options["value"]
                                ? options["value"]
                                : options["name"]
                            }
                            isMulti
                            isClearable
                            isDisabled={loadingCities || loading}
                            onChange={handleCountryChange}
                          />
                        </div>
                        {stateProvinces?.length ? (
                          <div className="mt-2">
                            <h6>State</h6>
                            <Select
                              components={{
                                DropdownIndicator: LocationIndicator,
                                LoadingIndicator: LoadingText,
                              }}
                              value={formik.values.state}
                              // placeholder="Select State"
                              options={stateProvinces}
                              getOptionLabel={(options) =>
                                options["value"]
                                  ? options["value"]
                                  : options["name"]
                              }
                              getOptionValue={(options) =>
                                options["value"]
                                  ? options["value"]
                                  : options["name"]
                              }
                              isMulti
                              isClearable
                              isDisabled={loadingCities || loading}
                              isLoading={loadingCities}
                              onChange={handleStateChange}
                            />
                          </div>
                        ) : null}
                        {cities?.length || formik.values.city?.length ? (
                          <div className="mt-2">
                            <h6>City</h6>
                            <Select
                              components={{
                                DropdownIndicator: LocationIndicator,
                                LoadingIndicator: LoadingText,
                              }}
                              value={formik.values.city}
                              placeholder="Doesn't Matter"
                              options={cities}
                              getOptionLabel={(options) =>
                                options["value"]
                                  ? options["value"]
                                  : options["name"]
                              }
                              getOptionValue={(options) =>
                                options["value"]
                                  ? options["value"]
                                  : options["name"]
                              }
                              isMulti
                              isClearable
                              isDisabled={loadingCities || loading}
                              isLoading={loadingCities}
                              onChange={handleCityChange}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 year-slider">
                      <h5>
                        <img src="images/partner-Year-of-Birth.png" alt="" />
                        Partner's Year of Birth
                      </h5>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="input-container mt-3">
                            <Select
                              components={{ DropdownIndicator: BirthIndicator }}
                              value={formik.values.minYear}
                              placeholder="Minimum Year of Birth"
                              options={birthOptions}
                              isDisabled={loading}
                              onChange={(value) => {
                                formik.setFieldValue("minYear", value);
                                formik.setFieldValue("maxYear", "");
                              }}
                            />
                          </div>
                          {formik.touched.minYear && formik.errors.minYear ? (
                            <span className="error">
                              {formik.errors.minYear.value}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="input-container mt-3">
                            <Select
                              components={{ DropdownIndicator: BirthIndicator }}
                              value={formik.values.maxYear}
                              placeholder="Maximum Year of Birth"
                              options={birthOptions}
                              isDisabled={loading}
                              isOptionDisabled={(option) =>
                                option.value < formik.values.minYear?.value
                              }
                              onChange={(value) =>
                                formik.setFieldValue("maxYear", value)
                              }
                            />
                          </div>
                          {formik.touched.maxYear && formik.errors.maxYear ? (
                            <span className="error">
                              {formik.errors.maxYear.value}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 year-slider">
                      <h5>
                        <img src="images/partner-Height.png" alt="" />
                        Partner's Height
                      </h5>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="input-container mt-3">
                            {/* <Select
                              components={{
                                DropdownIndicator: HeightIndicator,
                              }}
                              value={formik.values.minHeight}
                              placeholder="Minimum Height"
                              options={heightOptions}
                              isDisabled={loading}
                              onChange={(value) => {
                                formik.setFieldValue("minHeight", value);
                                formik.setFieldValue("maxHeight", "");
                              }}
                            /> */}
                            <select
                              className="form-control ps-4"
                              value={formik?.values?.minHeight}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "minHeight",
                                  e.target.value
                                );
                              }}
                            >
                              <option>Select</option>
                              {heightOptions?.map((res, i) => {
                                return (
                                  <option value={res?.value} key={i}>
                                    {res?.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {formik.touched.minHeight &&
                          formik.errors.minHeight ? (
                            <span className="error">
                              {formik.errors.minHeight.value}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="input-container mt-3">
                            {/* <Select
                              components={{
                                DropdownIndicator: HeightIndicator,
                              }}
                              value={formik.values.maxHeight}
                              placeholder="Maximum Height"
                              options={heightOptions}
                              isDisabled={loading}
                              isOptionDisabled={(option) =>
                                option.index <= formik.values.minHeight?.index
                              }
                              onChange={(value) =>
                                formik.setFieldValue("maxHeight", value)
                              }
                            /> */}

                            <select
                              className="form-control ps-4"
                              value={formik?.values?.maxHeight}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "maxHeight",
                                  e.target.value
                                );
                              }}
                            >
                              <option>Select</option>
                              {heightOptions?.map((res) => {
                                return (
                                  <option value={res?.value} key={res?.label}>
                                    {res?.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {formik.touched.maxHeight &&
                          formik.errors.maxHeight ? (
                            <span className="error">
                              {formik.errors.maxHeight.value}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 partner-Annual">
                      <h5>
                        <img src="images/partner-Annual-Package.png" alt="" />{" "}
                        Partner's Annual Package
                        <label
                          htmlFor="slider-range-price-sales"
                          className="priceContainer"
                          style={{ float: "right" }}
                        >
                          <input
                            type="radio"
                            id="INR"
                            name="packageCurrency"
                            checked={
                              formik.values.packageCurrency?.toUpperCase() ===
                              "INR"
                            }
                            onChange={() => {
                              formik.setFieldValue("packageCurrency", "INR");
                              formik.setFieldValue("minPackage", "");
                              formik.setFieldValue("maxPackage", "");
                            }}
                          />
                          <label className="pointer" htmlFor="INR">
                            INR
                          </label>{" "}
                          <input
                            type="radio"
                            id="USD"
                            name="packageCurrency"
                            checked={
                              formik.values.packageCurrency?.toUpperCase() ===
                              "USD"
                            }
                            onChange={() => {
                              formik.setFieldValue("packageCurrency", "USD");
                              formik.setFieldValue("minPackage", "");
                              formik.setFieldValue("maxPackage", "");
                            }}
                          />
                          <label className="pointer" htmlFor="USD">
                            USD
                          </label>
                        </label>
                      </h5>
                      <div className="row">
                        <div className="col-am-12 col-md-3 col-lg-3">
                          <div className="form-groupp mt-4">
                            <div className="nutritionRadio">
                              <label className="text_label">
                                Doesn't Matter{" "}
                              </label>
                              <p className="mb-0 mx-3">
                                <input
                                  type="checkbox"
                                  id="test11"
                                  name="packageStatus"
                                  checked={formik.values.packageStatus === true}
                                  onChange={() =>
                                    formik.setFieldValue(
                                      "packageStatus",
                                      !formik.values.packageStatus
                                    )
                                  }
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-9 col-lg-9"></div>
                        {formik.values.packageStatus === false ? (
                          <>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="input-container mt-3">
                                <Select
                                  components={{
                                    DropdownIndicator: PackageIndicator,
                                  }}
                                  value={formik.values.minPackage}
                                  placeholder="Minimum Package"
                                  options={
                                    formik.values.packageCurrency?.toUpperCase() ===
                                    "INR"
                                      ? INRpackageOptions
                                      : USDpackageOptions
                                  }
                                  isDisabled={loading}
                                  onChange={(value) => {
                                    formik.setFieldValue("minPackage", value);
                                    formik.setFieldValue("maxPackage", "");
                                  }}
                                />
                              </div>
                              {formik.touched.minPackage &&
                              formik.errors.minPackage ? (
                                <span className="error">
                                  {formik.errors.minPackage.value}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="input-container mt-3">
                                <Select
                                  components={{
                                    DropdownIndicator: PackageIndicator,
                                  }}
                                  value={formik.values.maxPackage}
                                  placeholder="Maximum Package"
                                  options={
                                    formik.values.packageCurrency?.toUpperCase() ===
                                    "INR"
                                      ? [...INRpackageOptions]
                                      : [...USDpackageOptions]
                                  }
                                  isDisabled={loading}
                                  isOptionDisabled={(option) =>
                                    option.value <
                                    formik.values.minPackage?.value
                                  }
                                  onChange={(value) =>
                                    formik.setFieldValue("maxPackage", value)
                                  }
                                />
                              </div>
                              {formik.touched.maxPackage &&
                              formik.errors.maxPackage ? (
                                <span className="error">
                                  {formik.errors.maxPackage.value}
                                </span>
                              ) : null}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12">
                      <h5 className="mb-3">
                        <img src="images/partner-Marital-Status.png" alt="" />{" "}
                        Maritial Status
                      </h5>
                      <Select
                        components={{ DropdownIndicator: MarriageIndicator }}
                        value={formik.values.maritalStatus}
                        // placeholder="Doesn't Matter"
                        options={[
                          { name: "Never Married", value: "never married" },
                          { name: "Second Marriage", value: "second marriage" },
                        ]}
                        getOptionLabel={(options) =>
                          options["value"] === "never married"
                            ? "Never Married"
                            : options["value"] === "second marriage"
                            ? "Second Marriage"
                            : ""
                        }
                        getOptionValue={(options) => options["value"]}
                        isMulti
                        isClearable
                        isDisabled={loadingCities || loading}
                        onChange={handleMaritalStatusChange}
                      />
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12 year-slider">
                      <h5 className="mb-3">
                        <img src="images/occupationn.png" alt="" />{" "}
                        Education/Occupation Detail
                      </h5>
                      <div className="row">
                        <div className="col-am-12 col-md-3 col-lg-3">
                          <div className="form-group mt-2">
                            <div className="nutritionRadio">
                              <label className="text_label mx-4">Any </label>
                              <p className="mb-0 mx-3">
                                <input
                                  type="checkbox"
                                  id="test11"
                                  name="educationStatus"
                                  checked={
                                    formik.values.educationStatus === true
                                  }
                                  onChange={() =>
                                    formik.setFieldValue(
                                      "educationStatus",
                                      !formik.values.educationStatus
                                    )
                                  }
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        {formik.values.educationStatus === false ? (
                          <>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <label className="num">
                                Highest Qualification
                              </label>
                              <div className="input-container add_user_info">
                                <Multiselect
                                  className="form-controll add_user_info"
                                  options={highereducation}
                                  selectedValues={
                                    formik.values.highest_education
                                  }
                                  onSelect={(selectedList, selectedItem) =>
                                    handleSelect(selectedList, selectedItem)
                                  }
                                  // onRemove={this.onRemove}
                                  displayValue="name"
                                />

                                <img
                                  src="images/suitcasse.png"
                                  className="input-img"
                                  alt=""
                                />
                                {formik.touched.highest_education &&
                                formik.errors.highest_education ? (
                                  <span className="error">
                                    {formik.errors.highest_education}
                                  </span>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-groupp col-sm-12 col-md-6 col-lg-6 mb-4">
                              <label className="num">Course</label>
                              <div className="input-container add_user_info">
                                <Multiselect
                                  className="form-controll add_user_info"
                                  options={courses}
                                  selectedValues={formik.values.stream}
                                  onSelect={(selectedList, selectedItem) =>
                                    handleSelectCourse(
                                      selectedList,
                                      selectedItem
                                    )
                                  }
                                  // onRemove={this.onRemove}
                                  displayValue="name"
                                />

                                <img
                                  src="images/suitcasee.png"
                                  className="input-img"
                                  alt=""
                                />
                                {formik.touched.stream &&
                                formik.errors.stream ? (
                                  <span className="error">
                                    {formik.errors.stream}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-groupp col-sm-12 col-md-6 col-lg-6 mb-4">
                              <label className="num">Specialization</label>
                              <div className="input-container add_user_info">
                                <Multiselect
                                  className="form-controll add_user_info"
                                  options={specialization}
                                  selectedValues={formik.values.specialization}
                                  onSelect={(selectedList, selectedItem) =>
                                    handleSelectSpecialization(
                                      selectedList,
                                      selectedItem
                                    )
                                  }
                                  // onRemove={this.onRemove}
                                  displayValue="name"
                                />
                                <img
                                  src="images/suitcasess.png"
                                  className="input-img"
                                  alt=""
                                />
                                {formik.touched.specialization &&
                                formik.errors.specialization ? (
                                  <span className="error">
                                    {formik.errors.specialization}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <div className="form-groupp col-sm-12 col-md-9 col-lg-9 mb-4"></div>
                      </div>
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12">
                      <h5 className="mb-3">Employment Type</h5>

                      <div className="row">
                        <div className="col-am-12 col-md-3 col-lg-3">
                          <div className="nutritionRadio">
                            <label className="text_label mx-4">Any </label>
                            <p className="mb-0 mx-3">
                              <input
                                type="checkbox"
                                id="test114"
                                name="employmentStatus"
                                checked={
                                  formik.values.employmentStatus === true
                                }
                                onChange={() =>
                                  formik.setFieldValue(
                                    "employmentStatus",
                                    !formik.values.employmentStatus
                                  )
                                }
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      {formik.values.employmentStatus ? (
                        ""
                      ) : (
                        <Select
                          components={{ DropdownIndicator: MarriageIndicator }}
                          value={formik.values.employment_type}
                          // placeholder="Doesn't Matter"
                          options={[
                            { name: "Government", value: "Government" },
                            { name: "Private", value: "Private" },
                            { name: "Business", value: "Business" },
                            { name: "Defence", value: "Defence" },
                            { name: "Self employed", value: "Self employed" },
                            {
                              name: "Private Service & Business",
                              value: "Private Service & Business",
                            },
                            {
                              name: "Government Service & Business",
                              value: "Government Service & Business",
                            },
                            {
                              name: "Self Employed & Business",
                              value: "Self Employed & Business",
                            },
                            {
                              name: "Not Working",
                              value: "Not Working",
                            },
                          ]}
                          getOptionLabel={(options) =>
                            options["value"] === "Not Working"
                              ? "Not Working"
                              : options["value"] === "Self Employed & Business"
                              ? "Self Employed & Business"
                              : options["value"] ===
                                "Government Service & Business"
                              ? "Government Service & Business"
                              : options["value"] ===
                                "Private Service & Business"
                              ? "Private Service & Business"
                              : options["value"] === "Self employed"
                              ? "Self employed"
                              : options["value"] === "Defence"
                              ? "Defence"
                              : options["value"] === "Business"
                              ? "Business"
                              : options["value"] === "Private"
                              ? "Private"
                              : options["value"] === "Government"
                              ? "Government"
                              : ""
                          }
                          getOptionValue={(options) => options["value"]}
                          isMulti
                          isClearable
                          isDisabled={loadingCities || loading}
                          onChange={handleEmploymentType}
                        />
                      )}
                    </div>

                    <div className="form-groupp col-sm-12 col-md-6 col-lg-6 mb-5 mt-5">
                      <div className="form-grouppp">
                        <div className="nutritionRadio">
                          <h5>
                            <img src="images/Partner-Location.png" alt="" />{" "}
                            Occcupation
                          </h5>
                          <p className="mb-0">
                            <input
                              type="checkbox"
                              id="test10"
                              name="occupationAny"
                              checked={formik.values.occupationAny === true}
                              onChange={() =>
                                formik.setFieldValue(
                                  "occupationAny",
                                  !formik.values.occupationAny
                                )
                              }
                            />
                            <label for="test1">Any</label>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-groupp col-sm-12 col-md-12 col-lg-12 mb-4">
                      {/* <label className="num">Occupation</label> */}
                      <div className="input-container add_user_info">
                        <Multiselect
                          className="form-controll add_user_info"
                          options={occupation}
                          selectedValues={formik.values.occupation}
                          onSelect={(selectedList, selectedItem) =>
                            handleSelectOccupation(selectedList, selectedItem)
                          }
                          // onRemove={this.onRemove}
                          displayValue="name"
                        />

                        <img
                          src="images/suitcasse.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="form-groupp col-sm-12 col-md-6 col-lg-6 mb-5 mt-5">
                      <div className="form-grouppp">
                        <div className="nutritionRadio">
                          <h5>
                            <img src="images/Partner-Location.png" alt="" />{" "}
                            Occcupation Location
                          </h5>
                          <p className="mb-0">
                            <input
                              type="checkbox"
                              id="test10"
                              name="occupationStatus"
                              checked={formik.values.occupationStatus === true}
                              onChange={() =>
                                formik.setFieldValue(
                                  "occupationStatus",
                                  !formik.values.occupationStatus
                                )
                              }
                            />
                            <label for="test1">Any</label>
                          </p>
                        </div>
                      </div>
                    </div>
                    {formik.values.occupationStatus === false ? (
                      <div className="form-grouppp col-sm-12 col-md-12 col-lg-12 mb-4">
                        <div className="mt-2">
                          <h6>Country</h6>
                          <Select
                            components={{
                              DropdownIndicator: LocationIndicator,
                            }}
                            value={formik.values.occupation_country}
                            // placeholder="Select Country"
                            options={countryProvinces}
                            getOptionLabel={(options) =>
                              options["value"]
                                ? options["value"]
                                : options["name"]
                            }
                            getOptionValue={(options) =>
                              options["value"]
                                ? options["value"]
                                : options["name"]
                            }
                            isMulti
                            isClearable
                            isDisabled={loadingCities || loading}
                            onChange={handleOccupationCountryChange}
                          />
                        </div>
                        {stateProvincess?.length ? (
                          <div className="mt-2">
                            <h6>State</h6>
                            <Select
                              components={{
                                DropdownIndicator: LocationIndicator,
                                LoadingIndicator: LoadingText,
                              }}
                              value={formik.values.occupation_state}
                              // placeholder="Select State"
                              options={stateProvincess}
                              getOptionLabel={(options) =>
                                options["value"]
                                  ? options["value"]
                                  : options["name"]
                              }
                              getOptionValue={(options) =>
                                options["value"]
                                  ? options["value"]
                                  : options["name"]
                              }
                              isMulti
                              isClearable
                              isDisabled={loadingCities || loading}
                              isLoading={loadingCities}
                              onChange={handleStateOccupationChange}
                            />
                          </div>
                        ) : null}
                        {cities?.length ||
                        formik.values.occupation_city?.length ? (
                          <div className="mt-2">
                            <h6>City</h6>
                            <Select
                              components={{
                                DropdownIndicator: LocationIndicator,
                                LoadingIndicator: LoadingText,
                              }}
                              value={formik.values.occupation_city}
                              placeholder="Doesn't Matter"
                              options={cities}
                              getOptionLabel={(options) =>
                                options["value"]
                                  ? options["value"]
                                  : options["name"]
                              }
                              getOptionValue={(options) =>
                                options["value"]
                                  ? options["value"]
                                  : options["name"]
                              }
                              isMulti
                              isClearable
                              isDisabled={loadingCities || loading}
                              isLoading={loadingCities}
                              onChange={handleOccupationCityChange}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 partner-Annual">
                      <h5>
                        <img src="images/partner-Annual-Package.png" alt="" />{" "}
                        Family Monthly Income
                        <label
                          htmlFor="slider-range-price-sales"
                          className="priceContainer"
                          style={{ float: "right" }}
                        >
                          <input
                            type="radio"
                            id="INR"
                            name="packageCurrency"
                            checked={
                              formik.values.packageCurrency?.toUpperCase() ===
                              "INR"
                            }
                            onChange={() => {
                              formik.setFieldValue("packageCurrency", "INR");
                              formik.setFieldValue("minFamilyIncome", "");
                              formik.setFieldValue("maxFamilyIncome", "");
                            }}
                          />
                          <label className="pointer" htmlFor="INR">
                            INR
                          </label>{" "}
                          <input
                            type="radio"
                            id="US"
                            name="packageCurrency"
                            checked={
                              formik.values.packageCurrency?.toUpperCase() ===
                              "USD"
                            }
                            onChange={() => {
                              formik.setFieldValue("packageCurrency", "USD");
                              formik.setFieldValue("minFamilyIncome", "");
                              formik.setFieldValue("maxFamilyIncome", "");
                            }}
                          />
                          <label className="pointer" htmlFor="USD">
                            USD
                          </label>
                        </label>
                      </h5>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="input-container mt-3">
                            <Select
                              components={{
                                DropdownIndicator: PackageIndicator,
                              }}
                              value={formik.values.minFamilyIncome}
                              placeholder="Minimum Salary"
                              options={
                                formik.values.packageCurrency?.toUpperCase() ===
                                "INR"
                                  ? INRpackageOptions
                                  : USDpackageOptions
                              }
                              isDisabled={loading}
                              onChange={(value) => {
                                formik.setFieldValue("minFamilyIncome", value);
                                formik.setFieldValue("maxFamilyIncome", "");
                              }}
                            />
                          </div>
                          {formik.touched.minFamilyIncome &&
                          formik.errors.minFamilyIncome ? (
                            <span className="error">
                              {formik.errors.minFamilyIncome.value}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="input-container mt-3">
                            <Select
                              components={{
                                DropdownIndicator: PackageIndicator,
                              }}
                              value={formik.values.maxFamilyIncome}
                              placeholder="Maximum Family Income"
                              options={
                                formik.values.packageCurrency?.toUpperCase() ===
                                "INR"
                                  ? [...INRpackageOptions]
                                  : [...USDpackageOptions]
                              }
                              isDisabled={loading}
                              isOptionDisabled={(option) =>
                                option.value <
                                formik.values.minFamilyIncome?.value
                              }
                              onChange={(value) =>
                                formik.setFieldValue("maxFamilyIncome", value)
                              }
                            />
                          </div>
                          {formik.touched.maxFamilyIncome &&
                          formik.errors.maxFamilyIncome ? (
                            <span className="error">
                              {formik.errors.maxFamilyIncome.value}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12">
                      <h5 className="mb-3">
                        <img src="images/partner-Marital-Status.png" alt="" />{" "}
                        Manglik Status
                      </h5>
                      <div className="form-groupp">
                        <div className="nutritionRadio">
                          <p className="mb-0">
                            <input
                              type="radio"
                              id="test12"
                              name="radio-group"
                              checked={formik.values.manglikStatus === "Yes"}
                              onChange={() =>
                                formik.setFieldValue("manglikStatus", "Yes")
                              }
                            />
                            <label for="test12">Yes</label>
                          </p>

                          <p className="mb-0">
                            <input
                              type="radio"
                              id="test22"
                              name="radio-group"
                              checked={
                                formik.values.manglikStatus === "Yes but Mild"
                              }
                              onChange={() =>
                                formik.setFieldValue(
                                  "manglikStatus",
                                  "Yes but Mild"
                                )
                              }
                            />
                            <label for="test22">Yes but Mild</label>
                          </p>
                          <p className="mb-0">
                            <input
                              type="radio"
                              id="test32"
                              name="radio-group"
                              checked={formik.values.manglikStatus === "No"}
                              onChange={() =>
                                formik.setFieldValue("manglikStatus", "No")
                              }
                            />
                            <label for="test32">No</label>
                          </p>
                          <p className="mb-0">
                            <input
                              type="radio"
                              id="test42"
                              name="radio-group"
                              checked={formik.values.manglikStatus === "Any"}
                              onChange={() =>
                                formik.setFieldValue("manglikStatus", "Any")
                              }
                            />
                            <label for="test42">Any</label>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <h5>Religion</h5>
                      <div className="input-container add_user_info">
                        <select
                          className="form-control ps-4"
                          value={formik?.values?.religion[0]?._id}
                          onChange={(e) => handleReligionChange(e)}
                        >
                          <option>Select</option>
                          {religion?.map((res) => {
                            return (
                              <option value={res?._id} key={res?._id}>
                                {res?.name}
                              </option>
                            );
                          })}
                        </select>
                        <img
                          src="images/Path 17786.png"
                          className="input-img pass"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <h5>Caste</h5>
                      <div className="input-container add_user_info">
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps("caste")}
                          value={formik?.values?.caste[0]?._id || ""}
                          onChange={(e) => handleCasteChange(e)}
                        >
                          <option>Select</option>
                          {community?.map((res, i) => {
                            return (
                              <option value={res?._id} key={res?._id}>
                                {res?.name}
                              </option>
                            );
                          })}
                        </select>
                        <img
                          src="images/Path 17786.png"
                          className="input-img pass"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <h5>Sub-Caste</h5>
                      <div className="input-container add_user_info">
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps("subcaste")}
                          value={formik?.values?.subcaste[0]?._id || ""}
                          onChange={(e) => handleSubcasteChange(e)}
                        >
                          <option>Select</option>
                          {subcaste?.map((res, i) => {
                            return (
                              <option value={res?._id}>{res?.name}</option>
                            );
                          })}
                        </select>
                        <img
                          src="images/Path 17786.png"
                          className="input-img pass"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12">
                      <h5>About Expectation</h5>
                      <textarea
                        className="form-control"
                        placeholder="..."
                        rows={8}
                        {...formik.getFieldProps("expectation")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default PartnerPrefrences;
