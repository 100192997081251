import React from 'react'

const Height = ({ setHeightFrom, setHeightTo }) => {
    const age = ["4.0", "4.1", "4.2", "4.3", "4.4", "4.5", "4.6", "4.8", "4.9", "5.0", "5.1", "5.2", "5.3", "5.4", "5.5", "5.6", "5.8", "5.9", "6.0", "6.1", "6.2", "6.3", "6.4", "6.5", "6.6", "6.8", "6.9", "7.0", "7.1", "7.2", "7.3", "7.4", "7.5", "7.6", "7.8", "7.9", "8.0"]
    return (
        <div className='d-flex justify-content-around mt-4'>
            <h4 className='text-center'>Height</h4>
            <div className="form-groupp">
                <select
                    className="form-control add_user_info"
                    onChange={(e) => setHeightFrom(e.target.value)}

                >
                    <option value="">
                        Select Min
                    </option>
                    {age?.map((res) => {
                        return <option value={res}>{res}</option>
                    })}
                </select>

            </div>
            <div className="form-groupp">
                <select
                    className="form-control add_user_info"
                    onChange={(e) => setHeightTo(e.target.value)}

                >
                    <option value="">
                        Select Max
                    </option>
                    {age?.map((res) => {
                        return <option value={res}>{res}</option>
                    })}
                </select>

            </div>

        </div>
    )
}

export default Height