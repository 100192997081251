import React from "react";
import { components } from "react-select";

const PackageIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src="images/partner-Annual-Package.png" alt="Money Bag" />
    </components.DropdownIndicator>
  );
};

export default PackageIndicator;
