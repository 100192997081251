import React, { useState, useEffect } from 'react'
import { Drawer } from "antd";
import { useFormik } from 'formik'
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import { addUpdateCourses } from '../../api/offer';
import { getOfferDetails } from '../slice';
import { getHigherEducation } from '../slice';
import { getCourses } from '../slice';
import { getCourseDetails } from '../slice';


const AddCourses = ({ show, handleShow, id, status, search }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.courseDetail);
    const { enqueueSnackbar } = useSnackbar();
    const [viewportWidth, setViewportWidth] = useState("")
    const education = useSelector((state) => state?.higherEducation)


    useEffect(() => {
        if (typeof (window.innerWidth) != 'undefined') {
            setViewportWidth(window.innerWidth);
        }
    }, [])
    useEffect(() => {
        dispatch(getHigherEducation())

    }, [dispatch]);
    useEffect(() => {
        dispatch(getCourseDetails({ id }))
    }, [])


    const formik = useFormik({
        initialValues: {
            highereducation: id ? data?.higher_education
                : "",
            name: id ? data?.name : "",

        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            highereducation: Yup.string().required("*Required"),
            name: Yup.string().required("*Required"),

        }),
        onSubmit: (values, { resetForm }) => {
            const { highereducation, name } = values;
            console.log(values)
            const payload = {
                higher_education: highereducation,
                name: name,

            };
            if (id) {
                payload.courseId = id;
            }
            addUpdateCourses(payload).then((response) => {
                enqueueSnackbar(response?.message);
                if (response?.error_code === 0) {
                    resetForm({});
                    dispatch(getCourses({ page: 1 }));
                    handleShow()
                }
            });

        },
    });



    return (
        <>
            <Drawer placement="right" onClose={handleShow} visible={show} width={viewportWidth > 700 ? "40%" : "100%"}>

                <div className="px-5 pb-5">
                    <div className="text-center">
                        <h2 className="offcanvas-title mb-3">{id ? "Edit Course" : "Add Course"} </h2>
                    </div>
                    <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label">Higher Education</label>
                                <select
                                    className="form-control add_user_info"
                                    {...formik.getFieldProps("highereducation")}
                                >
                                    <option value="">
                                        Select Higher Education
                                    </option>
                                    {education?.map((el, i) => {
                                        return <option value={el?._id}>{el?.name} {el?.last_name}</option>
                                    })}
                                </select>
                                {formik.touched.highereducation && formik.errors.highereducation ? (
                                    <span className="error">{formik.errors.highereducation}</span>
                                ) : null}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4 mt-4">
                                <div className="form-groupp">
                                    <label className="text_label">Course Name</label>
                                    <div className="input-containerr">
                                        <input type="text" className="form-control add_user_info" {...formik.getFieldProps("name")}
                                            placeholder="Course Name" />
                                        {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                                    </div>
                                    {formik.touched.name && formik.errors.name ? (
                                        <span className="error">{formik.errors.name}</span>
                                    ) : null}
                                </div>
                            </div>




                        </div>
                        <div className="button_area text-center">
                            <button type="submit" className="save_btn">Submit</button>
                        </div>
                    </form>

                </div>
            </Drawer>

        </>
    )
}

export default AddCourses