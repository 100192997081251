import React from "react";
import PropTypes from "prop-types";

const regex =
  /\.(png|jpg|jpeg|pdf|PNG|JPG|JPEG|avif|apng|gif|jfif|pjpeg|pjp|svg|webp)$/;

const ImgFluid = ({ src, className }) => {
  return (
    <img
      src={
        typeof src === "string"
          ? src
          : typeof src === "object" && src?.length
          ? src.find((path) => path?.toLowerCase()?.match(regex))
          : "images/allison.png"
      }
      className={className || "img-fluid"}
      alt="User"
    />
  );
};

export default ImgFluid;

ImgFluid.propTypes = {
  className: PropTypes.string,
};
