import React from 'react'

const Maritial = ({ setMarriageValue }) => {
    return (
        <div className='row mt-4'>
            <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                <h4 className='text-center'>Maritial Status</h4>
                <div className="marital-status">
                    <span>
                        <input
                            type="radio"
                            id="NeverMarried"
                            name="merital_status"
                            onChange={() =>
                                setMarriageValue("never married")
                            }
                        />
                        <label htmlFor="NeverMarried">Never Married</label>
                    </span>

                    <span>
                        <input
                            type="radio"
                            id="widow"
                            name="merital_status"
                            onChange={() =>
                                setMarriageValue(
                                    "widow"
                                )
                            }
                        />
                        <label htmlFor="widow">Widow</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            id="Divorced"
                            name="merital_status"

                            onChange={() =>
                                setMarriageValue("divorced")
                            }
                        />
                        <label htmlFor="Divorced">Divorced</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            id="AwaitingDivorce"
                            name="merital_status"
                            onChange={() =>
                                setMarriageValue("awaiting divorce")
                            }
                        />
                        <label htmlFor="AwaitingDivorce">Awaiting Divorce</label>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Maritial