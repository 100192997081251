import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import SimpleBackdrop from "../../../components/backdrop";
import { fetchReligions, getUserDetails } from "../../slice";
import { editReligion } from "../../../api/edit";
import { Link } from "react-router-dom";
import { getCommunityAll } from "../../slice";
import { getSubcasteAll } from "../../slice";
import { getGothraAll } from "../../slice";
import { getDevakAll } from "../../slice";
import UserProfile from "../details/Profile";

const Religion = () => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state.status);
  const list = useSelector((state) => state.religionList);
  const community = useSelector((state) => state?.comminityList)
  const subcaste = useSelector((state) => state?.subcasteList)
  const gothraList = useSelector((state) => state?.gothraList)
  const devakList = useSelector((state) => state?.devakList)
  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (!list) {
      dispatch(fetchReligions({}));
    }
    dispatch(getCommunityAll({ religion_id: "616e64d04f57b0de32cfc71e" }))
    dispatch(getSubcasteAll({ community_id: "6463660e5db3dc8d734810d9" }))
    dispatch(getGothraAll({ community_id: "6463660e5db3dc8d734810d9", sub_caste: data?.sub_caste }))
    dispatch(getDevakAll({ community_id: "6463660e5db3dc8d734810d9", sub_caste: data?.sub_caste, gothra: data?.gothra }))
  }, [dispatch, list]);

  const handleFetchGothra = (e) => {
    formik.setFieldValue("sub_caste", e.target.value)
    dispatch(getGothraAll({ community_id: "6463660e5db3dc8d734810d9", sub_caste: e.target.value }))
  }
  const handleFetchDevak = (e) => {
    formik.setFieldValue("gothra", e.target.value)
    dispatch(getDevakAll({ community_id: "6463660e5db3dc8d734810d9", sub_caste: formik.values.sub_caste, gothra: e.target.value }))
  }

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      religion: data?.religionDetails?._id
        || "616e64d04f57b0de32cfc71e",
      caste: data?.community || "6463660e5db3dc8d734810d9",
      sub_caste: data?.sub_caste || "",
      gothra: data?.gothra || "",
      devak: data?.devak || "",
      dosh: data?.dosh || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      sub_caste: Yup.string().required("*Required"),
      gothra: Yup.string().required("*Required"),
      devak: Yup.string().required("*Required"),

    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);

      editReligion({ ...values, userid: userID }).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          dispatch(getUserDetails({ user_id: userID }));
        }
      });
    },
  });

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Religion/Caste Details"
              : "Add User Religion/Caste Details"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img
                src="images/edit.png"
                className="action-view"
                alt="edit"
              />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>
                  Lifestyle
                </Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                  My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>
                  About Me
                </Link>
                <Link to={`/users/my-document/${userID}`}>
                  My Document
                </Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 mt-5">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                  <div className="marital-status">
                    <span>
                      <input
                        type="radio"
                        id="NeverMarried"
                        name="merital_status"
                        checked={
                          formik.values.religion === "616e64d04f57b0de32cfc71e"
                        }
                        onChange={() =>
                          formik.setFieldValue("religion", "616e64d04f57b0de32cfc71e")
                        }
                      />
                      <label htmlFor="NeverMarried">Hindu</label>
                    </span>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Caste</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("caste")}
                    >
                      {community?.map((res, i) => {
                        return <option value={res?._id}>{res?.name}</option>

                      })}
                    </select>
                    <img
                      src="images/Path 17786.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.caste && formik.errors.caste ? (
                      <span className="error">{formik.errors.caste}</span>
                    ) : null}
                  </div>
                </div>
               
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Sub-Caste</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("sub_caste")}
                      value={formik.values.sub_caste}
                      onChange={(e) => {
                        handleFetchGothra(e)
                      }}
                      disabled={!subcaste?.length}

                    >
                      <option disabled value="">
                        Select sub-caste
                      </option>
                      {subcaste?.map((res, i) => {
                        return <option value={res?._id}>{res?.name}</option>
                      })}
                    </select>
                    <img
                      src="images/Path 17786.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.sub_caste && formik.errors.sub_caste ? (
                      <span className="error">{formik.errors.sub_caste}</span>
                    ) : null}
                  </div>
                </div>


                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Gothra</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      // {...formik.getFieldProps("gothra")}
                      value={formik.values.gothra}
                      onChange={(e) => { handleFetchDevak(e) }}
                      disabled={!gothraList?.length}
                    >
                      <option disabled value="">
                        Select
                      </option>
                      {gothraList?.map((res, i) => {
                        return <option value={res?._id}>{res?.name}</option>

                      })}
                    </select>
                    <img
                      src="images/Path 17786.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.gothra && formik.errors.gothra ? (
                      <span className="error">{formik.errors.gothra}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Devak</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("devak")}
                      disabled={!devakList?.length}
                    >
                      <option disabled value="">
                        Select Devak
                      </option>
                      {devakList?.map((res, i) => {
                        return <option value={res?._id} key={i}>{res?.name}</option>
                      })}
                    </select>
                    <img
                      src="images/astrology.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.devak && formik.errors.devak ? (
                      <span className="error">{formik.errors.devak}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Dosh</label>
                  <div className="input-container add_user_info">
                    <input type="text"
                      className="form-control ps-4"
                      {...formik.getFieldProps("dosh")}
                      placeholder="optional" />
                    <img
                      src="images/astrology.png"
                      className="input-img pass"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default Religion;
