import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { cms, fetchFAQs } from "../slice";
import { updateFAQs } from "../../api/cms";
import SimpleBackdrop from "../../components/backdrop";
import { useHistory } from 'react-router-dom'
import DeleteFAQ from "./deleteFaq";
import { useToggle } from "../../app/hooks";

const FAQs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory()
  const status = useSelector((state) => state.status);
  const faqs = useSelector((state) => state.cms);
  const [isOpenDelete, toggleDelete] = useToggle(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([{ title: "", description: "" }]);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchFAQs({}));

  }, [dispatch]);

  useEffect(() => {
    if (faqs?.length) {
      setData([...faqs]);
    }
  }, [faqs]);
  const handleAdd = () => {
    history.push("/content-management/add-update-faq")
  }

  const handleEdit = (id) => {
    history.push("/content-management/add-update-faq", { params: id })
  }
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      _id: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required(" *Required"),
      description: Yup.string().required(" *Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      let payload = {
        title: values.title,
        description: values.description,
      };

      if (values._id) {
        payload.faq_id = values._id;
      }

      updateFAQs(payload).then((response) => {
        // enqueueSnackbar(response?.message);
        // // if (response?.error_code === 0) {
        // //   resetForm({});
        // // }
        setLoading(false);
      });
    },
  });




  useEffect(() => {
    return () => dispatch(cms(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="user_package_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="user_package_content">

                <div className="parent">
                  <div className="center">
                    <img src="images/FAQs icon.png" alt="package-img" />
                  </div>
                  <div className="right">
                    <img src="images/add.png" alt="add" style={{ cursor: "pointer" }} onClick={handleAdd} />                  </div>
                </div>

                <div className="manage_salary">
                  <h4 className="manage_txt">
                    Frequently asked questions.
                  </h4>
                </div>

                {data?.length
                  ? data.map((el, index) => (
                    <div className="min-package-area subs_area" key={index}>

                      <div className="form-group col-sm-12 col-md-12 col-lg-12" style={{ cursor: "pointer" }}>
                        <img className="remove fas fa-edit mx-2" src="images/edit.png" alt="add" onClick={() => handleEdit(el._id)} />

                        <img className="remove fas fa-edit" src="images/delete.png" alt="add" onClick={() => {
                          setId(el._id);
                          toggleDelete();
                        }} />

                      </div>

                      <div className="package_main">
                        <p className="pact_txt">
                          Question {index + 1}.{" "}
                        </p>
                        <p>{el?.title}</p>
                      </div>
                      <div className="package_main">
                        <p className="pact_txt">
                          Answer {index + 1}
                        </p>
                        <p dangerouslySetInnerHTML={{
                          __html: el.description,
                        }}></p>
                      </div>
                    </div>
                  ))
                  : null}

              </div>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
      <DeleteFAQ
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        id={id}
        page={page}
        userType="deactivated"
      />
    </div>
  );
};

export default FAQs;
