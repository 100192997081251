import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { getNotifications, getUnreadNotifications } from "../slice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getCookie } from "../../utils";
import { Link } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const [showSetting, setShowSetting] = useState(false);
  const data = useSelector((state) => state?.notificationList);
  const unreadCount = useSelector((state) => state?.unreadNotification);

  useEffect(() => {
    // setInterval(() => {
    //     dispatch(getUnreadNotifications({}))
    // }, 6000);
    // setInterval(() => {
    //     dispatch(getNotifications({ page: 1 }))
    // }, 6000);
    dispatch(getUnreadNotifications({}));
    dispatch(getNotifications({ page: 1 }));
  }, []);

  const handleMouseLeave = () => {
    setShowSetting(false);
  };
  const handleMouseEnter = () => {
    setShowSetting(true);
    dispatch(getUnreadNotifications({}));
    dispatch(getNotifications({ page: 1 }));
  };

  const handleReadNotification = (e, id) => {
    e.preventDefault();
    handleUpdate(id);
  };
  const handleUpdate = (id) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        Authorization: getCookie("clszdi"),
      },
      url: `https://api.pairsindia.in/user/read_notification`,
      data: {
        notification_id: id,
      },
    }).then((res) => {
      console.log("data new", res);
      if (res.data.error_code === 0) {
        dispatch(getNotifications({ page: 1 }));
        dispatch(getUnreadNotifications({}));
      }
    });
  };

  const handleClearAll = () => {
    axios({
      method: "delete",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        Authorization: getCookie("clszdi"),
      },
      url: `https://api.pairsindia.in/user/clear_all_notification`,
    }).then((res) => {
      console.log("data new", res);
      if (res.data.error_code === 0) {
        dispatch(getNotifications({ page: 1 }));
        dispatch(getUnreadNotifications({}));
      }
    });
  };
  return (
    <div className=" d-flex align-items-center justify-content-center justify-content-sm-end mt-5 mb-4 ">
      <Dropdown
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ right: 0 }}
        className="tabs-lists mx-3"
      >
        <Dropdown.Toggle variant="" id="hover-dropdown">
          <div className="d-flex">
            <i className="fas fa-bell" style={{ fontSize: "30px" }}></i>
            {unreadCount?.unseen_count !== 0 ? (
              <span className="badge rounded-pill badge-notification bg-danger">
                {unreadCount?.unseen_count}
              </span>
            ) : (
              ""
            )}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu show={showSetting}>
          <h5 className="text-center notification mt-3">
            Latest Notifications
          </h5>
          {data?.data?.length > 0 ? (
            <div className="d-flex justify-content-end pointer mx-4">
              <button className="notification_btnn" onClick={handleClearAll}>
                Clear All
              </button>
            </div>
          ) : (
            ""
          )}

          <div className="dropdown-divider"></div>
          {data?.data?.length > 0 ? (
            data?.data?.map((res, i) => {
              if (i < 6) {
                return (
                  <Dropdown.Item
                    href="#"
                    onClick={(e) => handleReadNotification(e, res?._id)}
                    style={{ border: "2px red" }}
                  >
                    <button className="dropdown-item ">
                      {res?.adminByStatus === 0 &&
                      res?.title === "New User signup" ? (
                        <Link to={`/users/new/${res.action_id}`} title="View">
                          <div>
                            <p className={res?.seen === 1 ? "read" : "unread"}>
                              <i
                                class="fa fa-circle"
                                aria-hidden="true"
                                style={{ fontSize: "15px" }}
                              ></i>{" "}
                              {res?.body}
                            </p>
                            <p
                              className={
                                res?.seen === 1 ? "read mx-5" : "unread mx-5"
                              }
                            >
                              {res?.createdAt
                                ? new Date(res?.createdAt).toLocaleString()
                                : "-"}
                            </p>
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}
                      {res?.adminByStatus === 1 &&
                      res?.title === "New User signup" ? (
                        <Link
                          to={`/users/approved/${res.action_id}`}
                          title="View"
                        >
                          <div>
                            <p className={res?.seen === 1 ? "read" : "unread"}>
                              <i
                                class="fa fa-circle"
                                aria-hidden="true"
                                style={{ fontSize: "15px" }}
                              ></i>
                              {res?.body}
                            </p>
                            <p
                              className={
                                res?.seen === 1 ? "read mx-5" : "unread mx-5"
                              }
                            >
                              {res?.createdAt
                                ? new Date(res?.createdAt).toLocaleString()
                                : "-"}
                            </p>
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}
                      {res?.adminByStatus === 1 &&
                      res?.title === "New images found" ? (
                        <Link to={`/approve-photos`} title="View">
                          <div>
                            <p className={res?.seen === 1 ? "read" : "unread"}>
                              <i
                                class="fa fa-circle"
                                aria-hidden="true"
                                style={{ fontSize: "15px" }}
                              ></i>{" "}
                              {res?.body}
                            </p>
                            <p
                              className={
                                res?.seen === 1 ? "read mx-5" : "unread mx-5"
                              }
                            >
                              {res?.createdAt
                                ? new Date(res?.createdAt).toLocaleString()
                                : "-"}
                            </p>
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}
                      {res?.adminByStatus === 0 &&
                      res?.title === "New images found" ? (
                        <Link to={`/approve-photos`} title="View">
                          <div>
                            <p className={res?.seen === 1 ? "read" : "unread"}>
                              <i
                                class="fa fa-circle"
                                aria-hidden="true"
                                style={{ fontSize: "15px" }}
                              ></i>{" "}
                              {res?.body}
                            </p>
                            <p
                              className={
                                res?.seen === 1 ? "read mx-5" : "unread mx-5"
                              }
                            >
                              {res?.createdAt
                                ? new Date(res?.createdAt).toLocaleString()
                                : "-"}
                            </p>
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}
                      {res?.adminByStatus === 1 &&
                      res?.title === "Purchased a new subscription plan" ? (
                        <Link
                          to={`/users/approved/${res.action_id}`}
                          title="View"
                        >
                          <div>
                            <p className={res?.seen === 1 ? "read" : "unread"}>
                              <i
                                class="fa fa-circle"
                                aria-hidden="true"
                                style={{ fontSize: "15px" }}
                              ></i>{" "}
                              {res?.body}
                            </p>
                            <p
                              className={
                                res?.seen === 1 ? "read mx-5" : "unread mx-5"
                              }
                            >
                              {res?.createdAt
                                ? new Date(res?.createdAt).toLocaleString()
                                : "-"}
                            </p>
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}
                      {res?.adminByStatus === 0 &&
                      res?.title === "Purchased a new subscription plan" ? (
                        <div>
                          <p className={res?.seen === 1 ? "read" : "unread"}>
                            <i
                              class="fa fa-circle"
                              aria-hidden="true"
                              style={{ fontSize: "15px" }}
                            ></i>{" "}
                            {res?.body}
                          </p>
                          <p
                            className={
                              res?.seen === 1 ? "read mx-5" : "unread mx-5"
                            }
                          >
                            {res?.createdAt
                              ? new Date(res?.createdAt).toLocaleString()
                              : "-"}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </button>
                  </Dropdown.Item>
                );
              }
            })
          ) : (
            <h4 className="text-center">No New Notifications</h4>
          )}
          {data?.data?.length > 9 ? (
            <Link to="/all-notifications">
              <h5 className="text-center notification">view all</h5>
            </Link>
          ) : (
            ""
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Header;
