import React, { useState, useEffect } from 'react'
import { Drawer } from "antd";
import { useFormik } from 'formik'
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import { addNotification } from '../../api/offer';
import { getNotification } from '../slice';
import Multiselect from 'multiselect-react-dropdown';
import { getNotificationDetails } from '../slice';


const DetailNotification = ({ show, handleShow, id, status, search }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [viewportWidth, setViewportWidth] = useState("")
    const users = useSelector((state) => state?.allusers)
    const [allUsers, setAllUsers] = useState([])
    const data = useSelector((state) => state.notificationDetail);



    useEffect(() => {
        if (typeof (window.innerWidth) != 'undefined') {
            setViewportWidth(window.innerWidth);
        }
    }, [])
    console.log("datad", data)

    useEffect(() => {
        if (id) {
            dispatch(getNotificationDetails({ id }));
        }
    }, [dispatch, id]);

    const handleSelect = (l, i) => {
        setAllUsers(l?.map((res) => {
            return res?._id
        }))

    }

    const formik = useFormik({
        initialValues: {
            title: "",
            body: "",
            users: "",
            notifyAllUsers: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            title: Yup.string().required("*Required"),
            body: Yup.string().required("*Required"),
        }),

        onSubmit: (values, { resetForm }) => {
            const { title, body, notifyAllUsers } = values;
            const payload = {
                title: title,
                body: body,
                users: allUsers ? allUsers : notifyAllUsers ? notifyAllUsers : [],
                send_to_all_user: notifyAllUsers

            };
            addNotification(payload).then((response) => {
                // setSaving(false);
                enqueueSnackbar(response?.message);
                if (response?.error_code === 0) {
                    resetForm({});
                    dispatch(getNotification({}));
                    handleShow()
                }
            });

        },
    });

    return (
        <>
            <Drawer placement="right" onClose={handleShow} visible={show} width={viewportWidth > 700 ? "40%" : "100%"}>

                <div className="px-5 pb-5">
                    <div className="text-center">
                        <h2 className="offcanvas-title mb-3">{"Notification Details"} </h2>
                    </div>
                    <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                <div className="form-groupp">
                                    <label className="text_label">Notification Title</label>
                                    <h5>{data?.notification_details?.title || ""}</h5>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <hr/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="form-groupp">
                                    <label className="text_label">Information</label>
                                    <h5> {data?.notification_details?.body || ""}</h5>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <hr/>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <label className="text_label">Notified Users</label>
                                {data?.notification_details?.users?.map((res, i) => {
                                    return <div className='d-flex justify-content-space-between mt-3'>
                                        <img
                                            src={res?.profile_image_video_path[0]}
                                            className="action-view detail_notify mx-2"
                                            alt="delete"
                                        />
                                        <h5>{res?.first_name} {res?.last_name}</h5>
                                    </div>
                                })}

                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <hr/>
                            </div>
                        </div>


                    </form>

                </div>
            </Drawer>

        </>
    )
}

export default DetailNotification;