import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";


import {
    getStory,
} from "../../slice";

import { useToggle } from "../../../app/hooks";

import SimpleBackdrop from "../../../components/backdrop";
import ImgFluid from "../../../components/image/ImgFluid";
import AddSuccessStory from "./AddSuccessStory";
import DeleteStory from "./DeleteStory";

const SuccessList = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state?.status);
    const limit = useSelector((state) => state?.limit);
    const data = useSelector((state) => state?.story);

    const [isOpen, toggle] = useToggle(false);
    const [isOpenDelete, toggleDelete] = useToggle(false);
    const [isOpenEdit, toggleEdit] = useToggle(false)
    const [isOpenStatus, toggleStatus] = useToggle(false)
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [id, setId] = useState("");
    const [status2, setStatus2] = useState("")


    useEffect(() => {
        dispatch(getStory({}))
    }, [dispatch, page, limit]);


    return (
        <div className="m-main-content">
            <div className="brand_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="brand_head_data">
                                <div className="brands_total deactive_user">
                                    {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                                    <p className="t_brand_txt">Total Stories</p>
                                    <h4 className="brand_num">{data?.total_storys
                                        || "NA"}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end col-md-8">

                            <span className="brand_add mt-5" onClick={toggle}>
                                <img src="images/add.png" alt="add" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brand_table">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="brand_table_inner">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">User Image</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data?.story_list?.length ? (
                                                data?.story_list?.map((el, i) => (
                                                    <tr key={i}>

                                                        <td className="bt-non">
                                                            <div className="p_pic">
                                                                <ImgFluid
                                                                    src={
                                                                        el?.userid?.profile_image_video_path[0] || ""
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="bt-non">
                                                            {
                                                                el?.userid?.first_name || ""
                                                            }
                                                        </td>
                                                        <td className="bt-non">
                                                            {
                                                                el?.userid?.last_name || ""
                                                            }
                                                        </td>

                                                        <td className="bt-non">
                                                            <span className="table-action">
                                                              
                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggleEdit();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Edit"
                                                                >
                                                                    <img
                                                                        src="images/edit.png" className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                                <button
                                                                    onClick={() => {
                                                                        setId(el._id);
                                                                        toggleDelete();
                                                                    }}
                                                                    style={{ background: "none", border: "none" }}
                                                                    title="Delete"
                                                                >
                                                                    <img
                                                                        src="images/delete.png"
                                                                        className="action-view"
                                                                        alt="delete"
                                                                    />
                                                                </button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} style={{ padding: "10px" }}>
                                                        No Results Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {data?.pages > 1 ? (
                                        <Pagination
                                            count={data.pages}
                                            page={data.current}
                                            onChange={(e, page) => setPage(page)}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleBackdrop open={status === "loading"} />
            {isOpen ? <AddSuccessStory show={isOpen} handleShow={toggle} /> : ""}
            {isOpenEdit ? <AddSuccessStory show={isOpenEdit} handleShow={toggleEdit} id={id} /> : ""}
            <DeleteStory
                isOpen={isOpenDelete}
                toggle={toggleDelete}
                id={id}
                page={page}
                userType="deactivated"
            />

            {/* {isOpen ? <AddBanner show={isOpen} handleShow={toggle} /> : ""}
            {isOpenEdit ? <AddBanner show={isOpenEdit} handleShow={toggleEdit} id={id} /> : ""}
            <UpdateBanner
                isOpen={isOpenStatus}
                toggle={toggleStatus}
                id={id}
                status={status}
                page={page}
                userType="deactivated"
            />
           */}
            {/* 
            {isOpenEdit ? <AddOffers show={isOpenEdit} handleShow={toggleEdit} id={id} /> : ""}
            */}
        </div>
    );
};

export default SuccessList;
