import React, { useState, useEffect } from 'react'
import { getClassList } from "../../slice";
import { useSelector, useDispatch } from 'react-redux';

const GovernmentClass = ({ setCheckedItems, checkedItems }) => {
    const dispatch = useDispatch()
    const classlist = useSelector((state) => state.classList)


    useEffect(() => {
        dispatch(getClassList())
    }, [])

    const handleCheckOptions = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setCheckedItems([...checkedItems, value]); // add the value to the array
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== value)); // remove the value from the array
        }
    };



    return (
        <div className='gov text-center mb-4 mt-3'>
            <h4>Government Service Class</h4>
            {classlist?.map((res, i) => {
                return <div className="" key={i}>
                    <input
                        type="checkbox"
                        id="notifyAllUsers"
                        name="notifyAllUsers"
                        value={res?._id}
                        // checked={checkedItems.includes(res?.id)}
                        onChange={handleCheckOptions}
                    />
                    <label htmlFor="groupA" className='mx-3'>
                        {res?.name}
                    </label>
                </div>
            })}
        </div>
    )
}

export default GovernmentClass