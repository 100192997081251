import React from "react";
import LoginPage from "../features/accounts/LoginPage/LoginPage";
import OTPVerificationPage from "../features/accounts/OTPVerificationPage/OTPVerificationPage";
import ResetPasswordPage from "../features/accounts/ResetPasswordPage/ResetPasswordPage";
import ForgotPasswordPage from "../features/accounts/ForgotPasswordPage/ForgotPasswordPage";

const routes = [
  {
    path: "/login",
    exact: true,
    main: () => <LoginPage />,
  },

  {
    path: "/otp-verification",
    exact: true,
    main: () => <OTPVerificationPage />,
  },

  // {
  //   path: "/forgot-password",
  //   exact: true,
  //   main: () => <ForgotPasswordPage />,
  // },
  // {
  //   path: "/reset-password",
  //   exact: true,
  //   main: () => <ResetPasswordPage />,
  // },
];

export default routes;
