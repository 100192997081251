import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../slice";
import { editBloodGroup } from "../../../api/edit";
import { CircularProgress } from "@mui/material";
import SimpleBackdrop from "../../../components/backdrop";
import { Link } from "react-router-dom";

const BloodGroup = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      group: data?.blood_group || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      group: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      const payload = {
        userid: userID,
        blood_group: values.group,
      };

      editBloodGroup(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          goBack();
        }
      });
    },
  });

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Blood Group"
              : "Add User Blood Group"}
          </h2>
          <div className="edit-span-button">
            {/* <button
                    className="upload_btn"
                    type=""
                    title="Approve"
                    onClick={() => {
                      setValue(1);
                      toggleVerify();
                    }}
                  >
                    Download CSV
                  </button> */}
            {/* <CSVDownload data={data} target="_blank" />; */}

            <span className="position-relative edit-icon-list">
              <img
                src="images/edit.png"
                className="action-view"
                alt="edit"
              />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                <Link to={`/users/edit-gender/${userID}`}>Gender</Link>
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Marital Status
                </Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                {/* <Link to={`/users/edit-birth/${userID}`}>
                        Birth Details
                      </Link>
                      <Link to={`/users/edit-annual-package/${userID}`}>
                        Annual Package
                      </Link> */}
                {/* <Link to={`/users/edit-nationality/${userID}`}>
                        Nationality
                      </Link>
                      <Link to={`/users/edit-annual-package/${userID}`}>
                        Annual Package
                      </Link>
                      <Link to={`/users/edit-birth/${userID}`}>
                        Birth Details
                      </Link>
                      <Link to={`/users/edit-images-videos/${userID}`}>
                        Images/Videos
                      </Link>
                      <Link to={`/users/edit-height/${userID}`}>Height</Link> */}
                <Link to={`/users/edit-blood-group/${userID}`}>
                  Blood Group
                </Link>
                {/* <Link to={`/users/edit-religion/${userID}`}>
                        Religion
                      </Link>
                      <Link to={`/users/edit-community/${userID}`}>
                        Community
                      </Link>
                      <Link to={`/users/edit-mother-tongue/${userID}`}>
                        Mother Tongue
                      </Link> */}
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>
                  Lifestyle
                </Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>

                {/* <Link to={`/users/edit-past-experience/${userID}`}>
                        Past Experience
                      </Link>
                      <Link to={`/users/edit-lifestyle/${userID}`}>
                        Lifestyle
                      </Link>
                      <Link to={`/users/edit-pets/${userID}`}>Pets</Link>
                      <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                      <Link to={`/users/edit-partner-preferences/${userID}`}>
                        Partner Preferences
                      </Link>
                      <Link
                        to={`/users/edit-question/${userID}/description-phrase`}
                      >
                        Questionnaire
                      </Link> */}
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form
                className="row form-style profile-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <div className="">
                    <img
                      src="images/blood-group.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="gander blood-group">
                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.group === "O-"}
                        onChange={() => formik.setFieldValue("group", "O-")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>O-</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.group === "O+"}
                        onChange={() => formik.setFieldValue("group", "O+")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>O+</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.group === "AB-"}
                        onChange={() => formik.setFieldValue("group", "AB-")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>AB-</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.group === "AB+"}
                        onChange={() => formik.setFieldValue("group", "AB+")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>AB+</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.group === "B-"}
                        onChange={() => formik.setFieldValue("group", "B-")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>B-</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.group === "B+"}
                        onChange={() => formik.setFieldValue("group", "B+")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>B+</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.group === "A-"}
                        onChange={() => formik.setFieldValue("group", "A-")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>A-</span>
                      </p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="blood_group"
                        checked={formik.values.group === "A+"}
                        onChange={() => formik.setFieldValue("group", "A+")}
                      />
                      <div>
                        <img
                          src="images/unselected-blood-group.png"
                          className="unselected"
                          alt=""
                        />
                        <img
                          src="images/selected-blood-group.png"
                          className="selected"
                          alt=""
                        />
                      </div>
                      <p>
                        <span>A+</span>
                      </p>
                    </label>
                  </div>
                  {formik.errors.group ? (
                    <span className="error">{formik.errors.group}</span>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default BloodGroup;
